import { useRef, useState, useCallback } from 'react'
import axios from 'axios'
import { formatQuerys } from '@/utils'

type TypeInitState = {
    loading: boolean
}
type TypeSuccessCallback<T> = (data: T, message: string | null) => void
type TypeErrorCallback = (message: string | null) => void
type TypeFetchResult<T, K> = {
    loading: boolean,
    setLoading: (_: boolean) => void,
    querys: T,
    error: string | null,
    data: K | null,
    token: any,
    setData: (_: K | null) => void,
    setQuerys: (_: T) => void,
    loadData: () => void
}

const CancelToken = axios.CancelToken

export function useFetch<T, K> (
    action: any,
    params: T,
    initState: TypeInitState = { loading: false },
    callBack: TypeSuccessCallback<K> | null = null,
    errorCallback: TypeErrorCallback | null = null
): TypeFetchResult<T, K> {
    const token = useRef<any>(null)
    const [loading, setLoading] = useState(initState.loading)
    const querys = useRef<T>(params)
    const [error, setError] = useState<string | null >(null)
    const [data, setData] = useState<K | null>(null)
    const setQuerys = useCallback((v: T) => {
      querys.current = { ...querys.current, ...v }
    }, [querys])
    const loadData = useCallback(async () => {
      const cancelToken = new CancelToken(function executor (cancel) {
        token.current = { cancel }
      })
      setLoading(true)
      const result = await action(formatQuerys(querys.current), { cancelToken })
      const { data: _data, message, success } = result ?? {}
      if (success) {
        setError(null)
        setData(_data)
        if (callBack && typeof callBack === 'function') { callBack(_data, message) }
      } else {
        setError(message)
        if (errorCallback && typeof errorCallback === 'function') { errorCallback(message) }
      }
      setTimeout(() => {
        setLoading(false)
      }, 200)
    }, [action, token, querys, callBack, errorCallback])
    return {
      loading,
      setLoading,
      error,
      data,
      token,
      setData,
      setQuerys,
      loadData,
      querys: querys.current
    }
  }
