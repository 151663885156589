import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'
import PointBg from '../assets/images/point.png'
const CardContainer = styled.div<any>`
  height: ${props => props.height}px;
  display: flex;
  flex-direction: column;
  margin: 0 40px;
`
const CardWrap = styled.div<any>`
  display: grid;
  background-image: url(${props => props.background});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  padding: 24px;
  box-sizing: border-box;
`
const CardTitle = styled.div`
  font-size: 24px;
  font-weight: 600;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  img {
    width: 10px;
  }
`
type TypeDashboardCardProps = {
  title: string,
  height: number,
  background: string
}
const DashboardCard: React.FC<TypeDashboardCardProps & PropsWithChildren> = ({ title, height, background, children }) => {
  return <CardContainer height={height}>
    <CardTitle>
      <img src={PointBg}/> {title}
    </CardTitle>
    <CardWrap background={background}>
      {children}
    </CardWrap>
  </CardContainer>
}
export default DashboardCard
