import { post } from '@/axios'
import { TypeConfig, TypeResponse } from '@/types'
// 编辑表格设置
export namespace NSEditTableUserSetting {
    export type Params = {
        code: string | null,
        settingInfo: string
    }
    export type Result = TypeResponse<{}>
    export function action (params: Params, config?: TypeConfig) {
        console.log('NSEditTableUserSetting.action')
        return post('api/basedata/table/user/setting/edit', params, config)
    }
}
// 获取表格设置
export namespace NSFindTableUserSetting {
    export type Params = {
        code: string | null
    }
    export type Result = {
        code: string | null,
        settingInfo: string
    }
    export function action (params: Params, config?: TypeConfig) {
        return post('api/basedata/table/user/setting/find', params, config)
    }
}
