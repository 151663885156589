import { TypeConfig } from '@/types'
import { post } from '@/axios'

// 获取申请单详情
export namespace NSCodeInfo {
    export type Params = {
        id: number
    }
    export type Result = {
        requestNo: string,
        corpName: string,
        corpCode: string,
        productCode: string,
        productName: string,
        printCorpName: string,
        numberStr: string,
        createdTime: string,
        remark: string
    }
    export function action (params: Params, config?: TypeConfig) {
        return post('api/qingpi/codeRequest/find', params, config)
    }
}
// 配置中奖策略
export namespace NSetAwardsTactics {
    export type Params = {
        codeRequestId: number,
        rewardRule?: number,
        strategyList: string[]
    }
    export type Result = {
    }
    export function action (params: Params, config?: TypeConfig) {
        return post('api/qingpi/codeRequest/configureStrategy', params, config)
    }
}
