import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { notification } from 'tea-component'
import MScanImg from './images/mScan.png'
import BugSellImg from './images/bugSell.png'
import DuplicationCodeImg from './images/duplicationCode.png'
import iconRight from './images/i_right.svg'
import { GetUserInfo } from '@/assets/js/storage.js'
import warningMsg from './index.module.css'
import { getUnreadList } from './api'

export default function WarningList () {
    const userInfo = GetUserInfo()
    console.log(userInfo)
    document.title = '预警列表'
    const [dataList, setDataList] = useState([])

    const getWarningList = () => {
        getUnreadList({
            messageTypes: ['MScanCountWarn', 'BugSellWarn', 'DuplicationCodeWarn']
        }).then(res => {
            const { data, success, message } = res || {}
            if (success) {
                setDataList(data)
            } else {
                notification.error({ description: message })
            }
        }).catch(e => {
            console.log(e)
        })
    }
    useEffect(() => {
        getWarningList()
    }, [])
    return (
        <div className={warningMsg.background}>
            <div className="page">
                {dataList?.map(item => {
                    let imgData = ''
                    let descrip = ''
                    const metaData = JSON.parse(item.metaData)[0]
                    console.log(metaData)
                    switch (item.messageTypeCode) {
                        case 'MScanCountWarn':
                            imgData = MScanImg
                            descrip = '消费者' + metaData.timeSpan + '分钟内扫描' + metaData.count + '次未激活的产品。'
                            break
                        case 'BugSellWarn':
                            imgData = BugSellImg
                            descrip = '消费者' + metaData.timeSpan + '分钟内扫描' + metaData.count + '次窜货的产品。'
                            break
                        case 'DuplicationCodeWarn':
                            imgData = DuplicationCodeImg
                            descrip = userInfo.corpName + '工厂' + metaData.timeSpan + '分钟内产生' + metaData.count + '个重码。'
                            break
                    }
                    return (
                    <div key={item.messageTypeCode} className={warningMsg.listItemBox}>
                        <Link style={{ width: '100%', height: '100%', textDecoration: 'none' }} to={`/custom/qingpi/h5warningmsgdetailview/${item.messageTypeCode}/${item.amount}`}>
                            {item.amount !== 0 ? <div className={warningMsg.barge}>{item.amount}</div> : ''}
                            <div className={warningMsg.listItem}>
                                <div className={warningMsg.listItem_left}>
                                    <img src={imgData} alt=""></img>
                                </div>
                                <div className={warningMsg.listItem_body}>
                                    <div className={warningMsg.item__title}>{item.messageTypeName}</div>
                                    <div className={warningMsg.item__description}>{descrip}</div>
                                </div>
                                <div className={warningMsg.listItem_icon}>
                                    <img src={iconRight} alt=""></img>
                                </div>
                            </div>
                        </Link>
                    </div>
                    )
                })}
            </div>
        </div>
    )
}
