import React, { useRef, useEffect, useCallback } from 'react'
import { useSafeState } from 'ahooks'
// import styles from './index.module.css'
import styled from 'styled-components'
type TypeCardProps = {
    offset?: number,
    children: any,
    boxShadow?: string,
    resize?: boolean
}

const WrapCard = styled.div<any>`
    border-radius: 4px;
    box-shadow: ${props => props.boxShadow ?? '0px 0px 8px #eeeeee'};
    padding: 20px;
    height: ${props => props.clientHeight ? props.clientHeight + 'px' : '100%'};
    max-height: ${props => props.clientHeight ? props.clientHeight + 'px' : '100%'};
    flex: 1;
    box-sizing: border-box;
    overflow-y: auto;
    background-color: #ffffff;
    .tea-table+.tea-pagination {
        padding: 10px 10px 0px 10px !important
    }
`
const NCard: React.FC<TypeCardProps> = (props) => {
    const cardRef = useRef<any>(null)
    const [clientWidth, setClientWidth] = useSafeState<number>(0)
    const [clientHeight, setClientHeight] = useSafeState<number>(0)
    const [clientTableHeight, setClientTableHeight] = useSafeState<number>(0)
    // 计算card高度
    const getCardHeight = useCallback(() => {
        return (window.innerHeight - (props.offset ?? 180))
    }, [props])
    // 计算table高度
    const getTableHeight = useCallback(() => {
        const pageHeight = cardRef?.current?.querySelector('.tea-pagination')?.offsetHeight ?? 0
        const scrollBarHeight = 10 + 2 // 2是线的高度
        const paddingHeight = 40
        const tableHeaderHeight = 40/* cardRef?.current?.querySelector('.tea-table__header')?.offsetHeight */ // 先写死
        // console.log('tableHeaderHeight', tableHeaderHeight)
        const barHeight = cardRef?.current?.querySelector('.tea-table__action-panel')?.offsetHeight ?? 0
        return window.innerHeight - (props?.offset ?? 180) - pageHeight - barHeight - tableHeaderHeight - scrollBarHeight - paddingHeight
    }, [props])
    // resize事件
    const _resize = useCallback(() => {
        setClientHeight(getCardHeight())
        setTimeout(() => {
            // 防止为渲染
            setClientTableHeight(getTableHeight())
        }, 300)
        setClientWidth(cardRef.current?.offsetWidth)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getCardHeight, getTableHeight])
    useEffect(() => {
        setClientWidth(cardRef.current?.offsetWidth)
    }, [setClientWidth, props.resize])
    useEffect(() => {
        _resize()
        window.addEventListener('resize', () => {
            _resize()
        })
        return () => {
            window.removeEventListener('resize', () => {
                _resize()
            })
        }
    }, [_resize])

    return <WrapCard ref={cardRef} clientHeight={clientHeight} boxShadow={props.boxShadow}>
        {
            React.Children.map(props.children, child => {
                return React.cloneElement(child, {
                    scrollwidth: clientWidth - 50,
                    scrollheight: clientTableHeight
                })
            })
        }
    </WrapCard>
}
export default NCard
