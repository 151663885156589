import React from 'react'
import ScanDashboard from './scandashboard/index'
import TraceDashboard from './tracedashboard/index'
const Dashboard: React.FC<any> = (props) => {
  const { location } = props
  if (location.pathname.includes('scan')) {
    return <ScanDashboard />
  } else {
    return <TraceDashboard />
  }
}
export default Dashboard
