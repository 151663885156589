import React from 'react'
import Chart from '../Chart'
import { AutoTableNoData } from '../AutoTable'

const colors = [
  '#00E3FF',
  '#007FFE',
  '#6249FF',
  '#B43DE0',
  '#00D87C',
  '#FF8C33',
  '#E4A4FF',
  '#67DF0E',
  '#D85600',
  '#FFDF7A'
]
const covertData = (data: any[]) => {
  return data?.map((item, i) => {
    return {
      value: item,
      itemStyle: {
        color: colors[i]
      }
    }
  }) ?? []
}
export const mapOptions = (yAxis: string[], data: any[]) => ({
  xAxis: {
    max: (value: any) => {
      return value.max + value.max * 0.2
    },
    splitLine: {
      show: false
    }
  },
  yAxis: {
    type: 'category',
    splitLine: {
      show: false
    },
    axisLine: {
      show: false
    },
    axisTick: {
      show: false
    },
    data: yAxis,
    inverse: true,
    animationDuration: 300,
    animationDurationUpdate: 300,
    max: 10 // only the largest 3 bars will be displayed
  },
  color: colors,
  textStyle: {
    color: '#fff',
    fontSize: 14
  },
  itemStyle: {
    borderRadius: 5
  },
  grid: {
    top: 0,
    left: 50,
    bottom: -20,
    right: -10
  },
  series: [
    {
      realtimeSort: true,
      name: 'X',
      type: 'bar',
      barGap: 0,
      barCategoryGap: '60%',
      label: {
        show: true,
        position: 'right',
        valueAnimation: true
      },
      data
    }
  ],
  animationDuration: 0,
  animationDurationUpdate: 3000,
  animationEasing: 'linear',
  animationEasingUpdate: 'linear'
})
type TypeComprehensiveCollectProps = {
  data: any[],
  yAxis: string[]
}
const ComprehensiveCollect: React.FC<TypeComprehensiveCollectProps> = (props) => {
  const { data, yAxis } = props
  return <>
  {
    data?.length
    ? <Chart option={mapOptions(yAxis, covertData(data))} />
    : <AutoTableNoData>暂无数据</AutoTableNoData>
  }
    </>
}
export default ComprehensiveCollect
