import React, { useCallback, useEffect } from 'react'

import {
	Modal,
	notification
} from 'tea-component'
import { SetToken, SetUserInfo, SetSysInfo } from '@/assets/js/storage'
import { instance, postWithAnonymous } from '@/axios'
import { useHistory } from 'react-router-dom'
import { tokenLogin } from './api'

const pageTitle = process.env.REACT_APP_Title || 'TTS追溯平台'
// 第三方登录跳转页面
export default function ThirdLogin () {
	const history = useHistory()

	useEffect(() => {
		postWithAnonymous('/api/basedata/systemsetting/systemName/find').then(res => {
			if (res?.success) {
				if (!res?.data) { return }
				SetSysInfo(res?.data)

				const title = res?.data.name || pageTitle
				document.title = title

				// #region 设置网页图标favicon
				const favicon = res.data.favicon
				const faviconurl = favicon ? `https:${favicon}` : '' // 这里可以是动态的获取的favicon的地址
				const link = document.querySelector("link[rel*='icon']") || document.createElement('link')
				link.type = 'image/x-icon'
				link.rel = 'shortcut icon'
				if (faviconurl) {
					link.href = faviconurl
				}
				document.getElementsByTagName('head')[0].appendChild(link)
				// #endregion
			} else {
				document.title = pageTitle
			}
		})
	}, [])

	// 接口失败，弹框提示
	const tipMessage = useCallback(async msg => {
		try {
            const yes = await Modal.confirm({
                message: '提示',
                description: msg || '',
                okText: '确定',
                cancelText: '取消'
            })
            if (yes) {
				console.log(yes)
            }
			// 跳转后台登录
			history.push('/sysmgr/user/login')
        } catch (e) {
            notification.error({ description: e.message })
        }
	}, [history])

	// 判断token存在
	useEffect(() => {
		const url = new URL(location.href)
    	const searchParams = url.searchParams
        const params = Object.fromEntries(searchParams.entries())
		const { token, code, state } = params || {}
		// 携带参数，请求接口存储token，跳转至首页，接口请求失败或者提示用户不存在则点击确定按钮跳转到后台登录页面
		if (token || (code && state)) {
			tokenLogin({ ...params }).then(res => {
				const { success, message } = res || {}
				if (success) {
					const { userInfo, redirectUrl, token } = res.data || {}
					// 第三方登录存储数据
					if (!userInfo.industryType) {
						userInfo.industryType = 'tts'
					}
					SetUserInfo(userInfo)
					SetToken(token)
					instance.defaults.headers.Authorization = 'Bearer ' + token
					history.push(redirectUrl)
				} else {
					tipMessage(message)
				}
			}).catch(() => {
				notification.error({
					description: '请求失败'
				})
			})
		} else {
			// 第三方跳转过来token不存在，跳转后台登录
			history.push('/sysmgr/user/login')
		}
	}, [tipMessage, history])

	return (
		<div></div>
	)
}
