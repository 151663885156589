import React from 'react'
import { Checkbox } from 'tea-component'
export const CheckboxGroup: React.FC<any> = props => {
    const { field, ...rest } = props
    return <Checkbox.Group {...rest}/>
}

const NCheckbox: React.FC<any> = props => {
    return <Checkbox {...props}/>
}
export default NCheckbox
