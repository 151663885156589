import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'
import GroupImg from '../../../assets/images/group.png'

const GlobalTopDataItemWrap = styled.div`
  background-image: url(${GroupImg});
  background-repeat: no-repeat;
  height: 109px;
  width: 448px;
  padding-bottom: 10px;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
type TypeGlobalTopDataProps = {

}
const GlobalTopDataItem: React.FC<TypeGlobalTopDataProps & PropsWithChildren> = (props) => {
    const { children } = props
    return <GlobalTopDataItemWrap>
      {children}
    </GlobalTopDataItemWrap>
}
export default GlobalTopDataItem
