import styled from 'styled-components'
const DashboardContainer = styled.div<any>`
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-image: url(${props => props.background});
  background-size: cover;
  background-repeat: no-repeat;
`
export default DashboardContainer
