import React from 'react'
import { Radio } from 'tea-component'
export const RadioGroup: React.FC<any> = props => {
    const { field, value, onChange, style, ...rest } = props
    const handleOnChange = (val: number | string | boolean) => {
        if (val === 'true') {
            onChange(true)
        } else if (val === 'false') {
            onChange(false)
        } else if (val === 'undefind') {
            onChange(undefined)
        } else if (val === 'null') {
            onChange(null)
        } else {
            if (val === '0') {
                onChange(0)
            } else {
                onChange(Number(val) || val)
            }
        }
    }
    return <Radio.Group style={{
        ...style
    }} value={value + ''} onChange={handleOnChange} {...rest}/>
}

const NRadio: React.FC<any> = (props) => {
    const { name, ...rest } = props
    return <Radio name={name + ''} {...rest}/>
}
export default NRadio
