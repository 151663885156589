import React, { PropsWithChildren, useEffect, useCallback } from 'react'
import Charts from '@jiaminghi/charts'
import { RingWrap } from './style'

type TypeRingProps = {
  id: string;
  title: string;
  value: number;
  gradient: string[];
  testStyle: string;
}

const Ring: React.FC<TypeRingProps & PropsWithChildren> = (props) => {
  const { id, title, testStyle, value, gradient } = props

  const renderChart = useCallback(() => {
    let container: any = ''
    if (document.getElementById(id)) {
      container = document.getElementById(id)
    }
    // 销毁 container 下面的子元素，防止重复渲染
    if (container.childNodes?.length > 0) {
      container.removeChild(container.childNodes[0])
    }

    const myChart = new Charts(container)

    const option = {
      title: 'My Chart',
      series: [
        {
          type: 'gauge',
          startAngle: -Math.PI / 2,
          endAngle: Math.PI * 1.5,
          arcLineWidth: 12,
          data: [
            { name: 'itemA', value, gradient }
          ],
          axisLabel: {
            show: false
          },
          axisTick: {
            show: false
          },
          backgroundArc: {
            show: true,
            style: {
              stroke: 'rgba(26,63,129,0.5)'
            }
          },
          pointer: {
            show: false
          },
          dataItemStyle: {
            lineCap: 'round'
          },
          details: {
            show: true,
            formatter: '{value}%',
            style: {
                fill: testStyle,
                fontSize: 22
            }
          }
        }
      ]
    }

    myChart.setOption(option, true)
  }, [gradient, id, testStyle, value])

  useEffect(() => {
    renderChart()
  }, [id, renderChart])

  return (
    <RingWrap>
      <div id={id} style={{ height: 130, textAlign: 'center' }}></div>
      <div className="progress-name">
        {title}
      </div>
  </RingWrap>
  )
}

export default Ring
