import { post } from '@/axios/index'

// ticket登录
export function tokenLogin (params, config) {
    return post(`api/qingpi/thirdlogin?token=${params.token}&redirectUrl=${params.redirectUrl}&state=${params.state}&code=${params.code}`, {}, {
        ...config,
        anonymous: true
    })
}
// 获取登录URL
export function getLoginUrl (params, config) {
    return post('api/yuhong/authorize/getLoginUrl', params, {
        ...config,
        anonymous: true
    })
}
