import { useEffect, useState } from 'react'
import { Input } from '@/components/Base'
import styled from 'styled-components'
import { numberToThousands } from '@/utils/index_ts'
const WrapInput = styled.div`
    display: inline-block;
    .extra {
        display: inline-block;
        color: #a1a1a1;
        margin-top: 5px;
        font-size: 12px;
        margin-left: 10px;
    }
    
`
const NCashInput = ({ _field, value, unit='¥', ...rest }: any) => {
    const [valueExtra, setValueExtra] = useState<any>(value ? numberToThousands(value) : '')
    useEffect(() => {
        if (value) {
            setValueExtra(numberToThousands(value))
        }
    }, [value])
    return <WrapInput>
        <Input value={value} {...rest}/><br />
        {
            value && <span className='extra'>{unit} {valueExtra}</span>
        }
    </WrapInput>
}
export default NCashInput
