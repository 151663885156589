import React, { useEffect, useCallback, useState } from 'react'
import { postWithAnonymous as post } from '@/axios'
import { queryString } from '@/utils/index.js'
import { notification } from 'tea-component'
import { useHistory } from 'react-router-dom'
export default props => {
    useEffect(() => {
        sessionStorage.setItem('scanCodeUrl', window.location.href)
    }, [])
    const history = useHistory()
    const url = process.env.NODE_ENV === 'development' ? 'http://localhost:8000/views' : '/h5editors/views'
    const [sourceForm] = useState({ t: 1, m: 2, c: 3 })
    // 静默授权获取code
    const getWeChatAuthUrl = appID => `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appID}&redirect_uri=${encodeURIComponent(window.location.href)}&response_type=code&scope=snsapi_base&state=1#wechat_redirect`
    const getWeChatCode = () => queryString().code

    // eslint-disable-next-line no-unused-vars
    const browsingEnvironment = () => {
        // 1 微信小程序 2 微信公众号 9 其他
        let source = 9
        const ua = navigator.userAgent.toLowerCase()
        const isWeixin = ua.indexOf('micromessenger') !== -1
        if (isWeixin) {
            if (window.__wxjs_environment === 'miniprogram') { // 小程序
                source = 1
            } else { // 公众号
                source = 3
            }
        }
        return source
    }
    const { code } = props.match.params
    const type = props.match.path.substring(1, 2)
    const getwxmsg = useCallback(bcCode => post('/api/h5/wechat/find', { code: bcCode, url: window.location.href }), [])
    const getopenid = useCallback((weChatCode, id) => post('/api/h5/weChat/openid', { code: weChatCode, id }), [])
    useEffect(() => {
        const REACTAPPWechatCode = process.env?.REACT_APP_WechatCode
        if (browsingEnvironment() !== 9 && REACTAPPWechatCode) {
            getwxmsg(REACTAPPWechatCode).then(rs => {
                if (!rs.success) {
                    notification.error({ description: rs.message })
                    return
                }
                const appID = rs.data.appId
                const id = rs.data.id
                const weChatCode = getWeChatCode()
                if (weChatCode) {
                    getopenid(weChatCode, id).then(r => {
                        if (!r.success) {
                            notification.error({ description: r.message })
                            return
                        }
                        if (process.env.REACT_APP_CustomDesign === 'True') {
                            history.replace('/qingpiscan/' + code)
                        } else {
                            window.location.replace(`${url}?code=${code}&type=${sourceForm[type]}&openid=${r.data}`)
                        }
                    })
                } else {
                    // 重定向(跳转页面 获取 code,会携带code 返回 当前页面)

                    window.location.replace(getWeChatAuthUrl(appID))
                }
            })
        } else {
            if (process.env.REACT_APP_CustomDesign === 'True') {
                history.replace('/qingpiscan/' + code)
            } else {
                window.location.replace(`${url}?code=${code}&type=${sourceForm[type]}`)
            }
        }
    }, [code, getopenid, getwxmsg, history, sourceForm, type, url])
    return (
        <div>{' '}</div>
    )
}
