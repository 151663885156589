import React from 'react'
import UnExpectedException from '@/components/ErrorPage/UnExpectedException.jsx'

class ErrorBoundary extends React.Component {
    constructor (props) {
        super(props)
        this.state = { hasError: false }
    }

    static getDerivedStateFromError () {
        // 更新 state 使下一次渲染能够显示降级后的 UI
        return { hasError: true }
    }

    componentDidCatch (error, info) {
        console.log('error', error)
        console.log('info', info)
    }

    render () {
        if (this.state.hasError) {
            // 你可以自定义降级后的 UI 并渲染
            return <UnExpectedException />
        }

        return this.props.children
    }
}
export default ErrorBoundary
