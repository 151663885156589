import React, { useEffect, useState } from 'react'
import {
    Layout,
    Card,
    Form,
    Button,
    Upload,
    Copy,
    notification
} from 'tea-component'
import { getSnInfo } from '../api'
import { BaseURL } from '@/axios/index'
import './index.css'
import { useFetch } from '@/hooks'
const { Content: LayoutContent } = Layout
export default function SNIndex () {
    const { data, loadData } = useFetch(getSnInfo)
    const status = data?.status || null
    useEffect(() => {
        loadData()
    }, [loadData])

    const [uploading, setUploading] = useState(null)
    function handleStart () {
        setUploading(true)
    }
    const handleSuccess = result => {
        if (result.success === true) {
            notification.success({ description: result.message })
        } else {
            notification.error({ description: result.message })
        }
        setUploading(false)
    }
    const handleError = () => setUploading(false)
    return (
        <LayoutContent style={{ height: '100%' }} >
            <LayoutContent.Body full={true}>
                <Card style={{ width: '40%' }}>
                    <Card.Body>
                        <div className="title">SN查询</div>
                        <Form readonly className='tea_form'>
                            <Form.Item label="SN :" style={{ display: (status === 0) ? 'none' : 'table-row' }}>
                                <Form.Text className='text'>{data?.snCode || ''} <Copy text={data?.snCode || ''} /></Form.Text>;
                            </Form.Item>
                            <Form.Item label="授权公司 :" style={{ display: (status !== 2) ? 'none' : 'table-row' }}>
                                <Form.Text>{data?.company || ''}</Form.Text>
                            </Form.Item>
                            <Form.Item label="过期日期 :" className="text" style={{ display: (status !== 2) ? 'none' : 'table-row' }}>
                                <Form.Text>{data?.expiration || ''}</Form.Text>
                            </Form.Item>
                        </Form>
                        <div style={{ textAlign: 'center' }}>
                            <Upload
                                action={`${BaseURL}/web/sysmgr/user/sn/import`}
                                onStart={handleStart}
                                onSuccess={handleSuccess}
                                onError={handleError}
                                style={{ marginRight: '10px' }}
                            >
                                <Button type="primary" loading={uploading}>导入授权</Button>
                            </Upload>
                        </div>
                    </Card.Body>
                </Card>
            </LayoutContent.Body>
        </LayoutContent>
    )
}
