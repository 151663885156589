import { post } from '@/axios'
import { TypeConfig } from '@/types'

/** 流入省份top 10、流入产品排行榜、全国扫码信息 */
export namespace NSScreenMain {
  export type Params = {
    /** 当前页数 */
    currentPage: number,
    /** 每页条数 */
    itemsPerPage: number,
    /** 日期 */
    date?: string,
    /** 省编码 */
    provinceCode?: string,
    /** 排序 */
    orderBy?: string,
    /** 查询的条数 */
    querySize?: number
  }
  /** 产品top10 */
  export type TypeProductTopTen = {
    /** 扫码次数 */
    num: number,
    /** 产品Id */
    productId: string,
    /** 产品名称 */
    productName: string,
    /** 省编码 */
    provinceCode: string,
    /** 省名称 */
    provinceName: string
  }
  export type TypeProvince = {
    /** 省编码 */
    provinceCode: string,
    /** 省名称 */
    provinceName: string
  }
  /** 省份流向 */
  export type TypeProvinceFlowData = {
    /** 省名称 */
    provinceName: string,
    /** 省编码 */
    provinceCode: string,
    /** 产品流入省份：从当前身份窜货至何方 */
    inProvince: TypeProvince[],
    /** 产品流出省份：从何方窜货至当前省份 */
    outProvince: TypeProvince[],
  }
  /** 流入省份top10 */
  export type TypeProvinceTopTen = {
    /** 扫码次数 */
    num: number,
    /** 产品Id */
    productId: number,
    /** 产品名称 */
    productName: string,
    /** 省份编码 */
    provinceCode: string,
    /** 省份名称 */
    provinceName: string
  }
  export type Result = {
    /** 异地扫码总量 */
    diffPlaceScanNum: number,
    /** 全国扫码数量 */
    allScanNum: number,
    /** 省流入数量 */
    provinceInNum: number,
    /** 省流出数量 */
    provinceOutNum: number
    /** 省流出率 */
    provinceOutRate: number,
    /** 流入省份top10 */
    provinceTopTen: TypeProvinceTopTen[],
    /** 产品top10 */
    productTopTen: TypeProductTopTen[],
    /** 省份流向 */
    provinceFlowData: TypeProvinceFlowData[]
  }
  export function action (params: Params, config?: TypeConfig) {
      return post('openapi/qingpi/inspect/screen/main', params, config)
  }
}

/** 查询省份列表 */
export namespace NSScreenProvinceList {
    export type Params = {
        /** 当前页数 */
        currentPage: number,
        /** 每页条数 */
        itemsPerPage: number,
        /** 日期 */
        date?: string,
        /** 省编码 */
        provinceCode?: string,
        /** 排序 */
        orderBy?: string,
        /** 查询的条数 */
        querySize?: number
    }
    export type Result = {
        /** 省份编码 */
        provinceCode: string,
        /** 省份名称 */
        provinceName: string,
    }
    export function action (params: Params, config?: TypeConfig) {
        return post('openapi/qingpi/inspect/screen/provinceList', params, config)
    }
}
/** 流入客户扫码预警 */
export namespace NSScreenFlowInData {
    export type Params = {
        /** 当前页数 */
        currentPage: number,
        /** 每页条数 */
        itemsPerPage: number,
        /** 日期 */
        date?: string,
        /** 省编码 */
        provinceCode?: string,
        /** 排序 */
        orderBy?: string,
        /** 查询的条数 */
        querySize?: number
    }
    export type Result = {
        /** 客户名称 */
        corpName: string,
        /** 产品名称 */
        productName: string,
        /** 销售区域 */
        salesRegion: number,
        /** 扫码位置 */
        scanArea: number
    }
    export function action (params: Params, config?: TypeConfig) {
        return post('openapi/qingpi/inspect/screen/flowInData', params, config)
    }
}
