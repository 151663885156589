import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { notification, Button } from 'tea-component'
import warningMsg from './index.module.css'
import { getUnreadListInfo, getUnreadStatus } from './api'
import { postWithAnonymous } from '@/axios'
import DOMPurify from 'dompurify'

export default function WarningInfoList (props) {
    const [userLoginInfo, setUserLoginInfo] = useState({})
    useEffect(() => {
        postWithAnonymous('/api/basedata/systemsetting/systemName/find').then(res => {
            if (res?.success) {
                if (!res?.data) { return }
                setUserLoginInfo(res?.data)
            }
        })
    }, [])

    const { id, amount } = props.match.params
    const docTitle = [
        '消费者扫描预警',
        '窜货稽查预警',
        '产线重码预警通知'
    ]
    switch (id) {
        case 'MScanCountWarn':
            document.title = docTitle[0]
            break
        case 'BugSellWarn':
            document.title = docTitle[1]
            break
        case 'DuplicationCodeWarn':
            document.title = docTitle[2]
            break
    }
    // 获取列表
    const [dataList, setDataList] = useState([])
    const [totalItems, setTotalItems] = useState(0)
    const [totalPage, setTotalPage] = useState(1)
    const [params, setParams] = useState({
        currentPage: 1
    })
    // 默认每页展示10条数据
    const disableNext = useMemo(() => {
        return Math.ceil(totalItems / 10) <= params.currentPage
    }, [params.currentPage, totalItems])
    const getWarningInfoList = useCallback((currentPage) => {
        getUnreadListInfo({
            messageTypeCode: id,
            itemsPerPage: 10,
            currentPage
        }).then(res => {
            const { data, success, message } = res || {}
            if (success) {
                setDataList(data.items)
                setParams((prev) => {
                    return { ...prev, currentPage: data.currentPage }
                })
                setTotalItems(data.totalItems)
                setTotalPage(Math.ceil(data.totalItems / 10))
            } else {
                notification.error({ description: message })
            }
        }).catch(e => {
            console.log(e)
        })
    }, [id])

    // 判断更新消息状态
    const getUnreadStatusUpdate = useCallback(() => {
        if (amount != null && parseInt(amount) > 0) {
            getUnreadStatus({
                messageTypeCode: id
            }).then(res => {
                const { success, message } = res || {}
                if (!success) {
                    console.log(message)
                }
            }).catch(e => {
                console.log(e)
            })
        }
    }, [id, amount])

    useEffect(() => {
        getWarningInfoList(1)
    }, [getWarningInfoList])

    useEffect(() => {
        getUnreadStatusUpdate()
    }, [getUnreadStatusUpdate])

    // 点击下一页
    const handleClick = useCallback((count) => {
        console.log(count)
        getWarningInfoList(params.currentPage + count)
    }, [getWarningInfoList, params.currentPage])

    return (
        <div className={warningMsg.background}>
            <div className={warningMsg.bodyBox}>
                <div className="page">
                    {dataList?.length>0
                    ? (dataList?.map(item => {
                        const cleanContent = DOMPurify.sanitize(item.content)
                        return (
                            <div key={item.id} className={warningMsg.cardItemBox}>
                                <div className={warningMsg.cardItem}>
                                    <div
                                        className={warningMsg.CardItem_top}
                                        dangerouslySetInnerHTML={{
                                            __html: cleanContent
                                        }}
                                    ></div>
                                    <div className={warningMsg.CardItem_bottom}>{`请登录${userLoginInfo.name}查询详细信息。`}</div>
                                </div>
                            </div>
                        )
                    }))
                    : <div className={warningMsg.tips}>暂无数据</div>
                    }
                </div>
            </div>
            <div className={warningMsg.footerBox}>
                <div className={warningMsg.footerItem}>
                    <Button type="weak" htmlType="button" className={warningMsg.footerItem_btn} onClick={() => { handleClick(-1) }} disabled={params.currentPage === 0 || params.currentPage === 1}>上一页</Button>
                    <div className={warningMsg.pageNum}>{params.currentPage} / {totalPage}</div>
                    <Button type="weak" htmlType="button" className={warningMsg.footerItem_btn} onClick={() => { handleClick(1) }} disabled={disableNext}>下一页</Button>
                </div>
            </div>
        </div >
    )
}
