import { post } from '@/axios/index'

// 获取企业下拉框数据源
export function getCodeRequestForward (params, config) {
    return post('api/qingpi/codeRequest/forward', params, config)
}

// 获取追溯码详情
export function $getCodeInfo (params, config) {
    return post('api/qingpi/codeRequest/find', params, config)
}

// 获取追溯码详情
export function getSubpackageDownload (params, config) {
    return post('api/qingpi/codeRequest/subpackageDownload', params, {
        responseType: 'blob',
        ...config
    })
}
// 重发验证码
export function $resendsCode (params, config) {
    return post('api/qingpi/codeRequest/download/send', params, config)
}
