
import React, { useCallback, useMemo } from 'react'
import { withRouter } from 'react-router-dom'
import { Menu } from 'tea-component'
import { useRefresh } from '@/hooks'
import { GetToken } from '@/assets/js/storage'
function LeftMenu ({ location, menuItem, icon, iconHover }) {
    const [open, setOpen] = React.useState(menuItem.expanded)
    const history = useRefresh()
    // 一级菜单点击后，clickedMenus记录该菜单展开
    const handleOpen = useCallback(
        () => {
            const o = !open
            setOpen(o)
        },
        [open]
    )

    const isSelected = useCallback(
        url => {
            if (location.pathname.indexOf('/') === -1) { return false }
            if (!url) { return false }
            if (url.indexOf('/') === -1) { return false }
            if (url.indexOf('/reportmgr/report') > -1) return url === location.pathname
            let path = location.pathname.substring(0, location.pathname.lastIndexOf('/'))
            url = url.substring(0, url.lastIndexOf('/'))
            if (url === path) { return true }
            if (path.indexOf('/') === -1) { return false }
            path = path.substring(0, path.lastIndexOf('/'))
            if (url === path) { return true }
            if (path.indexOf('/') === -1) { return false }
            path = path.substring(0, path.lastIndexOf('/'))
            if (url === path) { return true }
            path = path.substring(0, path.lastIndexOf('/'))
            return url === path
        },
        [location]
    )

    const subMenuDom = useMemo(() => {
        if (menuItem.children.length > 0 && menuItem.children[0].type !== 2) {
            return <Menu.SubMenu key={menuItem.name} defaultOpened={menuItem.expanded} opened={open} title={menuItem.displayName} onOpenedChange={handleOpen} icon={[icon, iconHover]}>
                {menuItem.children?.length &&
                    menuItem.children.map(child => {
                        return (
                            child.type !== 2 && <Menu.Item
                                selected={isSelected(child.url)}
                                key={child.name}
                                title={child.displayName}
                                render={children => {
                                    return <span onClick={() => {
                                        if (child.url === '/custom/qingpi/inspect/bulletinboard/index') {
                                            window.open(`http://${window.location.host.split(':')?.[0]}/dashboard`)
                                            return
                                        }
                                        history.push(child.url)
                                    }}>{children}</span>
                                }}
                            />
                        )
                    })}
            </Menu.SubMenu>
        } else if (menuItem.children.length || menuItem.url) {
            return <Menu.Item key={menuItem.name}
                selected={menuItem.url === location.pathname}
                icon={[icon, iconHover]}
                title={menuItem.displayName}
                render={children => {
                    return <span onClick={() => {
                        let curl = menuItem.url
                        if (curl === '/custom/dfyh/reportmgr/consumerTrace/index') {
                            curl = '/'
                        } else if (curl === '/magicapimgr/magicapikey/index') {
                            const token = GetToken()
                            const domain = window.location.host.split(':')?.[0]
                            const ipReg = /(1\d{2}|2[0-4]\d|25[0-5]|[1-9]\d|\d)\.((1\d{2}|2[0-4]\d|25[0-5]|[1-9]\d|\d)\.){2}(1\d{2}|2[0-4]\d|25[0-5]|[1-9]\d|\d)/
                            if (ipReg.test(domain)) {
                                const port = 8000
                                window.open(`http://${domain}:${port}/di/#/login?token=${token}`)
                            } else {
                                window.open(`http://${domain}/di/#/login?token=${token}`)
                            }
                            return
                        }
                        history.push(curl)
                    }}>{children}</span>
                }}
            />
        } else {
            return null
        }
    }, [handleOpen, history, icon, iconHover, isSelected, location.pathname, menuItem.children, menuItem.displayName, menuItem.expanded, menuItem.name, menuItem.url, open])
    return <>
        {subMenuDom}
    </>
}

export default withRouter(LeftMenu)
