// 系统用户
const SYS_USERINFO = 'sys_userInfo'
// 系统token
const SYS_TOKEN = 'sys_token'
// 系统refresh token 用于刷新token
const SYS_REFRESHTOKEN = 'sys_refreshtoken'
// 菜单
const SYS_MENUS = 'sys_menus'
// 系统设置
const SYS_INFO = 'sys_info'
// 运营用户
export const UserType_Admin = 'admin'
// 集团用户
export const UserType_Group = 'group'
// 企业用户
export const UserTye_Corp = 'corp'
/**
 * @author hanqi
 * @description 获取当前用户信息
 * @param
 * @returns {groupId: string | number}
 */
export function GetUserInfo () {
    const str = localStorage.getItem(SYS_USERINFO) || ''
    if (!str) { return {} }
    return JSON.parse(str)
}
/**
 * @author hanqi
 * @description 存储当前用户信息
 * @param {groupId: string | number}
 * @returns
 */
export function SetUserInfo (user) {
    const { groupId, corpId } = user
    // 存储用户信息
    const getUserType = function (gid, cid) {
        let UserType
        if (gid === 0 && cid === 0) {
            UserType = UserType_Admin
        } else if (gid > 0 && gid === cid) {
            UserType = UserType_Group
        } else if (gid !== cid) {
            UserType = UserTye_Corp
        } else {
            UserType = ''
        }
        return UserType
    }
    localStorage.setItem(SYS_USERINFO, JSON.stringify({ ...user, userType: getUserType(groupId, corpId) }))
}

/**
 * @author hanqi
 * @description 获取token
 * @param
 * @returns {string}
 */
export function GetToken () {
    return localStorage.getItem(SYS_TOKEN) || ''
}
/**
 * @author hanqi
 * @description 存储token
 * @param {string}
 * @returns
 */
export function SetToken (token) {
    localStorage.setItem(SYS_TOKEN, token)
}

/**
 * @author hanqi
 * @description 获取菜单
 * @param
 * @returns {string}
 */
export function GetMenus () {
    const str = localStorage.getItem(SYS_MENUS) || ''
    if (!str) { return {} }
    return JSON.parse(str)
}
/**
 * @author hanqi
 * @description 存储菜单
 * @param {string}
 * @returns
 */
export function SetMenus (menus) {
    localStorage.setItem(SYS_MENUS, JSON.stringify(menus))
}

/**
 * @author hanqi
 * @description 获取系统设置
 * @param
 * @returns {string}
 */
export function GetSysInfo () {
    const str = localStorage.getItem(SYS_INFO) || ''
    if (!str) { return {} }
    return JSON.parse(str)
}
/**
 * @author hanqi
 * @description 存储系统设置
 * @param {string}
 * @returns
 */
export function SetSysInfo (info) {
    localStorage.setItem(SYS_INFO, JSON.stringify(info))
}

/**
 * @author hanqi
 * @description 清除系统信息（token和用户信息）
 * @param
 * @returns
 */
export function RemoveSysInfo () {
    localStorage.setItem(SYS_USERINFO, '')
    localStorage.setItem(SYS_TOKEN, '')
    localStorage.setItem(SYS_REFRESHTOKEN, '')
    localStorage.setItem(SYS_MENUS, '')
}
