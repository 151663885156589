import { useEffect, useCallback, useRef, useMemo } from 'react'
import axios from 'axios'
import { useSafeState } from 'ahooks'
import { notification } from 'tea-component'
import { useCacheDispatch } from 'react-keepalive-router'
import { formatQuerys } from '@/utils'
import { GetSysInfo } from '@/assets/js/storage'
import { CUSTOMTABLESETTING } from '@/utils/constants'
import { useHistory } from 'react-router-dom'
import { NSEditTableUserSetting, NSFindTableUserSetting } from '@/axios/api'

const CancelToken = axios.CancelToken

export function useFetch (action, params = {}, initState = { loading: false }, callBack = null, errorCallback = null) {
  const token = useRef(null)
  const [loading, setLoading] = useSafeState(initState.loading)
  const querys = useRef(params)
  const [error, setError] = useSafeState(null)
  const [data, setData] = useSafeState(null)
  const setQuerys = useCallback(v => {
    querys.current = { ...querys.current, ...v }
  }, [querys])
  const loadData = useCallback(async () => {
    setError(null)
    const cancelToken = new CancelToken(function executor (cancel) {
      token.current = { cancel }
    })
    setLoading(true)
    const result = await action(formatQuerys(querys.current), { cancelToken })
    const { data, message, success } = result ?? {}
    if (success) {
      setData(data)
      if (callBack && typeof callBack === 'function') { callBack(data, message) }
    } else {
      setError(message)
      setData([])
      if (errorCallback && typeof errorCallback === 'function') {
        errorCallback(message)
      }
    }
    setTimeout(() => {
      setLoading(false)
    }, 200)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action, token, querys, callBack, errorCallback])
  return {
    loading,
    setLoading,
    querys: querys.current,
    error,
    data,
    token,
    setData,
    setQuerys,
    loadData
  }
}

export function useSubmit (action, onChange, successMsg, showMsg = true) {
  const token = useRef(null)
  const [loading, setLoading] = useSafeState(false)
  const onSubmit = useCallback(async values => {
    try {
      const cancelToken = new CancelToken(function executor (cancel) {
        token.current = { cancel }
      })
      setLoading(true)
      const { message, data, success } = await action(values, { cancelToken })
      const fn = success ? notification.success : notification.error
      let msg = message
      if (!msg) {
        if (success) { msg = successMsg || '成功' }
      }
      showMsg && fn({ description: msg })
      if (success) {
        onChange(values, data)
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action, token, onChange, successMsg, showMsg])
  return {
    loading,
    onSubmit,
    token
  }
}

export function useTable ({
  action,
  query = {},
  serverSide = true,
  firstLoad = true, // 初始加载
  hasTotal = true, // 默认有分页
  successCallback = null,
  errorCallback = null
}) {
  const history = useHistory()
  const { itemsPerPage = 10 } = GetSysInfo()
  const [isServerSide] = useSafeState(serverSide)
  const [allData, setAllData] = useSafeState([])
  const [timestamp, setTimestamp] = useSafeState(new Date().getTime())
  const [client_CurrentPage, setClient_CurrentPage] = useSafeState(1)
  const [client_ItemsPerPage, setClient_ItemsPerPage] = useSafeState(10)
  const [noLimitCount, setNoLimitCount] = useSafeState(client_ItemsPerPage * 2)
  const tableCode = findSettingCode()
  if (serverSide) {
    query = {
      currentPage: 1,
      itemsPerPage: client_ItemsPerPage,
      ...query
    }
  }
  const _successCallback = useCallback((data) => {
    return successCallback?.(data)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const _errorCallback = useCallback((msg) => {
    return errorCallback?.(msg)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const {
    loadData,
    loading,
    querys,
    error,
    data,
    setQuerys,
    token,
    setData
  } = useFetch(action, query, { loading: false }, _successCallback, _errorCallback)
  // 保存表格页数
  const saveLocalTablePageSize = useCallback((_pageSize) => {
    const _settings = localStorage.getItem(CUSTOMTABLESETTING)
    if (_settings) {
        try {
            const settingObj = JSON.parse(_settings)
            const newObj = { ...settingObj[tableCode ?? ''], pageSize: _pageSize }
            settingObj[tableCode ?? ''] = newObj
            localStorage.setItem(CUSTOMTABLESETTING, JSON.stringify(settingObj))
        } catch (error) {
            notification.error({
                description: '存储表格配置失败'
            })
            return false
        }
    } else {
        // 先存在storage里面
        localStorage.setItem(CUSTOMTABLESETTING, JSON.stringify({ [tableCode ?? '']: { pageSize: _pageSize } }))
    }
    return true
  }, [tableCode])
/** 获取settingcode */
function findSettingCode () {
  const { location: { pathname } } = history
  const codeArr = pathname.split('/')
  // 过滤index和空字符串的一层，并用_衔接
  const _tableCoode = codeArr.filter(e => e !== 'index' && !!e)?.join('_')
  if (_tableCoode) {
      return _tableCoode
  } else {
      notification.error({
          description: '表格设置初始化失败，请检查路由'
      })
      return ''
  }
}
  // 保存设置
const _saveTableSettings = useCallback((_pageSize) => {
  // 调用接口完成远端存储
  if (saveLocalTablePageSize(_pageSize)) {
      let _settings = localStorage.getItem(CUSTOMTABLESETTING)
      if (_settings) {
        _settings = JSON.parse(_settings)
      }
      return NSEditTableUserSetting.action({
          code: tableCode,
          settingInfo: JSON.stringify(_settings[tableCode ?? ''])
      })
  } else {
      return Promise.reject(new Error('本地保存设置失败'))
  }
}, [saveLocalTablePageSize, tableCode])
  const recordCount = useMemo(() => {
    if (isServerSide) {
      if (!hasTotal) {
        return noLimitCount
      }
      return data?.totalItems || 0
    }
    setAllData(data)
    return data?.length || 0
  }, [data, hasTotal, isServerSide, noLimitCount, setAllData])
  const list = useMemo(() => {
    if (isServerSide) { return data?.items || (Array.isArray(data) ? data : []) }

    return data?.slice(client_ItemsPerPage * (client_CurrentPage - 1), client_ItemsPerPage * client_CurrentPage) || []
  }, [data, client_CurrentPage, client_ItemsPerPage, isServerSide])
  const setPageIndex = useCallback(currentPage => {
    if (isServerSide) {
      setQuerys({
        currentPage
      })
    } else {
      setClient_CurrentPage(currentPage)
    }
  }, [setQuerys, setClient_CurrentPage, isServerSide])
  const setPageSize = useCallback(_itemsPerPage => {
    if (isServerSide) {
      setQuerys({
        itemsPerPage: _itemsPerPage
      })
    } else {
      setClient_ItemsPerPage(_itemsPerPage)
    }
  }, [setQuerys, setClient_ItemsPerPage, isServerSide])
  const handleLoadData = useCallback(() => {
    loadData()
    setTimestamp(new Date().getTime())
  }, [loadData, setTimestamp])
  const onPagingChange = (opts) => {
    setPageIndex(opts.pageIndex)
    setPageSize(opts.pageSize)
    _saveTableSettings(opts.pageSize)
    if (isServerSide) { loadData() }
  }
  const fetchUserSetting = (callback) => {
    if (tableCode) { /** 如果传了tableCode，去拿用户的设置 */
      NSFindTableUserSetting.action({ code: tableCode }).then(res => {
        if (res?.data?.settingInfo) {
          const _pageSize = JSON.parse(res.data.settingInfo).pageSize
          if (_pageSize) {
            setPageSize(_pageSize)
            callback?.()
          } else {
            setPageSize(itemsPerPage)
            /** 存储pageSize */
            _saveTableSettings(itemsPerPage)
            callback?.()
          }
        } else {
          setPageSize(itemsPerPage)
          /** 存储pageSize */
          _saveTableSettings(itemsPerPage)
          callback?.()
        }
      })
    } else {
      callback?.()
    }
  }
  useEffect(() => {
    if (firstLoad) {
      fetchUserSetting(() => {
        loadData()
      })
    } else {
      fetchUserSetting()
    }
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      token.current?.cancel?.('cancel')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadData, token, firstLoad])
  useEffect(() => {
    if (error) {
      notification.error({
        description: error
      })
    }
  }, [error, timestamp])
  useEffect(() => {
    if (!hasTotal) {
      if (list?.length >= querys.itemsPerPage) { // 如果不是最后一页
        setNoLimitCount((querys.currentPage + 1) * querys.itemsPerPage)
      } else {
        setNoLimitCount(querys.currentPage * querys.itemsPerPage)
      }
    }
  }, [querys.itemsPerPage, querys.currentPage, list, hasTotal, setNoLimitCount])
  return {
    pageOpts: {
      pageSize: querys.itemsPerPage || client_ItemsPerPage,
      pageIndex: querys.currentPage || client_CurrentPage,
      stateTextVisible: hasTotal,
      recordCount,
      endJumpVisible: hasTotal,
      pageIndexVisible: hasTotal,
      pageSizeOptions: [10, 20, 30, 50, 100, 200, 400],
      onPagingChange
    },
    error,
    loading,
    data: list,
    querys,
    loadData: handleLoadData,
    load: handleLoadData,
    setQuerys,
    setPageIndex,
    setPageSize,
    setData,
    allData
  }
}

export function useRefresh () {
  const history = useHistory()
  const cacheDispatch = useCacheDispatch()
  return {
    push: url => {
      cacheDispatch({ type: 'reset' })
      setTimeout(() => {
        history.push(url)
      }, 50)
    },
    goBack: () => {
      cacheDispatch({ type: 'reset' })
      setTimeout(() => {
        history.goBack()
      }, 50)
    }
  }
}
