import React, { useMemo, useRef, useState, Ref, forwardRef, useImperativeHandle, useEffect } from 'react'
import Chart from './Chart'
import '../utils/china'
import { NSAreaCollection } from '../api'
let cacheTooltip = ''
export const mapOptions = (params: { currentCorpId: number, citys: { value2: any[] }[], mapName: string }) => ({
  title: {
    show: false,
    text: '青岛',
    left: 'center',
    textStyle: {
      color: '#fff'
    }
  },
  tooltip: {
    // 鼠标可以进入悬浮框
    enterable: false,
    // 触发方式 mousemove, click, none, mousemove|click
    // triggerOn: 'mousemove|click',
    // item 图形触发， axis 坐标轴触发， none 不触发
    trigger: 'item',
    // 浮层隐藏的延迟
    hideDelay: 400,
    borderWidth: 0,
    // 背景色
    backgroundColor: 'rgba(255,255,255, 0)',
    textStyle: {
      color: '#fff'
    },
    axisPointer: {
      animation: false
    },
    position: (point: any) => {
      // 固定在顶部
      const [x, y] = point
      return [x * 1.1, y * 1.1]
    },
    extraCssText: 'box-shadow: 0 0 0 rgba(0, 0, 0, 0);',
    formatter (param: any) {
      let itemAll = ''
      const regions = param?.data?.value2 ?? []
      regions.filter((e: any) => Number(e.corpId) === Number(params.currentCorpId))?.forEach((item: { corpName: string; corpId: number }) => {
        const str = item.corpName
        itemAll = itemAll + str
      })
      /** 加了一个缓存，处理多次render，无锡会变成常州，导致数据异常 */
      if (itemAll) {
        cacheTooltip = itemAll
      }
      return itemAll || cacheTooltip
    }
  },
  legend: {
    show: false
  },
  geo: {
    nameMap: {
      China: '中国'
    },
    map: params.mapName,
    label: {
      // show: true,
      color: '#fff',
      offset: [20, 20]
    },
    zoom: 1.2,
    itemStyle: {
      borderColor: '#04A8DD', // 区域边框颜色
      areaColor: '#0154A7', // 区域颜色
      borderWidth: 0.5, // 区域边框宽度
      shadowBlur: 5,
      shadowColor: 'rgba(107,91,237,.7)'
    },
    emphasis: {
      label: {
        color: '#fff'
      },
      style: {
        borderColor: '#04A8DD',
        areaColor: 'rgba(102,105,240,.3)',
        borderWidth: 1,
        shadowBlur: 5,
        shadowColor: 'rgba(135,138,255,.5)'
      }
    }
  },
  visualMap: {
    show: false,
    // 设置最大值和最小值
    min: 0,
    max: 50,
    // 设置位置
    left: '4%',
    top: '40%',
    text: ['高', '低'], // 文本，默认为数值文本
    calculable: true,
    seriesIndex: [0], // 作用在哪个series上
    inRange: {
      color: ['#FFCA4B'] // 粉黄
    }
  },

  series: [
    {
      name: '',
      map: params.mapName ?? 'china',
      type: 'map',
      geoIndex: 0,
      data: params.citys
    }
  ]
})

type TypeMapProps = {
    mapData: NSAreaCollection.Result[],
    currentCorpId?: number,
    areaCode?: string,
    areaName?: string
}
export default forwardRef((props: TypeMapProps, ref: Ref<any>) => {
    const { mapData, areaCode, areaName, currentCorpId } = props
    const chartRef = useRef<any>(null)
    const showTip = () => {
      chartRef.current?.getInstance()?.dispatchAction({
        type: 'showTip',
        seriesIndex: 0,
        dataIndex: 0
      })
    }
    const _map = useMemo(() => {
        const map: any = {
            citys: [],
            mapName: areaName,
            currentCorpId
        }
        mapData.forEach((item: NSAreaCollection.Result) => {
            const city= {
                name: item.provinceName,
                value: [1],
                value2: item.details
            }
            map.citys.push(city)
        })
        return map
    }, [areaName, mapData, currentCorpId])
    useImperativeHandle(ref, () => ({
      showTip
    }))
    useEffect(() => {
      return () => {
        cacheTooltip = ''
      }
    }, [])
    const [renderer] = useState<string>('canvas')
    return <div
              style={{
                  width: '100%',
                  height: '100%'
              }}
            >
            {
                mapData
                ? <Chart ref={chartRef} renderer={renderer} option={mapOptions(_map)} areaCode={areaCode} areaName={areaName}/>
                : <></>
            }
        </div>
})
