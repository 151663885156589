import React, { useCallback, useEffect, useRef, useState } from 'react'
import autofit from 'autofit.js'
import DashboardContainer from './components/DashboardContainer'
import DashboardTitle from './components/DashboardTitle'
import DashboardBody from './components/DashboardBody'
import DashboardCard from './components/DashboardCard'
import AreaMap from './components/AreaMap'
import GlobalData from './components/GlobalData'
import ProductLineCharts from './components/LineChart/index'
import CardBgL from './assets/images/cardbg-l.png'
import CardBgS from './assets/images/cardbg-s.png'
import bg from './assets/images/productbg.png'
import styled from 'styled-components'
import LineCollectionRate from './components/LineCollectionRate/index'
import WaringMsgList from './components/WaringMsgList/index'
import { useFetch } from '@/hooks/useFetch'
import { NSArea, NSCollectRateList, NSFactoryWarningList } from './api'
import { getSetName } from '../scandashboard/components/Chart'

const MainWrap = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr 2fr;
`
const areas = [{
  name: '青岛市',
  code: '370200'
}]
const Dashboard: React.FC<any> = () => {
  const [areaCode, setAreaCode] = React.useState(areas?.[0]?.code)
  const [areaName, setAreaName] = React.useState(areas?.[0]?.name)
  const [corpId, setCorpId] = React.useState<number | null>(null)
  const [mapData, setMapData] = React.useState<any>([{
    provinceMergerName: '青岛市',
    details: [{
      corpName: '' // 工厂名称
    }]
  }])
  const areaMapRef = useRef<any>(null)
  const loopTime = 10 * 1000
  const loopRef = useRef<number>(0)
  const intervalRef = useRef<any>(null)
  const convertData = useCallback(async (item: any) => {
    const name = await getSetName(item?.provinceCode, item?.cityCode)
    item?.provinceMergerName && setAreaName(item.provinceMergerName)
    item?.provinceCode && setAreaCode(item.provinceCode)
    setCorpId(item?.corpId)
    item?.provinceMergerName && setMapData([{
      provinceName: name,
      details: [{
        corpId: item?.corpId,
        corpName: item?.corpName // 工厂名称
     }]
    }])
  }, [])
  useEffect(() => {
    autofit.init({
      dh: 1080,
      dw: 1920,
      el: '#dashboard',
      resize: true
    })
  }, [])
  useEffect(() => {
    NSArea.action({}).then(res => {
      const { data: _data } = res
      if (_data?.length) {
        convertData(_data?.[0])
        loopRef.current = loopRef.current + 1
        intervalRef.current && clearInterval(intervalRef.current)
        intervalRef.current = setInterval(() => {
          convertData(_data[loopRef.current])
          if (loopRef.current >= _data.length - 1) {
            loopRef.current = 0
          } else {
            loopRef.current = loopRef.current + 1
          }
        }, loopTime)
      }
    })
    return () => {
      intervalRef.current && clearInterval(intervalRef.current)
    }
  }, [convertData, loopTime])
  // #region 产线综合采集率
  const [collectRoteList, setCollectRoteList] = useState<any[]>([])
  const { data: collectRoteData, loadData: collectRoteLoad, setQuerys: setRoteQuerys } = useFetch<NSCollectRateList.Params, NSCollectRateList.Result>(NSCollectRateList.action, { corpId, dateRange: '', currentPage: 1, itemsPerPage: 2000 })
  useEffect(() => {
    if (corpId) {
      setRoteQuerys({
        corpId
      })
      collectRoteLoad()
    }
  }, [collectRoteLoad, corpId, setRoteQuerys])
  useEffect(() => {
    if (collectRoteData?.items?.length) {
      setCollectRoteList(collectRoteData?.items?.map((it: NSCollectRateList.Items) => {
        return {
          ...it,
          collectComprehensiveRate: it.collectComprehensiveRate,
          corpName: it.corpName,
          lineName: it.productionLineName
        }
      }))
    } else {
      setCollectRoteList([])
    }
  }, [collectRoteData])
  // #endregion
  // #region 获取预警信息列表
  const [waringMsgList, setWaringMsgList] = useState([])
  const { data, loadData, setQuerys }: any = useFetch(NSFactoryWarningList.action, { corpId, dateRange: '', currentPage: 1, itemsPerPage: 2000 })
  useEffect(() => {
    if (corpId) {
      setQuerys({
        corpId
      })
      loadData()
    }
  }, [loadData, corpId, setQuerys])
  useEffect(() => {
    if (data?.items?.length) {
      setWaringMsgList(
        data?.items?.map((it: NSFactoryWarningList.Items) => {
          return {
            ...it,
            errorMsg: it.errorMsg,
            corpName: it.corpName,
            lineName: it.productionLineName
          }
        })
      )
    } else {
      setWaringMsgList([])
    }
  }, [data])
  // #endregion

  return <DashboardContainer id='dashboard' background={bg}>
    <DashboardTitle>生产可视化看板</DashboardTitle>
    <DashboardBody>
      <DashboardCard background={CardBgL} title='工厂分布' height={423}>
        <MainWrap>
          <AreaMap
            ref={areaMapRef}
            currentCorpId={corpId ?? 0}
            mapData={mapData}
            areaCode={areaCode}
            areaName={areaName}
          />
          <GlobalData corpId={corpId}/>
        </MainWrap>
      </DashboardCard>
      <DashboardCard background={CardBgS} title='综合采集率' height={423}>
        <LineCollectionRate data={collectRoteList}/>
      </DashboardCard>
      <DashboardCard background={CardBgL} title='产线实时监控' height={423}>
        <ProductLineCharts corpId={corpId} loopTime={loopTime}/>
      </DashboardCard>
      <DashboardCard background={CardBgS} title='工厂预警' height={423}>
        <WaringMsgList data={waringMsgList} />
      </DashboardCard>
    </DashboardBody>
  </DashboardContainer>
}
export default Dashboard
