
import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import '../index.css'
import { useFetch } from '@/hooks'
import { Button, notification, Form, Input } from 'tea-component'
import { useForm, Controller } from 'react-hook-form'
import { resetPassApi } from '../api'
import { getPasswordSetting } from '@/pages/sysmgr/api'
function GetCode (props) {
    const { userId, verCode } = props
    const { control, formState, handleSubmit, getValues } = useForm()

    // 登录
    const [loginloading, setLoginloading] = useState(false)
    const [isMounted, setIsMounted] = useState(true)
    const [isVerify, setIsVerify] = useState(false)
    const [passwordValid, setPasswordValid] = useState(false)
    const { loadData, token } = useFetch(getPasswordSetting)
    useEffect(() => {
        loadData()
        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            token.current?.cancel?.('cancel')
        }
    }, [loadData, token])

    // const rules = useMemo(() => {

    //     if (data) {
    //         return passwordRules(data.length, data.reg)
    //     }
    // }, [data])
    // input状态
    function getStatus (meta) {
        if (!meta.isDirty && !formState.isSubmitted) {
            return null
        }
        return meta.invalid ? 'error' : 'success'
    }

    function onSubmit ({ confirmNewPassword, newPassword }) {
        setLoginloading(true)
        resetPassApi({ confirmNewPassword, newPassword, verificationCode: verCode, id: userId }).then(rs => {
            if (!rs.success) {
                notification.error({ description: rs.message })
                setLoginloading(false)
            } else {
                if (isMounted) {
                    setIsVerify(true)
                    setLoginloading(true)
                    notification.success({
                        description: '修改成功，即将跳转',
duration: 1000,
onClose: () => {
                            props.history.push('/sysmgr/user/login')
                        }
                    })
                }
            }
        })
    }
    useEffect(() => {
        return () => {
            setIsMounted(false)
        }
    }, [isVerify])
    return (
        <form style={{ width: 'form-material', margin: 'auto' }} id="loginForm" onSubmit={handleSubmit(onSubmit)}>
            <Form autoComplete="off">
                {/* <Controller
                    name="confirmNewPassword"
                    control={control}
                    rules={{ required: "请输入旧密码" }}
                    render={({ field, fieldState }) => (
                        <Form.Item
                            className={style.loginForm}
                            required={true}
                            status={
                                formState.isValidating ? "validating" : getStatus(field, fieldState)
                            }
                            message={formState.errors.confirmNewPassword?.message}
                            style={{ color: "red" }}
                        >
                            <Input type="confirmNewPassword" {...field} autoComplete="off" placeholder="请输入旧密码" className={style.pwdInput} />
                        </Form.Item>
                    )}
                /> */}
                <Controller
                    name="confirmNewPassword"
                    control={control}
                    rules={{
                        required: '请输入新密码',
validate: {
                            matchesPreviousPassword: () => passwordValid || '新密码不符合规则'
                        }
                    }}
                    render={({ field, fieldState }) => (
                        <Form.Item
                            required={true}
                            status={
                                formState.isValidating ? 'validating' : getStatus(fieldState)
                            }
                            message={formState.errors.confirmNewPassword?.message}
                        >
                            <Input.Password rules={false} size='full' {...field} autoComplete="off" placeholder="请输入新密码" className='pwdInput' onChange={(value, { valid }) => {
                                field.onChange(value)
                                setPasswordValid(valid)
                            }} />
                        </Form.Item>
                    )}
                />
                <Controller
                    name="newPassword"
                    control={control}
                    rules={{
                        required: '请再次输入新密码',
validate: {
                            matchesPreviousPassword: value => {
                                const { confirmNewPassword } = getValues()
                                return confirmNewPassword === value || '两次新密码不一致'
                            }
                        }
                    }}
                    render={({ field, fieldState }) => (
                        <Form.Item
                            style={{ width: '100%' }}
                            required={true}
                            status={
                                formState.isValidating ? 'validating' : getStatus(fieldState)
                            }
                            message={formState.errors.newPassword?.message}
                        >
                            <Input.Password size='full' rules={false} type="newPassword" {...field} autoComplete="off" placeholder="请再次输入新密码" className='pwdInput' />
                        </Form.Item>
                    )}
                />
            </Form>
            <Button className='blockBtn' type="primary" htmlType="submit" loading={loginloading} disabled={formState.isValidating}>确认</Button>
        </form>
    )
}

export default withRouter(GetCode)
