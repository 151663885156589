import React, { useMemo } from 'react'
import AutoTable from '../AutoTable'
import { TypeColumn } from '@/types'
type TypeUserSituationProps = {
    data: any[],
}
const ScanWarning: React.FC<TypeUserSituationProps> = (props) => {
    const { data } = props
    const columns = useMemo<TypeColumn[]>(() => {
        return [{
          key: 'index',
          header: '序号',
          width: 80,
          align: 'center'
        }, {
          key: 'corpName',
          header: '客户名称',
          width: 200,
          align: 'center',
          headerAlign: 'center'
        }, {
          key: 'salesRegion',
          header: '所属销售单位',
          width: 200,
          headerAlign: 'center',
          align: 'center'
        }, {
          key: 'productName',
          header: '单位名称',
          width: 200,
          headerAlign: 'center',
          align: 'center'
        }, {
          key: 'scanArea',
          header: '扫码位置',
          width: 200,
          headerAlign: 'center',
          align: 'center'
      }]
    }, [])
    return <AutoTable columns={columns} data={data} pageSize={4} height={185}/>
}
export default ScanWarning
