import React, { useMemo } from 'react'
import AutoTable from '../AutoTable'
import { TypeColumn } from '@/types'
type TypeUserSituationProps = {
    data: any[],
}
const InFlowRanking: React.FC<TypeUserSituationProps> = (props) => {
    const { data } = props
    const columns = useMemo<TypeColumn[]>(() => {
        return [{
            key: 'index',
            header: '序号',
            width: 60,
            align: 'center'
        }, {
            key: 'productName',
            header: '产品名称',
            width: 600,
            align: 'left',
            headerAlign: 'center'
        }, {
            key: 'num',
            header: '扫码次数',
            width: 120,
            headerAlign: 'right',
            align: 'right'
        }]
    }, [])
    return <AutoTable columns={columns} data={data} pageSize={4} height={185}/>
}
export default InFlowRanking
