import React, { useCallback, useMemo, useState } from 'react'
import { Button, Table, Input, notification, RadioGroup, Radio, InputNumber } from '@/components/Base'

const { autotip } = Table.addons
const AwardRuleTable: React.FC<any> = (props) => {
    const { rewardRule, value, onChange } = props
    const [id, setId] = useState(0)
    const [fields, setFields] = useState<any>(value)
    const handleAddCode = useCallback(() => {
        if (rewardRule) {
            const add = fields
            add.push({
                id: id + 1,
                includeMaskCode: true,
                number: 0,
                content: '',
                priority: 1, // 优先级
                proportion: 0// 比例
            })
            setFields(add)
            onChange('strategyList', add)
            setId(id + 1)
        } else {
            notification.error({ description: '请先选择配置策略规则' })
        }
    }, [rewardRule, fields, id, onChange])

    const handleChangeCode = useCallback((_id: number, obj: object) => {
        const mapArray = fields.map((val: any) => {
            return val.id === _id ? { ...val, ...obj } : val
        })
        onChange('strategyList', mapArray)
        setFields(mapArray)
    }, [fields, onChange])

    const handleOnDel = useCallback((_id: number) => {
        const filteredArray = fields.filter((obj: any) => {
            return obj.id !== _id
        })
        onChange('strategyList', filteredArray)
        setFields(filteredArray)
    }, [fields, onChange])

    const tableColumnsMemo: any = useMemo(() => {
        let columns: any = [
            {
                key: 'includeMaskCode',
                header: '包含内码',
                width: 120,
                render: (record: any) => {
                    return (
                        <RadioGroup
                            defaultValue={record.includeMaskCode}
                            value={record.includeMaskCode}
                            onChange={(_v: any) => handleChangeCode(record.id, { includeMaskCode: _v })}
                            >
                            <Radio name={true}>是</Radio>
                            <Radio name={false}>否</Radio>
                        </RadioGroup>
                    )
                }
            }
        ]
        if (rewardRule === 1) {
            columns.push(
                {
                    key: 'proportion',
                    header: '比例',
                    render: (record: any) => {
                        return (
                            <InputNumber
                                defaultValue={record.proportion}
                                value={record.proportion}
                                onChange={(_value: string) => {
                                    handleChangeCode(record.id, { proportion: _value })
                                }}
                                min={0}
                                max={100}
                                step={1}
                                formatter={(_value: any) => `${_value}%`}
                                parser={(_val: any) => Number(_val.replace('%', ''))}
                            />
                        )
                    }
                })
        } else {
            columns.push({
                key: 'number',
                header: '个数',
                render: (record: any) => {
                    return (
                        <InputNumber
                            defaultValue={record.number}
                            value={record.number}
                            min={0}
                            onChange={(_value: string) => {
                                handleChangeCode(record.id, { number: _value })
                            }
                            }
                        />
                    )
                }
            })
        }
        columns = [...columns,
            {
                key: 'priority',
                header: '优先级',
                render: (record: any) => {
                    return (
                        <InputNumber
                            defaultValue={record.priority}
                            value={record.priority}
                            min={1}
                            onChange={(_value: string) => {
                                handleChangeCode(record.id, { priority: _value })
                            }
                            }
                        />
                    )
                }
            },
            {
                key: 'content',
                header: '内容',
                width: 220,
                render: (record: any) => {
                    return (
                        <Input
                            defaultValue={record.content}
                            value={record.content}
                            onChange={(_value: string) => {
                                const t = _value.replace(/[^\u4E00-\u9FA5]/g, '') // 只能输入汉字
                                handleChangeCode(record.id, { content: t })
                            }}
                            placeholder="请输入内容(只能输入1-6个汉字)"
                            maxLength={6}
                        />
                    )
                }
            },
            {
                key: 'operation',
                header: '操作',
                width: 80,
                render: (record: any, _: any, index: any) => (<Button type="link" htmlType="button" onClick={() => { handleOnDel(record.id) }} style={{ marginTop: '6px' }}>删除</Button>)
            }
        ]
        return columns
    }, [handleChangeCode, handleOnDel, rewardRule])

    return (
        <div key='property'>
            <Table
                verticalTop
                disableTextOverflow={true}
                bordered
                records={fields}
                recordKey="id"
                columns={tableColumnsMemo}
                addons={[
                    autotip({
                        emptyText: '无数据'
                    })
                ]}
            />
            <div style={{ textAlign: 'center', padding: '10px', border: '1px dotted #006eff', marginTop: '10px', cursor: 'pointer' }} onClick={() => handleAddCode()}>
                <Button type='link' htmlType='button'>添加分组</Button>
            </div>
        </div>
    )
}
export default AwardRuleTable
