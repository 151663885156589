export function passwordRules (length, regArray) {
    const rules = []
    rules.push({
        text: `长度至少为${length}位字符`,
        validator: function (value) { return value.length >= length }
    })

    const subRules = []
    // 密码规则 0、数字，1、小写字母，2、大写字母，3、特殊字符（除空格）
    regArray.forEach(reg => {
        if (reg === 0) {
 subRules.push({
            text: '数字 0 ～ 9',
            validator: function (value) { return /\d/.test(value) }
        })
} else if (reg === 1) {
 subRules.push({
            text: '小写字母 a ~ z',
            validator: function (value) { return /[a-z]/.test(value) }
        })
} else if (reg === 2) {
 subRules.push({
            text: '大写字母 A ～ Z',
            validator: function (value) { return /[A-Z]/.test(value) }
        })
} else {
 subRules.push({
            text: '()!@#$%^&*|?><',
            validator: function (value) { return /[()!@#$%^&*|?><]/.test(value) }
        })
}
    })

    if (subRules.length > 0) {
        rules.push({
            text: '至少包含以下几项',
            validator: function (_, results) {
                return results.reduce(function (pre, cur) { return pre && cur }, true)
            },
            subRules: subRules
        })
    }
    return rules
}
