import React, { useMemo } from 'react'
import { ScrollBoard } from '@jiaminghi/data-view-react'
import { TypeColumn } from '@/types'
import styled from 'styled-components'
type TypeConfig = {
    headerBGC?: string,
    oddRowBGC?: string,
    evenRowBGC?: string,
    index?: boolean,
    indexHeader?: string,
    columnWidth?: number[],
    align?: string[],
    rowNum?: number,
    waitTime?: number,
    tooltip?: {
        trigger: string
    }
}
type TypeUserSituationProps = {
  columns: TypeColumn[],
  data: any[],
  config?: TypeConfig,
  pageSize?: number,
  height?: number
}
const AutoTableWrap = styled.div<any>`
  width: 100%;
  /* height: 100%; */
  overflow: hidden;
  font-size: 16px;
  .dv-scroll-board {
    height: 100% !important;
    .header {
      .header-item {
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
        background: linear-gradient(0deg, #56D1ED 0%, #CAF6FF 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    .rows {
      height: ${props => props.height ? props.height + 'px !important' : 'auto'};
      .row-item {
        border-bottom: 1px solid #04C0EB;
      }
      .ceil {
        font-size: 16px;
      }
    }
  }
`
export const AutoTableNoData = styled.div`
  width: 100%;
  height: 100px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 25px;
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #FFFFFF;
`
const AutoTableHeaderWrap = styled.div`
  display: flex;
`
const AutoTableHeaderItemWrap = styled.div<any>`
  width: ${props => props.width ? props.width + 'px' : '100%'};
  text-align: ${props => props.align ?? 'left'};
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #FFFFFF;
  background: linear-gradient(0deg, #56D1ED 0%, #CAF6FF 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`
const AutoTableHeader = ({ columns }: { columns: TypeColumn[] }) => {
  return <AutoTableHeaderWrap>
    {
      columns.map(e => {
        return <AutoTableHeaderItemWrap key={e.key} width={e.width} align={e.headerAlign ?? e.align}>
          {e.header}
        </AutoTableHeaderItemWrap>
      })
    }
  </AutoTableHeaderWrap>
}
const AutoTable: React.FC<TypeUserSituationProps> = (props) => {
    const { columns, data=[], config, pageSize = 10, height } = props
    const hasIndex = useMemo<boolean>(() => {
        return columns.some(e => e.header === '序号')
    }, [columns])
    const defaultConfig = useMemo<TypeConfig>(() => {
        return {
          // header: columns.filter(e => e.header !== '序号').map(e => e.header),
          // 表头背景色
          headerBGC: 'transparent',
          // 奇数行背景色
          oddRowBGC: 'transparent',
          // 偶数行背景色
          evenRowBGC: 'transparent',
          // 行号
          index: hasIndex,
          // 行号表头
          indexHeader: hasIndex ? '序号' : '',
          // 宽度
          columnWidth: columns.map(e => Number((e.width + '')?.replace(/px/g, ''))),
          // 对其方式
          align: columns?.map(e => e.align ?? 'left') ?? [],
          // 表行数
          rowNum: pageSize,
          // 轮播时间
          waitTime: 1000,
          carousel: 'single',
          hoverPause: false,
          tooltip: {
              trigger: 'item'
          }
        }
    }, [columns, hasIndex, pageSize])
    // 数据转换
    const convertData = useMemo<any>(() => {
      const _data: any[] = []
      for (const dataItem of data) {
        const _arr: any[] = []
        for (const column of columns.filter(e => e.header !== '序号')) {
          _arr.push(`<Tooltip title='${dataItem[column.key]}'>${dataItem[column.key]}</Tooltip>`)
        }
        _data.push(_arr)
      }
      return _data
    }, [columns, data])
    return <AutoTableWrap height={height}>
        <AutoTableHeader columns={columns} />
        {
            data?.length
            ? <ScrollBoard
                    config={{
                      ...defaultConfig,
                      ...config,
                      data: convertData
                    }}
                  >
                </ScrollBoard>
            : <AutoTableNoData>暂无数据</AutoTableNoData>
        }
    </AutoTableWrap>
}
export default AutoTable
