import React, { useEffect, useState } from 'react'
import {
    Form,
    Button,
    notification
} from 'tea-component'
import { useForm, Controller } from 'react-hook-form'
import TranspondSelect from '@/components/Select/transpondSelect'
import { getCodeRequestForward } from '../../api'

export default function Transpond ({ id, fields }) {
    const { control } = useForm({})
    const [printCorpId, setPrintCorpId] = useState('')
    const [disabled, setDisabled] = useState(true)
    const [downloadLoading, setDownloadLoading] = useState(false)
    const HandleTranspond = async () => {
        setDownloadLoading(true)
        const { success, message } = await getCodeRequestForward({ codeRequestId: id, printCorpId })
        if (success) {
            notification.success({ description: '转发成功' })
            setDownloadLoading(false)
        } else {
            notification.error({ description: message })
            setDownloadLoading(false)
        }
    }
    useEffect(() => {
        if (printCorpId) {
            setDisabled(false)
        } else {
            setDisabled(true)
        }
    }, [printCorpId])
    return <>
        <Form>
            <Controller
                name="printCorpId"
                control={control}
                render={({ field }) => {
                    return (
                        <Form.Item
                            label="盖厂"
                            align='middle'
                            extra="请选择实际生产的罐盖厂家，用于接收码包"
                        >
                            <TranspondSelect
                                placeholder="请选择盖厂"
                                value={field.value}
                                onChange={(v) => {
                                    field.onChange(v)
                                    setPrintCorpId(v)
                                }}
                                params={{ corpLevelCode: 'qingpi_ykg_factory' }}
                            />
                        </Form.Item>
                    )
                }}
            />
        </Form>
        <div style={{ marginTop: '10px', padding: '20px', backgroundColor: '#D5E7FF' }}>
            <Form>
                <Form.Item label="申请单号">
                    <Form.Text>{fields.requestNo}</Form.Text>
                </Form.Item>
                <Form.Item label="工厂" >
                    <Form.Text>{fields.corpName}</Form.Text>
                </Form.Item>
                <Form.Item label="罐厂" >
                    <Form.Text>{fields.printCorpName}</Form.Text>
                </Form.Item>
                <Form.Item label="产品" >
                    <Form.Text>{fields.productName}</Form.Text>
                </Form.Item>
                <Form.Item label="数量" >
                    <Form.Text>{fields.numberStr}</Form.Text>
                </Form.Item>
                <Form.Item label="申请时间" >
                    <Form.Text>{fields.createdTime}</Form.Text>
                </Form.Item>
                <Form.Item label="备注" >
                    <Form.Text>{fields.remark}</Form.Text>
                </Form.Item>
            </Form>
        </div>
        <Button type="primary" onClick={() => { HandleTranspond() }} loading={downloadLoading} disabled={disabled} style={{ width: '100%', marginTop: 20 }}>
            转发
        </Button>
    </>
}
