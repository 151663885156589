import styled from 'styled-components'
import topBar from '../assets/images/topbar.png'
const DashboardTitle = styled.div<any>`
  height: 85px;
  color: #3CE7FF;
  font-family: Source Han Sans CN;
  font-size: 50px;
  font-weight: bold;
  padding: 18px 0px 20px 0px;
  background-image: url(${topBar});
  background-size: 100%;
  background-repeat: no-repeat;
  width: 100%;
  letter-spacing: 3px;
  display: flex;
  justify-content: center;
`
export default DashboardTitle
