import React from 'react'
import ReactDOM from 'react-dom'
import '@/assets/iconfont/iconfont.css'
import 'tea-component/dist/tea.css'
import './ntea.css'
import './index.css'
import Page from './Page'
import reportWebVitals from './reportWebVitals'
ReactDOM.render(
  <Page />,
  document.getElementById('root')
)
reportWebVitals()
