import { NMoment } from './moment'
export namespace Util {
  export const Moment = NMoment
}

/**
 * @author hanqi
 * @description 数字 千分位
 * @param value
 * @returns {string}
 */
export function numberToThousands (number: number | bigint): string {
  return new Intl.NumberFormat().format(number)
}

export function numberToDemicals (number: number, decimalPlaces: number): string {
  return (number * Math.pow(10, decimalPlaces) / Math.pow(10, decimalPlaces)).toFixed(decimalPlaces)
}
