import React from 'react'
import { Form, Text } from 'tea-component'

export default function CodeViewInfo (fields: any) {
  return (<div style={{ marginTop: '30px' }}>
    <Text theme='danger'>*请仔细核实配置信息，影响码包生成和内码的印刷。优先级数值越小，中奖优先级越高。</Text>
    <div style={{ padding: '20px', backgroundColor: '#D5E7FF', marginTop: '10px' }}>
    <Form>
    <Form.Item label="申请单号" >
            <Form.Text>{fields.requestNo}</Form.Text>
        </Form.Item>
        <Form.Item label="申请企业" >
            <Form.Text>{fields.corpName}</Form.Text>
        </Form.Item>
      <Form.Item label="罐厂">
        <Form.Text>{fields.printCorpName}</Form.Text>
      </Form.Item>
      <Form.Item label="产品">
        <Form.Text>{fields.productName}</Form.Text>
      </Form.Item>
      <Form.Item label="申请数量">
        <Form.Text>{fields.numberStr}</Form.Text>
      </Form.Item>
          <Form.Item label="申请时间" >
              <Form.Text>{fields.createdTime}</Form.Text>
          </Form.Item>
          <Form.Item label="备注" >
              <Form.Text>{fields.remark}</Form.Text>
          </Form.Item>
    </Form>
    </div>
    </div>)
}
