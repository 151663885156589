import styled from 'styled-components'
const DashboardBody = styled.div<any>`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 1fr 1fr;
  color: #ffffff;
  font-size: 36px;
  row-gap: 35px;
`
export default DashboardBody
