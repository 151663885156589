import React, { useCallback, useEffect, useState } from 'react'
import {
    Form,
    Input,
    Button,
    Alert,
    List,
    Stepper,
    notification,
    Select,
    Modal
} from 'tea-component'
import { useForm, Controller } from 'react-hook-form'
import { downloadFile } from '@/utils'
import { getDictionaryItemList } from '@/axios/api.js'
import { getSubpackageDownload, $resendsCode } from '../../api'

export default function Download ({ id, fields }) {
    const [randomCode, setRandomCode] = useState('')
    const { handleSubmit, control, reset } = useForm({})
    const [channelNumber, setChannelNumber] = useState(4)
    const [disabled, setDisabled] = useState(true)

    // #region 发送验证码
    let timer = null
    const [send, setSend] = useState(true)
    const [time, setTime] = useState(60)
    const handelResend = () => {
        sendCode()
        setSend(true)
        let times = time
        timer = setInterval(() => {
            if (times < 1) {
                clearInterval(Number(timer))
                setTime(60)
                setSend(false)
            } else {
                times -= 1
                setTime(times)
            }
        }, 1000)
    }
    const sendCode = useCallback(async () => {
        const { success, message } = await $resendsCode({ id })
        if (success) {
            notification.success({ description: '发送成功' })
        } else {
            notification.error({ description: message })
        }
    }, [id])
    useEffect(() => {
        reset()
        setSend(false)
        return () => { // 清除计时器
            clearInterval(Number(timer))
        }
    }, [reset, timer])
    // #endregion

    // #region 分包下载
    const [downloadLoading, setDownloadLoading] = useState(false)
    const HandleDownload = async () => {
        try {
            const yes = await Modal.confirm({
                message: `请确认通道数量${channelNumber}是否正确？`,
                okText: '确定',
                cancelText: '取消'
            })
            if (yes) {
                setDownloadLoading(true)
                const res = await getSubpackageDownload({ codeRequestId: id, channelNumber, randomCode })
                downloadFile(res)
                setDownloadLoading(false)
            }
        } catch (e) {
            notification.error({ description: e.message })
        }
    }
    useEffect(() => {
        if (channelNumber&&randomCode) {
            setDisabled(false)
        } else {
            setDisabled(true)
        }
    }, [channelNumber, randomCode])

    const [options, setOptions] = useState([])
    const getOptions = useCallback(async () => {
        const { success, data } = await getDictionaryItemList({ groupName: 'ChannelNumber', status: true })
        if (success) {
            const value = (data || []).map((it) => ({
                text: it.displayName,
                value: it.name
            }))
            setOptions(value)
        }
    }, [])
    useEffect(() => {
        getOptions()
    }, [getOptions])
    // #endregion

    return <>
        <Stepper
            type="process-vertical"
            steps={[
                {
                    id: 'prepare',
                    label: '请输入验证码',
                    detail: (
                        <>
                            <form onSubmit={handleSubmit()}>
                                <Controller
                                    name="randomCode"
                                    control={control}
                                    render={({ field }) => {
                                        return (
                                            <Form.Item
                                                extra="验证码将发送申请配置中的码包接收人"
                                            >
                                                <Input
                                                    {...field}
                                                    placeholder="请输入6位验证码"
                                                    maxLength={6}
                                                    onChange={(v) => {
                                                        field.onChange(v)
                                                        setRandomCode(v)
                                                    }}
                                                />
                                                <Button type="weak" htmlType="button" onClick={handelResend} style={{ marginLeft: '10px', width: '100px' }} disabled={send}>{send ? `${time}s` : '发送验证码'}</Button>
                                            </Form.Item>
                                        )
                                    }}
                                />
                            </form>
                            <Alert style={{ marginTop: '10px' }}>
                                <h4 style={{ marginBottom: 8 }}>没有收到验证码怎么办？</h4>
                                <p>邮件方式</p>
                                <List type="bullet">
                                    <List.Item>请检查个人信息中邮箱地址正确，然后点击。<Button type="link" htmlType="button" onClick={handelResend}>重新发送</Button> </List.Item>
                                    <List.Item>
                                        请检查垃圾箱。
                                    </List.Item>
                                </List>
                            </Alert>
                        </>
                    )
                },
                {
                    id: 'upload',
                    label: '选择通道数量',
                    detail: (
                        <>
                            <Form>
                                <Controller
                                    name="channelNumber"
                                    control={control}
                                    render={({ field: { value, onChange } }) => {
                                        return (
                                            <Form.Item
                                                label="通道数量"
                                                align='middle'
                                                extra="请仔细核实生产的通道数量，影响拆分码包的大小和数量"
                                            >
                                                <Select
                                                    clearable
                                                    appearance="button"
                                                    value={value}
                                                    onChange={(v) => {
                                                        onChange(v)
                                                        setChannelNumber(v)
                                                    }}
                                                    placeholder="请选择通道数量"
                                                    size="m"
                                                    matchButtonWidth
                                                    defaultValue={'4'}
                                                    options={options}
                                                />
                                            </Form.Item>
                                        )
                                    }}
                                />
                            </Form>
                            <div style={{ marginTop: '10px', padding: '20px', backgroundColor: '#D5E7FF' }}>
                                <Form>
                                    <Form.Item label="申请单号">
                                        <Form.Text>{fields.requestNo}</Form.Text>
                                    </Form.Item>
                                    <Form.Item label="工厂" >
                                        <Form.Text>{fields.corpName}</Form.Text>
                                    </Form.Item>
                                    <Form.Item label="罐厂" >
                                        <Form.Text>{fields.printCorpName}</Form.Text>
                                    </Form.Item>
                                    <Form.Item label="产品" >
                                        <Form.Text>{fields.productName}</Form.Text>
                                    </Form.Item>
                                    <Form.Item label="数量" >
                                        <Form.Text>{fields.numberStr}</Form.Text>
                                    </Form.Item>
                                    <Form.Item label="申请时间" >
                                        <Form.Text>{fields.createdTime}</Form.Text>
                                    </Form.Item>
                                    <Form.Item label="备注" >
                                        <Form.Text>{fields.remark}</Form.Text>
                                    </Form.Item>
                                </Form>
                            </div>
                        </>
                    )
                },
                {
                    id: 'upload',
                    label: '点击分包下载',
                    detail: (
                        <>
                            <Button type="primary" onClick={() => { HandleDownload() }} loading={downloadLoading} disabled={disabled} style={{ width: '100%', marginTop: 20 }}>
                                分包下载
                            </Button>
                        </>
                    )
                }
            ]} />
    </>
}
