import React, { useContext, useState, useMemo, useCallback, useEffect } from 'react';
import { KeepaliveRouterSwitch, KeepaliveRoute } from 'react-keepalive-router';
import { Switch, Route } from 'react-router-dom'
import loadable from '@loadable/component'
import ErrorBoundary from '@/components/ErrorBoundary'
import { getLocalesJs } from '@/axios/api'
import { GetSysInfo } from '@/assets/js/storage'
import { indexOf } from '@antv/util';
import { IntlProvider } from 'react-intl'
import Context from '@/context';
// import { useFetch } from '@/hooks'
import { useScript } from '@/hooks/useScript.js'
const Mock = require('mockjs');

function loadMock (url) {
    if (process.env.NODE_ENV === "development" && process.env.REACT_APP_MOCK === "true") {
        const dirArr = eachDirs(url);
        dirArr.forEach(e => {
            let mockObj = {};
            try {
                mockObj = require(`../pages${e}/mock.js`).default;
            } catch (error) {
            } finally {
                for (const key in mockObj) {
                    if (Object.hasOwnProperty.call(mockObj, key)) {
                        const target = mockObj[key];
                        Mock.mock(new RegExp(key), "post", target);
                    }
                }
            }
        })
    }
}

function eachDirs (url) {
    const urlArr = url.split("/").filter(e => e && e !== "index");
    let dirArr = new Array(urlArr.length).fill('');
    urlArr.forEach((e, i) => {
        const len = urlArr.length - i;
        for (let j = 0; j < len; j++) {
            dirArr[i + j] += "/" + e;
        }
    });
    return dirArr;
}

export default function Routes (props) {
    const { routes } = props
    const { name: sysName, url: homeurl } = GetSysInfo()
    const provider = useContext(Context);
    const HomePage = url => loadable(() => import(`../pages${url.toLowerCase()}`));
    //动态加载组件
    const AsyncPage = loadable(_props => import(`../pages${_props.url}`)
        .then(component => component)
        .catch(() => {
            return class extends React.Component {
                render () { return <></> }
            }
        }), {
        cacheKey: p => p.url
    }
    )

    //获取根url (开始到倒数第二级)
    //比如/productmgr/product/index ,得到 /productmgr/product
    //定制路由 4层 比如 /custom/sdl/product/index ,得到 /custom/sdl
    //定制路由 5层 比如 /custom/sdl/productmgr/product/index ,得到 /custom/sdl/productmgr
    function getRootPath (url) {
        if (url.indexOf('/') < 0) { return '' }
        //去除最后一级
        url = url.substring(0, url.lastIndexOf('/'))
        if (url / indexOf('/') < 0) { return url }
        //去除最后第二级
        url = url.substring(0, url.lastIndexOf('/'))
        return url
    }

    //获取所有一级路由
    const [rootPaths, setRootPaths] = useState([])
    useEffect(() => {
        // console.log('router.effect.urls')
        let rootUrls = []
        routes.data.forEach(route => {
            let rootUrl = getRootPath(route.url.toLowerCase())
            if (rootUrl && !rootUrls.some(root => root === rootUrl)) {
                rootUrls.push(rootUrl)
            }
        });
        setRootPaths(rootUrls)
    }, [routes])

    //#region 多语言相关
    //加载后端定制js文件列表
    const [localeJsUrls, setLocaleJsUrls] = useState([])

    useEffect(() => {
        getLocalesJs({}).then(res => {
            if (res.success) {
                const temp = res.data?.filter(x => x.languageType === provider.locale.language).map(r => `${r.storagePath}?${r.version}`)
                setLocaleJsUrls(temp)
            }
        })
    }, [provider])

    const messagesFromStorage = JSON.parse(localStorage.getItem('sys_i18n_messages')) || {}
    const [localeJsData, setLocaleJsData] = useState(messagesFromStorage)
    const onLoadJsReady = useCallback
        (() => {
            if (window[provider.locale.language]) {
                const messagesFromJs = window[provider.locale.language]
                setLocaleJsData(messagesFromJs)
                localStorage.setItem('sys_i18n_messages', JSON.stringify(messagesFromJs))
            }
        }, [setLocaleJsData])
    //加载js
    useScript(localeJsUrls, onLoadJsReady)
    //加载本地json文件
    const [localMessages, setLocalMessages] = useState({})
    useEffect(() => {
        const requireJson = e => {
            const { language } = provider.locale
            try {
                return require(`../pages${e}/i18n/${language}.json`)
            } catch (error) {
                return {}
            }
        }
        let messages = {}
        //加载通用语言json
        let commonMessages = requireJson('')
        messages = { ...messages, ...commonMessages }

        rootPaths.forEach(e => {
            let moduleMessages = requireJson(e)
            messages = { ...messages, ...moduleMessages };
        });
        setLocalMessages(messages)
    }, [rootPaths, provider.locale])

    const messageData = useMemo(() => {
        let d = { ...localMessages, ...localeJsData }
        return d
    }, [localeJsData, rootPaths, localMessages])
    //#endregion


    const getUrl = () => {
        let pageTitle = '首页'
        routes.data.map(route => {
            if (route.url === homeurl) {
                pageTitle = route.title
            }
        })
        return pageTitle
    }
    const getPageName = () => {
        let pageName = ''
        routes.data.map(route => {
            if (route.url === homeurl) {
                pageName = route.name
            }
        })
        return pageName
    }

    const isShowHome = () => {
        return routes.data.find(route => route.url === homeurl)
    }

    return (<>
        <KeepaliveRouterSwitch>
            <KeepaliveRoute
                key={'/error'}
                exact
                // path={route.url.toLowerCase()}
                path={'/error'}
                render={() => {
                    return <AsyncPage url={'/error/index'} />
                }}
            />
            {
                routes.data.map(route => {
                    loadMock(route.url);// 加载mock数据
                    let path = route.url.toLowerCase()
                    let url = route.url.toLowerCase()
                    if (url.indexOf('/reportmgr/report/') !== -1) {
                        path = '/reportmgr/report/:name'
                        url = '/reportmgr/report/index'
                    }
                    return (
                        <KeepaliveRoute
                            key={route.url.toLowerCase()}
                            exact
                            // path={route.url.toLowerCase()}
                            path={path}
                            render={() => {
                                document.title = sysName ? `${sysName}-${route.title}` : route.title
                                return <ErrorBoundary>
                                    <IntlProvider messages={messageData} locale={provider.locale.l} onError={() => { }}>
                                        <AsyncPage pageTitle={route.title} pageName={route.name} url={url} path={path} routes={routes} />
                                    </IntlProvider>
                                </ErrorBoundary>
                            }}
                        />
                    )
                })
            }
        </KeepaliveRouterSwitch>
        <Switch>
            {
                //一个一级路由，用于后续子路由匹配 /productmgr/*,组件为/productmgr/route，route.jsx自行定义子路由
                rootPaths.map(route => {
                    return (
                        <Route
                            key={route}
                            path={route}
                            render={() => {
                                if (route === '/error') {
                                    return <AsyncPage url={route + '/index'} />
                                } else {
                                    return <ErrorBoundary>
                                        <IntlProvider messages={messageData} locale={provider.locale.l} onError={() => { }}>
                                            <AsyncPage url={route + '/route'} />
                                        </IntlProvider>
                                    </ErrorBoundary>
                                }
                            }}
                        />
                    )
                })
            }
        </Switch>
        {
            !!homeurl && isShowHome() && <Route
                exact
                key={homeurl}
                path="/"
                render={() => {
                    const Home = HomePage(homeurl)
                    return <KeepaliveRouterSwitch withoutRoute>
                        <Home pageTitle={getUrl()} pageName={getPageName()}></Home>
                    </KeepaliveRouterSwitch>
                }}
            />
        }
    </>
    )
}