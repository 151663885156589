import axios from 'axios'
import { notification } from 'tea-component'
import { GetToken, RemoveSysInfo } from '@/assets/js/storage'
import { readBlobToJson } from '@/utils/index'
// 对外提供的服务地址
export const BaseURL =
    process.env.NODE_ENV === 'development' ? '/web' : '/'

export const SYS_TOKEN = 'sys_token'
export const instance = axios.create({
    timeout: 20000, // 超时时间
    baseURL: BaseURL
})

instance.defaults.headers.post['Content-Type'] = 'application/json'
if (GetToken()) {
    instance.defaults.headers.Authorization = GetToken() ? 'Bearer ' + GetToken() : ''
}

const httpCode = {
    400: '请求参数错误',
    401: '权限不足, 请重新登录',
    403: '服务器拒绝本次访问',
    404: '请求资源未找到',
    500: '内部服务器错误',
    501: '服务器不支持该请求中使用的方法',
    502: '网关错误',
    504: '网关超时'
}

const loginUrl = '/sysmgr/user/login'
instance.interceptors.request.use(config => {
    // 如果token为空且当前不是登录页，则跳转到登录页面
    const allowAnonymousArray = ['/sysmgr/user/login', '/sysmgr/user/resetPassWords', '/sysmgr/sn']

    if (!config.anonymous &&
        (!config.headers.Authorization || config.headers.Authorization === '') &&
        !allowAnonymousArray.some(x => window.location.pathname.startsWith(x)) && window.location.search.indexOf('token') < 0) {
        goToLogin()
    }
    return config
}, error => {
    return Promise.reject(error)
})

/** 响应拦截器  **/
instance.interceptors.response.use(response => {
    let resData = response.data
    if (resData.code === '401.1' || resData.code === '501') {
        goToLogin()
        return
    }
    if (response.status === 200) {
        // 如果是下载或导出文件，赋值文件名
        if (response.config.responseType === 'blob') {
            let fileName = ''
            if (response.headers['content-disposition']) {
                const keyArray = response.headers['content-disposition'].split(';')
                keyArray.forEach(key => {
                    const keyValueArray = key.split('=')
                    if (keyValueArray.length === 2 && keyValueArray[0].trim() === 'fileName') {
                        let name = keyValueArray[1]
                        if (name.indexOf('UTF-8') >= 0) { name = name.substring(7, name.length) }
                        fileName = decodeURIComponent(name)
                    }
                })
            }
            resData = { blob: resData, fileName: fileName }
        }
        return Promise.resolve(resData)
    } else {
        notification.error({ description: '响应超时' })
        return Promise.reject(response.data.notification)
    }
}, error => responseErrorInterceptors(error))
export const responseErrorInterceptors = error => {
    if (error.message === 'interrupt') {
        return
    }
    if (error.message === 'cancel') {
        return Promise.reject(error)
    }
    if (error.response) {
        let responseCode = error.response.data.code
        // 如果返回类型是blob,转成json去获取返回信息
        if (error.response.config.responseType === 'blob') {
            return readBlobToJson(error.response.data).then(jsonResult => {
                if (jsonResult) { responseCode = jsonResult.code }
                return handleErrorResponse(responseCode, error)
            })
        }
        return handleErrorResponse(responseCode, error)
    }
    return Promise.reject(error)
}

const handleErrorResponse = (responseCode, error) => {
    if (responseCode === '401.1' || responseCode === '501') {
        goToLogin()
        return
    }
    // 根据请求失败的http状态码去给用户相应的提示
    const tips = error.response.status in httpCode ? httpCode[error.response.status] : error.response.data.message
    notification.error({ description: tips })
}

const goToLogin = () => {
    RemoveSysInfo()
    window.location.href = loginUrl
}

/* 统一封装get请求 */
export const get = (url, params, config = {}) => {
    return new Promise((resolve, reject) => {
        instance({
            method: 'get',
            url,
            params,
            ...config
        }).then(response => {
            resolve(response)
        }).catch(error => {
            reject(error)
        })
    })
}

/* 统一封装post请求  */
export const post = (url, data, config = {}) => {
    const isUploadFile = data instanceof FormData
    const isDownloadFile = config?.responseType === 'blob'
    return new Promise((resolve, reject) => {
        let ajaxConfig = {
            method: 'post',
            url,
            data
        }
        ajaxConfig = (isUploadFile || isDownloadFile) ? { ...ajaxConfig, timeout: 2 * 60 * 1000, ...config } : { ...ajaxConfig, ...config }
        instance(ajaxConfig).then(response => {
            resolve(response)
        }).catch(error => {
            reject(error)
        })
    })
}

/* 匿名post请求  */
export const postWithAnonymous = (url, data, config = {}) => {
    return post(url, data, {
        anonymous: true,
        ...config
    })
}
