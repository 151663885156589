import React, { useCallback } from 'react'
import NSelect from '@/components/Select/NSelect.jsx'
import { NSPrintcorpList } from '@/axios/api'

const { useRef, forwardRef, useImperativeHandle } = React
function TranspondSelect ({
  field,
  onChange,
  value,
  defaultValue,
  disabled = false,
  placeholder = '请选择',
  params = { },
  selected
}, ref) {
  const nSelectRef = useRef(null)

  useImperativeHandle(ref, () => ({
    setParams: p => {
      nSelectRef.current?.setParams(p)
    }
  }))

  const setOptions = useCallback(
    data => {
      return (data || []).map(it => ({
        ...it,
        text: `${it.name}`,
        value: it.id
      }))
    },
    []
  )

  return (
    <NSelect
      ref={nSelectRef}
      action={NSPrintcorpList}
      onChange={onChange}
      value={value}
      defaultValue={defaultValue}
      disabled={disabled}
      placeholder={placeholder}
      params={params}
      searchKey='name' // 指定查询条件字段
      selected={selected}
      setOptions={setOptions}
    />
  )
}

export default forwardRef(TranspondSelect)
