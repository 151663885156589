import React from 'react'
import styled from 'styled-components'
import LedBoard from '../../components/LedBoard/LedBoard'
import GlobalTopDataItem from './components/GlobalTopDataItem'
import numberWrap from '../../assets/images/numberwrap.png'

const GlobalTopDataWrap = styled.div`
  background-image: url(${numberWrap});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
`

const GlobalTopDataItemHeader = styled.div`
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #FEFEFE;
`
type TypeGlobalTopDataProps = {
  data: {
    diffPlaceScanNum: number,
    allScanNum: number
  }
}
const GlobalTopData: React.FC<TypeGlobalTopDataProps> = (props) => {
    const { data } = props
    return <GlobalTopDataWrap>
      <GlobalTopDataItem>
        <GlobalTopDataItemHeader>
          全国扫码总量
        </GlobalTopDataItemHeader>
        <LedBoard number={data.allScanNum}></LedBoard>
      </GlobalTopDataItem>
      <GlobalTopDataItem>
        <GlobalTopDataItemHeader>
          异地扫码总量
        </GlobalTopDataItemHeader>
        <LedBoard number={data.diffPlaceScanNum}></LedBoard>
      </GlobalTopDataItem>
    </GlobalTopDataWrap>
}
export default GlobalTopData
