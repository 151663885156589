import React, { useRef, useEffect, useCallback, useState } from 'react'
import { init, graphic } from 'echarts'

// const lineData = [
//   [80, 90, 120, 100, 60, 90, 110, 40, 20, 100, 40, 80, 90, 120, 100, 60, 90, 110, 40, 20, 100, 40, 76, 90],
//   [-4, -4, 41, -3, 19, -60, 7, -20, 5, 28, 70, -4, -4, 41, -3, 19, -60, 7, -20, 5, 28, 70, 20, 11],
//   [-55, -7, 4, -7, 11, 150, -3, 20, 66, 54, -90, -55, -7, 4, -7, 11, 150, -3, 20, 66, 54, -90, 100, 90],
//   [80, -30, 100, -0, 30, -60, 24, 90, 1, 76, 80, 10, 50, 80, -30, 100, -0, 30, -60, 24, 90, 1, 76, 80],
//   [-100, -80, -40, -70, -19, -60, -7, -20, -88, -49, -90, 80, 10, -100, -80, -40, -70, -19, -60, -7, -20, -88, -49, -90, 80, 10]
// ]
const dateTime = ['00:00', '1:00', '2:00', '3:00', '4:00', '5:00', '6:00', '7:00', '8:00', '9:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00']
export default function Chart (props: any) {
    const { loading, data } = props
    const backgroundColor = 'rgba(82,82,82,0.4)'
    const [lineData, setLineData] = useState<string[][]>([])
    useEffect(() => {
      const arr: any = [[], [], [], [], []]
      dateTime?.forEach(it => {
        const obj = data?.find((e: any) => e.collectTime === it)
        arr[0].push(((obj?.collectBottleRate || 0) * 100).toFixed(2))
        arr[1].push(((obj?.collectBoxRate || 0) * 100).toFixed(2))
        arr[2].push(((obj?.collectBottleBoxRate || 0) * 100).toFixed(2))
        arr[3].push(((obj?.collectBoxChopRate || 0) * 100).toFixed(2))
        arr[4].push(((obj?.collectComprehensiveRate || 0) * 100).toFixed(2))
      })
      setLineData(arr)
    }, [data])

    const chartRef = useRef<any>()
    const myChart = useRef<any>()

    useEffect(() => {
        if (myChart.current) {
            if (loading) {
                myChart.current.showLoading({
                    text: '加载中...',
                    color: '#0061ff',
                    spinnerRadius: 7,
                    lineWidth: 2
                })
            } else {
                myChart.current.hideLoading()
            }
        }
    }, [loading])

    const drawChart = useCallback(() => {
        if (myChart.current !== null && myChart.current !== '' && myChart.current !== undefined) {
            myChart.current.dispose()// 销毁
        }

        myChart.current = init(chartRef.current)

        myChart.current.setOption({
                tooltip: {
                  backgroundColor,
                  trigger: 'axis',
                  textStyle: {
                    align: 'left',
                    color: '#fff',
                    fontSize: 14
                  },
                  borderColor: backgroundColor
                },
                legend: {
                  // 字体对应折线标识
                  data: ['瓶采集率', '箱码采集率', '瓶箱关联率', '箱托关联率', '综合关联率'],
                  right: '1%',
                  top: '5%',
                  // orient: 'vertical', // 竖排显示图例(目前图例太多)
                  textStyle: {
                    color: '#fff',
                    fontSize: '16px'
                  },
                  borderColor: backgroundColor,
                  itemHeight: 6, // 圆心
                  itemWidth: 0, // 折线
                  itemGap: 16 // 间隔
                },
                // 折线图位置
                grid: {
                  left: '1%',
                  right: '1%',
                  bottom: '1%',
                  top: '18%',
                  containLabel: true // 刻度标签
                },
                xAxis: {
                  boundaryGap: false,
                  axisLabel: {
                    fontSize: '12px', // 文字的字体大小
                    color: '#fff'
                  },
                  axisTick: {
                    // x轴刻度
                    show: false
                  },
                  axisLine: {
                    // x轴线样式
                    show: false,
                    lineStyle: {
                      color: '#ccc',
                      type: [0, 10],
                      dashOffset: 5
                    }
                  },
                  // 列出24小时的时间
                  data: dateTime
                  // data: ['00:00', '2:00', '6:00', '8:00', '10:00', '12:00', '14:00', '16:00', '18:00', '20:00', '22:00']
                },
                yAxis: {
                  type: 'value',
                //   min: -100,
                //   max: 200,
                  splitLine: {
                    // 分割线
                    lineStyle: {
                      color: 'rgba(255,255,255,0.2)',
                      type: [10, 10],
                      dashOffset: 5
                    }
                  },
                  axisLabel: {
                    color: '#fff',
                    fontSize: '12px'
                    // formatter: '{value}%' // y轴显示%
                  }
                },
                series: [
                  {
                    name: '瓶采集率',
                    type: 'line',
                    smooth: true, // smooth：true 为曲线
                    data: lineData[0],
                    color: '#0059FF',
                    symbolSize: 6, // 设定实心点的大小
                    label: {
                      show: false, // 在折线拐点上显示数据
                      fontSize: 14,
                      color: '#fff',
                      fontWeight: 10
                    },
                    areaStyle: {
                      origin: 'start',
                      color: new graphic.LinearGradient(0, 0, 0, 1, [
                        {
                          offset: 0,
                          color: 'rgba(0, 89, 255, 0.3)'
                        },
                        {
                          offset: 1,
                          color: 'rgba(0, 89, 255, 0)'
                        }
                      ])
                    }
                  },
                  {
                    name: '箱码采集率',
                    type: 'line',
                    smooth: true, // smooth：true 为曲线
                    data: lineData[1],
                    color: '#00BDFF',
                    symbolSize: 6,
                    label: {
                      show: false,
                      fontSize: 14,
                      color: '#fff',
                      fontWeight: 10
                    },
                    areaStyle: {
                      origin: 'start',
                      color: new graphic.LinearGradient(0, 0, 0, 1, [
                        {
                          offset: 0,
                          color: 'rgba(0, 189, 255, 0.3)'
                        },
                        {
                          offset: 1,
                          color: 'rgba(0, 189, 255, 0)'
                        }
                      ])
                    }
                  },
                  {
                    name: '瓶箱关联率',
                    type: 'line',
                    smooth: true, // smooth：true 为曲线
                    data: lineData[2],
                    color: '#FF9F2F',
                    symbolSize: 6,
                    label: {
                      show: false,
                      fontSize: 14,
                      color: '#fff',
                      fontWeight: 10
                    },
                    areaStyle: {
                      origin: 'start',
                      color: new graphic.LinearGradient(0, 0, 0, 1, [
                        {
                            offset: 0,
                            color: 'rgba(255,159,47, 0.3)'
                          },
                          {
                            offset: 1,
                            color: 'rgba(255,159,47, 0)'
                          }
                      ])
                    }
                  },
                  {
                    name: '箱托关联率',
                    type: 'line',
                    smooth: true, // smooth：true 为曲线
                    data: lineData[3],
                    color: '#FC41A8',
                    symbolSize: 6,
                    label: {
                      show: false,
                      fontSize: 14,
                      color: '#fff',
                      fontWeight: 10
                    },
                    areaStyle: {
                      origin: 'start',
                      color: new graphic.LinearGradient(0, 0, 0, 1, [
                        {
                            offset: 0,
                            color: 'rgba(252, 65, 168, 0.3)'
                          },
                          {
                            offset: 1,
                            color: 'rgba(252, 65, 168, 0)'
                          }
                      ])
                    }
                  },
                  {
                    name: '综合关联率',
                    type: 'line',
                    smooth: true, // smooth：true 为曲线
                    data: lineData[4],
                    color: '#A92DEE',
                    symbolSize: 6,
                    label: {
                      show: false,
                      fontSize: 14,
                      color: '#fff',
                      fontWeight: 10
                    },
                    areaStyle: {
                      origin: 'start',
                      color: new graphic.LinearGradient(0, 0, 0, 1, [
                        {
                          offset: 0,
                          color: 'rgba(169, 45, 238, 0.3)'
                        },
                        {
                          offset: 1,
                          color: 'rgba(169, 45, 238, 0)'
                        }
                      ])
                    }
                  }
                ]
        })
    }, [lineData])

    function render () {
      myChart.current && myChart.current.resize()
    }

    useEffect(() => {
      drawChart()

      window.addEventListener('resize', render)
      return () => {
        window.removeEventListener('resize', render)
      }
  }, [data, drawChart])

    return (
      <div ref={chartRef} style={{ height: '100%' }} ></div>
    )
}
