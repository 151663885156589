import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import Ring from '../Ring'
import { NSFactoryRelationRate } from '../../api'
import { useFetch } from '@/hooks/useFetch'

const BottomDataWrap = styled.div`
  width: 100%;
  height: 150px;
  padding: 8px 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
`

const ringOption = [
  { title: '平均瓶采集率', testStyle: '#00A5FF', gradient: ['#374BFF', '#0c61a2', '#5CB7FB'] },
  { title: '平均箱码采集率', testStyle: '#FC8B85', gradient: ['#F7D438', '#a83a5b', '#FB7099'] },
  { title: '平均瓶箱关联率', testStyle: '#C14CFF', gradient: ['#7776FF', '#7c20aa', '#AE2CF1'] },
  { title: '平均箱托关联率', testStyle: '#18CDC6', gradient: ['#02A1F9', '#136fa1', '#29F19C'] },
  { title: '平均综合关联率', testStyle: '#18CDC6', gradient: ['#48DD2A', '#c09e31', '#FFDF7A'] }
]
const BottomData: React.FC<any> = ({ corpId }: any) => {
  const [relationRotes, setRelationRates] = useState({})

  const { data, loadData, setQuerys } = useFetch<NSFactoryRelationRate.Params, NSFactoryRelationRate.Result>(NSFactoryRelationRate.action, { corpId, dateRange: '' })

  const ringList = useCallback(() => {
    if (relationRotes === null) {
      return []
    }
    const value: number[] = Object.values(relationRotes)

    return ringOption.map((item, index) => ({
      ...item,
      id: `container${index}`,
      value: value[index] ? value[index] * 100 : 0
    }))
  }, [relationRotes])

  useEffect(() => {
    setQuerys({ corpId, dateRange: '' })
    loadData()
  }, [corpId, loadData, setQuerys])

  useEffect(() => {
    if (data) {
      setRelationRates(data)
    }
  }, [data])

  return <BottomDataWrap>
    {ringList().map((item) => (
      <Ring
        key={item.id}
        id={item.id}
        value={item.value}
        title={item.title}
        testStyle={item.testStyle}
        gradient={item.gradient}
      />
    ))}
  </BottomDataWrap>
}

export default BottomData
