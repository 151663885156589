import styled from 'styled-components'

// 设置表格下划线样式
export const Collection = styled.div`
  .rows {
    .row-item {
      border-bottom: 1px solid rgba(4, 192, 235, 0.54);
    }
  }
`

export const RingWrap = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    .progress-name {
      height: calc(100% - 130px);
      font-size: 16px;
      color: #A1CEFA;
      text-align: center;
    }
`
