import React, { useRef, useState, useEffect, forwardRef, Ref, useImperativeHandle } from 'react'
import { init, registerMap } from 'echarts'
type TypeChartProps = {
    option: Record<string, any>,
    areaCode?: string,
    areaName?: string,
    renderer?: any,
    notMerge?: any,
    lazyUpdate?: any
}
function getGeoData (code: string) {
  const localStorageMapGeo = localStorage.getItem(`area.${code}`)
  if (!localStorageMapGeo) {
    const url = `/geodata/${code}.json`
    return fetch(url).then(response => response.json()).then(res => {
      localStorage.setItem(`area.${code}`, JSON.stringify(res))
      return Promise.resolve(res)
    })
  } else {
    return Promise.resolve(JSON.parse(localStorageMapGeo))
  }
}

export const getSetName = (provinceCode: string | undefined, cityCode: string | undefined) => {
  return getGeoData(provinceCode ?? '100000').then(features => {
    return features.filter((feat: any) => Number(feat.properties.adcode) === Number(cityCode)).map((it: any) => it.properties.name)[0]
  })
}

function getMapData (areaCode: string | undefined) {
  return getGeoData(areaCode ?? '100000').then(features => {
    const feas = features.filter((feat: any) => feat.properties.name)
    return { type: 'FeatureCollection', features: feas }
  })
}
export default forwardRef((props: TypeChartProps, ref: Ref<any>) => {
    const { renderer, option, areaCode, areaName='china' } = props
    const [width] = useState<string>('100%')
    const [height] = useState<string>('100%')
    const elRef = useRef<any>(null)
    const chartRef = useRef<any>(null)
    const setOption = (_option: Record<string, any>) => {
        if (!chartRef.current) {
            return null
        }
        chartRef.current?.setOption?.(_option)
        return true
    }
    const initChart = (el: any) => {
        // renderer 用于配置渲染方式 可以是 svg 或者 canvas
        return new Promise<void>(resolve => {
            setTimeout(() => {
                chartRef.current = init(el, undefined, {
                    renderer: renderer || 'canvas'
                })
                resolve()
            }, 0)
        })
    }
    const showTip = () => {
      chartRef.current?.dispatchAction({
        type: 'showTip',
        seriesIndex: 0,
        dataIndex: 0
      })
    }
    useEffect(() => {
        if (elRef.current && option) {
          if (option.geo) {
            getMapData(areaCode).then(res => {
              registerMap(areaName, res as any)
              initChart(elRef.current).then(() => {
                const result = setOption(option)
                if (result) {
                  showTip()
                }
              })
            })
          } else {
            initChart(elRef.current).then(() => {
              setOption(option)
            })
          }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [elRef, option])
    useImperativeHandle(ref, () => ({
        getInstance: () => {
          return chartRef.current
        }
    }))
    return <div
        className='default-chart'
        ref={elRef}
        style={{ width, height }}
    />
})
