import { notification } from 'tea-component'
import './base64'

export const queryString = () => {
    const _queryString = {}
    if (window.location.search) {
        const _query = window.location.search?.split('?')[1] || ''
        const _vars = _query.split('&')
        _vars.forEach(v => {
            const _pair = v.split('=')
            // eslint-disable-next-line no-prototype-builtins
            if (!_queryString.hasOwnProperty(_pair[0])) {
                _queryString[_pair[0]] = decodeURIComponent(_pair[1])
            } else if (typeof _queryString[_pair[0]] === 'string') {
                const _arr = [_queryString[_pair[0]], decodeURIComponent(_pair[1])]
                _queryString[_pair[0]] = _arr
            } else {
                _queryString[_pair[0]].push(decodeURIComponent(_pair[1]))
            }
        })
    }
    return _queryString
}

export const domainWithHttp = () => {
    let httpLength = 7
    const url = window.location.href
    if (url.indexOf('https') >= 0) { httpLength = 8 }
    const firstBarLength = url.substring(httpLength).indexOf('/')
    if (firstBarLength === -1) { return url }
    return url.substring(0, httpLength + firstBarLength)
}

// 是否都是纯数字正则
export const reg_number = /^\d*$/
// 正整数正则
export const reg_number_int = /^[1-9]\d*$/
// 11位手机号正则
export const reg_phone = /^1\d{10}$/
// 邮箱正则
export const reg_email = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
// 不包含汉字正则
export const reg_noChinese = /^[^\u4e00-\u9fa5]+$/
// 纬度正则
export const reg_latitude = /^-?([1-8]?\d(\.\d+)?|90(\.0+)?)$/
// 经度正则
export const reg_longitude = /^-?((1[0-7]|[1-9])?\d(\.\d+)?|180(\.0+)?)$/
// 官网正则
export const reg_website = /^(?:(http|https|ftp):\/\/)?((?:[\w-]+\.)+[a-z0-9]+)((?:\/[^/?#]*)+)?(\?[^#]+)?(#.+)?$/i
// 只允许输入数字字母下划线
export const reg_number_group = /^\w+$/
// 金额无上限,包含两位小数,只允许输入数字,不可以为负数
export const reg_number_account_nolimit = /^(0\.\d?[1-9]|\+?[1-9]\d*)(\.\d{1,2})?$/
export const reg_number_account_nolimit0 = /^(0|0\.\d?[1-9]|\+?[1-9]\d*)(\.\d{1,2})?$/

export function getQueryParamsFields (options) {
    const target = {}
    options.forEach(it => {
        target[it.field] = it.defaultValue || ''
    })
    return target
}
// blob有可能是json格式的
// 如果是json格式，转换为json对象；否则返回null
export function readBlobToJson (blob) {
    return new Promise(function (resolve) {
        const reader = new FileReader()
        reader.onload = function () {
            try {
                // 先进行json转换，如果转换失败或者不是失败的json格式（有可能是下载的json文件），则代表返回的文件流，进行下载
                const jsonResult = JSON.parse(reader.result)
                resolve(jsonResult)
            } catch (err) {
                resolve(null)
            }
        }
        reader.readAsText(blob)
    })
}
// 下载文件
export function downloadFile (blobData, cb) {
    // 下载文件有两种情况
    // 1.下载成功，返回文件流
    // 2.下载失败,返回json错误信息
    const { blob, fileName } = blobData
    readBlobToJson(blob).then(jsonResult => {
        if (jsonResult && (!fileName.endsWith('.json') && !fileName.endsWith('.txt'))) {
            const message = jsonResult.message
            notification.error({ description: message })
            const fail = false
            cb && cb(fail)
        } else {
            const link = document.createElement('a')
            link.download = fileName
            link.style.display = 'none'
            link.href = URL.createObjectURL(blob)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
            const success = true
            cb && cb(success)
        }
    })
}
// 导入excel
export function importXlsx () {
    return new Promise((resolve, reject) => {
        const input = document.createElement('input')
        input.setAttribute('type', 'file')
        input.setAttribute('accept', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,application/json')
        input.style.display = 'none'
        document.body.appendChild(input)
        input.addEventListener('change', e => {
            if (e.target.value) {
                resolve(e.target.files[0])
            } else {
                reject(new Error(null))
            }
        })
        input.click()
        document.body.removeChild(input)
    })
}

export function formatQuerys (querys) {
    const temp = {}
    Object.keys(querys).forEach(key => {
        if (querys[key] || querys[key] === 0 || querys[key] === false) {
            temp[key] = querys[key]
        }
    })
    return temp
}
/**
 *
 * @param {React.FormEvent<HTMLFormElement>} event -<form> 标签 onSubmit事件返回参数(用于阻止表单默认提交事件)
 * @param {Function} handleSubmit - 固定值： handleSubmit(submit) 1. handleSubmit：const { handleSubmit } = useForm() 2.submit 用于接收接受提交参数
 * @param {Function} getValues - const { getValues } = useForm()
 * @param {Function} setValue - const { setValue } = useForm()
 */
export function submitTrim (event, handleSubmit, getValues, setValue) {
    const vals = getValues()
    for (const key in vals) {
        if (typeof vals[key] === 'string') {
            setValue(key, vals[key].trim())
        }
    }
    handleSubmit(event)
}
// 列表显示
export function formatRender (codeValue, nameValue) {
    if (codeValue && nameValue) {
        return `(${codeValue})${nameValue}`
    } else if (!codeValue && nameValue) {
        return `${nameValue}`
    } else if (!nameValue && codeValue) {
        return `(${codeValue}) + '' `
    } else {
        return ''
    }
}

export { getStatus, getErrorMessage } from './formHelper'

export function randomFloat () {
    // 生成 32 位随机值
    const crypto = window.crypto || window.msCrypto
    const fooArray = new Uint32Array(1)
    // 最大值是 2^32 –1
    const maxUint32 = 0xFFFFFFFF
    // 用最大可能的值来除
    return crypto.getRandomValues(fooArray)[0] / maxUint32
}

export function getUuid () {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = (randomFloat() * 16) | 0
        const v = (c === 'x') ? r : (r & 0x3) | 0x8
        return v.toString(16)
    })
}

export function getTextWidth (str) {
    let width = 0
    const html = document.createElement('span')
    html.innerText = str
    html.className = 'getTextWidth'
    document.querySelector('body')?.appendChild(html)
    width = document.querySelector('.getTextWidth')?.offsetWidth
    document.querySelector('.getTextWidth')?.remove()
    return width
}
