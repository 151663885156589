import React, { Component } from 'react'
import { Card, Status, Layout } from 'tea-component'
const { Content: LayoutContent } = Layout
export default class NotFound extends Component {
    render () {
        return (
            <LayoutContent>
                <LayoutContent.Body full={true}>
                    <Card style={{ height: '100%' }}>
                        <Status
                            size={'l'}
                            title="404"
                            description="页面不存在"
                        />
                    </Card>
                </LayoutContent.Body>
            </LayoutContent>
        )
    }
}
