import React from 'react'
import { Input } from 'tea-component'

const NInput: React.FC<any> = props => {
    const { field, value, ...rest } = props
    return <Input value={value || ''} {...rest}/>
}
export const TextArea: React.FC<any> = props => {
    const { field, value, ...rest } = props
    const { TextArea: NTextArea } = Input
    return <NTextArea value={value || ''} {...rest} showCount/>
}
export default NInput
