import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'
const DashboardLayoutWrap = styled.div<{ columns: string, rows: string }>`
  display: grid;
  grid-template-columns: ${props => props.columns};
  grid-template-rows: ${props => props.rows};
  color: #ffffff;
  font-size: 36px;
  row-gap: 35px;
  height: 100%;
`
type TypeDashboardLayoutProps = {
  columns?: number[],
  rows?: number[]
}
const DashboardLayout: React.FC<TypeDashboardLayoutProps & PropsWithChildren> = (props) => {
  const { columns=[2, 1], rows=[1, 1], children } = props
  return <DashboardLayoutWrap columns={columns.join('fr ') + 'fr'} rows={rows.join('fr ') + 'fr'}>
    { children }
  </DashboardLayoutWrap>
}
export default DashboardLayout
