import { useCallback, useEffect, useState, useRef } from 'react'
import { NSScreenFlowInData, NSScreenMain, NSScreenProvinceList } from './api'
import { getSetName } from './components/Chart'

type TypeLineData = {
  from: string,
  to: string,
  type: 'in' | 'out'
}
export function useMainData () {
  const CHINACODE = '0'
  const [mainData, setMainData] = useState<NSScreenMain.Result>({} as NSScreenMain.Result)
  const [lineDatas, setLineDatas] = useState<TypeLineData[]>([])
  const [ccData, setCcData] = useState<number[]>([])
  const [yAxis, setYAxis] = useState<string[]>([])
  const [provinceName, setProvinceName] = useState<string>('全国')
  const [originalProviceName, setOriginalProviceName] = useState<string>('全国')
  const provinceListRef = useRef<any>(null)
  const loopRef = useRef<number>(0)
  const intervalRef = useRef<any>(null)
  const [scanWarningData, setScanWarningData] = useState<NSScreenFlowInData.Result[]>([])
  const convertLineDatas = async (data: NSScreenMain.TypeProvinceFlowData[]) => {
    const result: TypeLineData[] = []
    if (data?.length) {
      const item = data?.[0]
      for (const p of item.outProvince ?? []) {
        const promiseList = [getSetName('100000_withoutsansha', p.provinceCode), getSetName('100000_withoutsansha', item.provinceCode)]
        await Promise.all(promiseList).then(promiseListRes => {
          if (promiseListRes[0] && promiseListRes[1]) {
            result.push({
              from: promiseListRes[0],
              to: promiseListRes[1],
              type: 'in'
            })
          }
        })
      }
      for (const o of item.inProvince ?? []) {
        const promiseList = [getSetName('100000_withoutsansha', item.provinceCode), getSetName('100000_withoutsansha', o.provinceCode)]
        await Promise.all(promiseList).then(promiseListRes => {
          if (promiseListRes[0] && promiseListRes[1]) {
            result.push({
              from: promiseListRes[0],
              to: promiseListRes[1],
              type: 'out'
            })
          }
        })
      }
    }
    setLineDatas(result)
  }
  const convertComprehensiveCollect = (data: NSScreenMain.TypeProvinceTopTen[]) => {
    setYAxis(data?.map(item => item.provinceName))
    setCcData(data?.map(item => item.num))
  }
  const getCurrentProvinceName = async (provinceCode?: string) => {
    console.log('provinceCode', provinceCode)
    return await getSetName('100000_withoutsansha', provinceCode) ?? ''
  }

  const init = useCallback((originalProvinceCode?: string, _originalProviceName?: string) => {
    const pList: Promise<any>[] = [
      NSScreenMain.action({
        currentPage: 1,
        itemsPerPage: 10,
        provinceCode: originalProvinceCode === CHINACODE ? undefined : originalProvinceCode
      }),
      NSScreenFlowInData.action({
        currentPage: 1,
        itemsPerPage: 10,
        provinceCode: originalProvinceCode === CHINACODE ? undefined : originalProvinceCode
      })
    ]
    Promise.all(pList).then(async (res: any[]) => {
      if (res[0].success === true) {
        const mainResult: NSScreenMain.Result = res[0].data
        setMainData(mainResult)
        /** 全国的时候不展示飞线图 */
        if (originalProvinceCode !== CHINACODE) {
          const proviceName = await getCurrentProvinceName(originalProvinceCode)
          setOriginalProviceName(_originalProviceName ?? '全国')
          setProvinceName(proviceName)
          await convertLineDatas(mainResult.provinceFlowData)
        } else {
          setProvinceName('全国')
          setOriginalProviceName('全国')
          setLineDatas([])
        }
        convertComprehensiveCollect(mainResult.provinceTopTen)
      }
      if (res[1].success === true) {
        const flowResult: NSScreenFlowInData.Result[] = res[1].data
        setScanWarningData(flowResult)
      }
    })
  }, [])

  const getProvinceList = () => {
    return NSScreenProvinceList.action({
      currentPage: 1,
      itemsPerPage: 35
    }).then((res: any) => {
      if (res.success === true) {
        const provinceList: NSScreenProvinceList.Result[] = res.data
        const mergeProviceList = [{ provinceCode: CHINACODE, provinceName: '全国' }, ...provinceList]
        provinceListRef.current = mergeProviceList
        return mergeProviceList
      } else {
        return []
      }
    })
  }

  const mountedLoad = useCallback(() => {
    getProvinceList().then((list: any) => {
      if (list) {
        init(list?.[loopRef.current]?.provinceCode, list?.[loopRef.current]?.provinceName)
        loopRef.current = 1
        if (intervalRef.current) {
          clearInterval(intervalRef.current)
        }
        intervalRef.current = setInterval(() => {
          if (loopRef.current > list.length - 1) {
            loopRef.current = 0
            mountedLoad()
          } else {
            init(list?.[loopRef.current]?.provinceCode, list?.[loopRef.current]?.provinceName)
            loopRef.current = loopRef.current + 1
          }
        }, 10 * 1000)
      }
    })
  }, [init])

  useEffect(() => {
    mountedLoad()
    return () => {
      intervalRef.current && clearInterval(intervalRef.current)
    }
  }, [mountedLoad])
  return {
    mainData,
    lineDatas,
    scanWarningData,
    originalProviceName,
    comprehensiveCollectData: ccData,
    comprehensiveCollectYAxis: yAxis,
    inFlowRangkingData: mainData.productTopTen,
    currentProvinceName: provinceName
  }
}
