import React, { useMemo } from 'react'
import AutoTable from '../../../tracedashboard/components/AutoTable'
import { TypeColumn } from '@/types'
type TypeUserSituationProps = {
    data: any[],
}
const WaringMsgCom: React.FC<TypeUserSituationProps> = (props) => {
    const { data } = props
    const columns = useMemo<TypeColumn[]>(() => {
        return [{
            key: 'corpName',
            header: '工厂',
            width: 100,
            headerAlign: 'center',
            align: 'left'
        }, {
            key: 'lineName',
            header: '产线',
            width: 100,
            align: 'center',
            headerAlign: 'center'
        }, {
            key: 'errorMsg',
            header: '预警',
            width: 400,
            headerAlign: 'center',
            align: 'center'
        }, {
            key: 'warningTime',
            header: '预警时间',
            width: 100,
            headerAlign: 'center',
            align: 'center'
        }]
    }, [])
    return <AutoTable columns={columns} data={data} pageSize={8} height={280}/>
}
export default WaringMsgCom
