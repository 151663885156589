import React from 'react'
import styled from 'styled-components'
import TopData from './TopData'
import BottomData from './BottomData'
const GlobalWrap = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;

`
type PropsType = {
  corpId: number
}
const GlobalData: React.FC<any> = ({ corpId }: PropsType) => {
  return <GlobalWrap>
    <TopData corpId={corpId}></TopData>
    <BottomData corpId={corpId}></BottomData>
  </GlobalWrap>
}

export default GlobalData
