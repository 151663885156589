import React, { useCallback, useEffect, useState } from 'react'
import {
    Form,
    Input,
    Button,
    Alert,
    List,
    Stepper,
    Text,
    H2,
    notification
} from 'tea-component'
import { Link } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import { downloadFile, queryString } from '@/utils'
import { $resendsCode, $downloadCodePackage, $findByToken } from '@/axios/api.js'

let timer = null
export default function CodePkgDownloadModal () {
    const { handleSubmit, control, formState, reset } = useForm({})
    // 根据token获取id
    const [id, setId] = useState(null)
    const findByToken = useCallback(() => {
        $findByToken({ token: queryString().token }).then(res => {
            if (res.success) {
                setId(res.data.id)
            } else {
                notification.error({ description: res.message })
            }
        })
    }, [])
    useEffect(() => {
        if (queryString().token) {
            findByToken()
        }
    }, [reset, id, findByToken])

    // 发送验证码倒计时
    const [send, setSend] = useState(true)
    const [time, setTime] = useState(60)
    const handelResend = () => {
        sendCode()
        setSend(true)
        let times = time
        timer = setInterval(() => {
          if (times < 1) {
            clearInterval(Number(timer))
            setTime(60)
            setSend(false)
          } else {
            times -= 1
            setTime(times)
          }
        }, 1000)
    }
    const sendCode = async () => {
        const { success, message } = await $resendsCode({ id })
        if (success) {
            notification.success({ description: '发送成功' })
        } else {
            notification.error({ description: message })
        }
    }
    useEffect(() => {
        reset()
        setSend(false)

        // 清除计时器
        return () => {
            clearInterval(Number(timer))
        }
    }, [reset, id])

    // #region
    // 下载码包
    const [randomCode, setRandomCode] = useState('')
    const handelUpload = async () => {
        const res = await $downloadCodePackage({ codeRequestId: id, randomCode: randomCode })
        downloadFile(res)
    }
    // #endregion

    const steps = [
        {
            id: 'prepare',
            label: '请输入验证码',
            detail: (
                <>
                    <form onSubmit={handleSubmit()}>
                        <Controller
                            name="randomCode"
                            control={control}
                            render={({ field }) => {
                                return (
                                    <Form.Item
                                        extra="验证码将发送申请配置中的码包接收人"
                                    >
                                        <Input
                                        {...field}
                                        placeholder="请输入6位验证码"
                                        maxLength={6}
                                        onChange={(v) => {
                                            field.onChange(v)
                                            setRandomCode(v)
                                            }}
                                        />
                                        <Button type="weak" htmlType="button" onClick={handelResend} style={{ marginLeft: '10px', width: '100px' }} disabled={send}>{send ? `${time}s` : '发送验证码'}</Button>
                                    </Form.Item>
                                )
                            }}
                        />
                    </form>
                    <Alert style={{ marginTop: '10px' }}>
                        <h4 style={{ marginBottom: 8 }}>没有收到验证码怎么办？</h4>
                        <p>邮件方式</p>
                        <List type="bullet">
                            <List.Item>请检查个人信息中邮箱地址正确，然后点击。<Button type="link" htmlType="button" onClick={handelResend}>重新发送</Button> </List.Item>
                            <List.Item>
                                请检查垃圾箱。
                            </List.Item>
                        </List>
                    </Alert>
                </>
            )
        },
        {
            id: 'upload',
            label: '点击下载追溯码',
            detail: (
                <>
                    <Button type="primary" onClick={handelUpload} disabled={!randomCode} loading={formState.isSubmitting} style={{ width: '100%', marginTop: '10px' }} >下载追溯码</Button>
                </>
            )
        }
    ]

    return (
        <>
            <div style={{ width: '100%', height: '100%', backgroundColor: '#F3F4F7' }}>
                <div style={{ width: '700px', height: '100%', position: 'absolute', backgroundColor: '#fff', left: '50%', transform: 'translate(-50%,0)' }}>
                    <Text parent="div" align="center" style={{ marginTop: '30px' }}>
                        <H2>下载追溯码</H2>
                    </Text>
                    <Form.Action></Form.Action>
                    <Stepper type="process-vertical" steps={steps} style={{ padding: '30px' }} />
                    <Link to='/'>
                        <Button type="primary" style={{ width: '20%', marginLeft: '40%' }}>
                            跳转到首页
                        </Button>
                    </Link>

                </div>
            </div>

        </>
    )
}
