import React from 'react'
import styled from 'styled-components'
const TopRightDataWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`
const TopRightDataTitle = styled.div`
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #D5E4FA;
  opacity: 0.9;
`
const TopRightDataContent = styled.div`
  display: flex;
  align-items: flex-end;
`
const TopRightDataNumber = styled.div`
  font-size: 32px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #C7D5F0;
`
const TopRightDataUnit = styled.div`
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #DCDCDC;
  opacity: 0.8;
  
`
const TopRightData: React.FC<any> = ({ title, number }) => {
  return <TopRightDataWrap>
    <TopRightDataTitle>{title}</TopRightDataTitle>
    <TopRightDataContent>
      <TopRightDataNumber>{number}</TopRightDataNumber>
      <TopRightDataUnit>条</TopRightDataUnit>
    </TopRightDataContent>
  </TopRightDataWrap>
}

export default TopRightData
