import React, { useReducer, useMemo } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
// import H5TempalteIndex from '@/pages/h5mgr/template/index'
import h5DesignMsgView from '@/pages/h5mgr/h5DesignMsgView/index'
import CodeScan from '@/pages/custom/qingpi/scancode/index.tsx'

import h5WarningMsgView from '@/pages/custom/qingpi/h5warningmsg/index'
import h5WarningMsgDetaiView from '@/pages/custom/qingpi/h5warningmsg/detail'
import TranspondDownloadCode from '@/pages/custom/qingpi/downloadCode'
import EnvironmentError from '@/pages/h5mgr/EnvironmentError'
import SNIndex from '@/pages/sysmgr/sn/index'
import MicroApp from '@micro-zoe/micro-app'
import DownloadCode from './components/DownloadCode'
import App from './App'
import Login from './components/Login'
import ResetPassWords from './components/Login/ResetPassWords'
import NotFound from './components/ErrorPage/NotFound'
import NoPermission from './components/ErrorPage/NoPermission'
import ThirdLogin from './components/ThirdLogin'
import DashBoard from './pages/dashboard'
import NaryTradeIn from './components/NaryTradeIn'

// 国际化
import { IntlProvider } from 'react-intl'
import { ConfigProvider } from 'tea-component'
import en_US from 'tea-component/lib/i18n/locale/en_US'
import zh_CN from 'tea-component/lib/i18n/locale/zh_CN'
import enMessage from '@/i18n/en-US'
import zhMessage from '@/i18n/zh-CN'
import Context from '@/context'
import { useSafeState } from 'ahooks'

const initialLocale = () => {
    const _locale = localStorage.getItem('locale')
    return _locale
        ? JSON.parse(_locale)
        : {
            messages: {},
            language: 'zh-CN',
            l: 'zh',
            tea: zh_CN
        }
}

function reducer (_, action) {
    let _locale
    switch (action) {
        case 'zh-CN':
            _locale = {
                messages: zhMessage,
                language: action,
                l: 'zh',
                tea: zh_CN
            }
            break
        case 'en-US':
            _locale = {
                messages: enMessage,
                language: action,
                l: 'en',
                tea: en_US
            }
            break
        default: break
    }
    localStorage.setItem('locale', JSON.stringify(_locale))
    return _locale
}

MicroApp.start({
    lifeCycles: {
        created (e) {
          console.log('created')
        },
        beforemount (e) {
          console.log('beforemount')
        },
        mounted (e) {
          console.log('mounted')
        },
        unmount (e) {
          console.log('unmount')
        },
        error (e) {
          console.log('error')
        }
      }
})
export default function Page () {
    const [locale, localeDispatch] = useReducer(reducer, initialLocale())
    const [tableColumns, setTableColumns] = useSafeState([])
    const provider = useMemo(() => {
        return { locale, localeDispatch, tableColumns, setTableColumns }
    }, [locale, setTableColumns, tableColumns])
    return (
        <Context.Provider value={provider}>
            <ConfigProvider locale={locale.tea}>
                <IntlProvider messages={locale.messages} locale={locale.l} onError={() => { }}>
                    <BrowserRouter>
                        <Switch>
                            {/* H5 明码或暗码查询 c:明码，m:暗码 */}
                            <Route exact path="/t/:code" component={h5DesignMsgView} />
                            <Route exact path="/m/:code" component={h5DesignMsgView} />
                            <Route exact path="/c/:code" component={h5DesignMsgView} />
                            <Route exact path="/m/000/:code" component={h5DesignMsgView} />
                            <Route exact path="/M/000/:code" component={h5DesignMsgView} />
                            <Route exact path="/m/:aio/:code" component={EnvironmentError} />
                            <Route exact path="/M/:AIO/:code" component={EnvironmentError} />
                            <Route exact path="/qingpiscan/:code" component={CodeScan} />
                            <Route exact path="/custom/qingpi/h5warningmsgview" component={h5WarningMsgView} />
                            <Route exact path="/custom/qingpi/h5warningmsgdetailview/:id/:amount" component={h5WarningMsgDetaiView} />
                            <Route exact path="/custom/qingpi/downloadcode" component={TranspondDownloadCode} />
                            <Route exact path="/sysmgr/user/login" component={Login} />
                            <Route exact path="/sysmgr/user/resetPassWords" component={ResetPassWords} />
                            <Route exact path="/sysmgr/sn" component={SNIndex} />
                            <Route exact path="/coderequestmgr/coderequest/download" component={DownloadCode} />
                            {/* N元换购页面 */}
                            <Route exact path="/custom/qingpi/coderequestmgr/coderequest/configurestrategy" component={NaryTradeIn} />
                            <Route path="/dashboard" component={DashBoard} />
                            <Route exact path="/401" component={NoPermission} />
                            <Route exact path="/thirdlogin" component={ThirdLogin} />
                            <Route path="/" component={App} />
                            <Route component={NotFound} />
                        </Switch>
                    </BrowserRouter>
                </IntlProvider>
            </ConfigProvider>
        </Context.Provider>
    )
}
