import React, { useMemo } from 'react'
import { Layout, Menu, StatusTip } from 'tea-component'

import LeftMenu from './menu.jsx'
import './index.css'
import defaultImage from '@/assets/images/icon/report.svg'
import { GetSysInfo } from '@/assets/js/storage'

const allImages = require.context('@/assets/images/icon/', true, /\.(svg)$/)
const { Sider } = Layout
const { LoadingTip } = StatusTip
function Left ({ leftMenus, hide, loading }) {
    const getIconImage = (name, hover = false) => {
        if (hover) { name += '-hover' }
        const fileName = `./${name}.svg`

        const exist = allImages.keys().filter(it => it === fileName)
        if (exist.length > 0) {
            return allImages(fileName).default
        }
        return defaultImage
    }

    const sysName = useMemo(() => { return GetSysInfo()?.name || '' }, [])
    const loadLeftMenu = useMemo(() => {
        if (loading) {
            return <LoadingTip loadingText="菜单加载中..." hideIcon={true} style={{ color: '#c1c6c8', paddingLeft: '20px' }} />
        } else if (leftMenus.length) {
            return leftMenus.map((menuItem) => {
                return <LeftMenu key={menuItem.name}
                    icon={getIconImage(menuItem.name)}
                    iconHover={getIconImage(menuItem.name, true)}
                    menuItem={menuItem} />
            })
        } else {
            return null
        }
    }, [leftMenus, loading])
    const SiderDom = useMemo(() => {
        if (!hide) {
            return <Sider>
                <Menu
                    theme="dark"
                    title={sysName}
                    collapsable
                >
                    {
                        loadLeftMenu
                    }
                </Menu>
            </Sider>
        } else {
            return null
        }
    }, [hide, loadLeftMenu, sysName])
    return <>
        {
            SiderDom
        }
    </>
}

export default Left
