import React, { useCallback, useEffect, useState, useRef } from 'react'
import Chart from './Chart'
import { NSProductLineCurve } from '../../api'
import { randomFloat } from '@/utils'

import { ProductChart } from './style'

type TypeCenterPageProps = {
  corpId?: number | null,
  loopTime: number
}

const LineChart = ({ corpId, loopTime }: TypeCenterPageProps) => {
    const [bkgColor, setBkgColor] = useState<number | null>(0) // 背景色
    const [rateDTOList, setRateDTOList] = useState<any>()
    const [data, setData] = useState<any>()
    const pieceLineLoopRef = useRef<number>(0)
    const intervalRef = useRef<any>(null)
    const lineCountRef = useRef<number>(0)
    const handlePieceLine = useCallback((item: Record<string, any>) => {
      if (item?.collectionRateDTOList) {
        setRateDTOList(item?.collectionRateDTOList)
      } else {
        setRateDTOList([])
      }
    }, [])
    useEffect(() => {
      NSProductLineCurve.action({ corpId }).then(res => {
        const { data: _data } = res
        setBkgColor(0)
        setData(_data)
        pieceLineLoopRef.current = 0
        lineCountRef.current = _data.length
        if (intervalRef.current) {
          clearInterval(intervalRef.current)
        }
        handlePieceLine(_data[pieceLineLoopRef.current])
        intervalRef.current = setInterval(() => {
          if (pieceLineLoopRef.current > lineCountRef.current - 1) {
            pieceLineLoopRef.current = 0
          } else {
            pieceLineLoopRef.current = pieceLineLoopRef.current + 1
          }
          handlePieceLine(_data[pieceLineLoopRef.current])
          setBkgColor(pieceLineLoopRef.current)
        }, loopTime / lineCountRef.current)
      }).catch(e => {})
      return () => {
        if (intervalRef.current) {
          clearInterval(intervalRef.current)
        }
      }
    }, [corpId, handlePieceLine, loopTime])

    // 选择产线
    const handleSelectProLine = useCallback((ind: number, it: NSProductLineCurve.Result) => {
      const { collectionRateDTOList } = it
      setBkgColor(ind)
      setRateDTOList(collectionRateDTOList)
    }, [])

    return (
      <ProductChart id='product'>
        {
          data?.length > 0 && <div className='chart-title'>
            <div>
               {
                data?.map((it: NSProductLineCurve.Result, index: number) => {
                  return (
                    <span key={randomFloat()} onClick={() => handleSelectProLine(index, it)} style={{ background: bkgColor === index ? '#1DA1E4' : '' }}>{it.lineName}</span>
                  )
                })
               }
            </div>
          </div>
        }
        {rateDTOList && <Chart data={rateDTOList}/>}
      </ProductChart>
    )
}
export default LineChart
