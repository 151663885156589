import { post } from '@/axios'

// 用户名获取验证码
export function getVerificationcode (loginname) {
  return post(`web/sysmgr/user/verificationcode/send/${loginname}`)
}
// 用户名获取验证码
export function verifyCode (params, config) {
  return post('web/sysmgr/user/verificationcode/find', params, config)
}

// 重置密码
// 用户名获取验证码
export function resetPassApi (params, config) {
  return post('api/basedata/user/modify', params, config)
}
