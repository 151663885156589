import { post } from '@/axios'
// 99用户管理 获取用户列表
export function getUserList (params, config) {
    return post('api/basedata/user/listPage', params, config)
}
// 99用户管理 重置密码
export function resetPassword (params, config) {
    return post('api/basedata/user/reset', params, config)
}
// 根据ID获取用户信息
export function getUserByID (params, config) {
    return post('api/basedata/user/find', params, config)
}
// 新建或编辑用户
export function addOrUpdateUser (params, config) {
    return post('api/basedata/user/edit', params, config)
}
// 根据ID删除用户
export function deleteUser (params, config) {
    return post('api/basedata/user/delete', params, config)
}
// 恢复用户
export function restoreUser (params, config) {
    return post('api/basedata/user/updateEnable', params, config)
}
// 获取组织机构树
export function getUserCategoryTree (params, config) {
    return post('api/basedata/organization/list', params, config)
}
// 根据组织机构ID获取机构信息
export function getUserCategoryInfo (params, config) {
    return post('api/basedata/organization/find', params, config)
}
// 创建获取更新组织机构
export function createOrUpdateUserCategory (params, config) {
    return post('api/basedata/organization/edit', params, config)
}
// 删除组织机构
export function delUserCategory (params, config) {
    return post('api/basedata/organization/delete', params, config)
}

// {* 角色管理 *}
// 角色管理  删除
export function deleteRole (params, config) {
    return post('api/basedata/role/delete', params, config)
}
// 根据ID获取角色信息
export function getRoleByID (params, config) {
    return post('api/basedata/role/find', params, config)
}
// 获取角色列表
export function getRoleList (params, config) {
    return post('api/basedata/role/listPage', params, config)
}
// 获取角色下拉列表
export function getRoleDownList (params, config) {
    return post('api/basedata/role/dropdown/list', params, config)
}
// 新建、修改
export function addOrUpdateRole (params, config) {
    return post('api/basedata/role/edit', params, config)
}
// 菜单树
export function menuList (params, config) {
    return post('api/basedata/role/menu/list', params, config)
}
// 保存角色对应的菜单
export function saveMenuData (params, config) {
    return post('api/basedata/role/roleNavigation/insert', params, config)
}
// 导出角色(json文件)
export function downloadRolesInfo (params, config) {
    return post('web/sysmgr/role/export', params, {
        responseType: 'blob',
        ...config
    })
}
// 导入角色
export function importRole (params, config) {
    return post('/web/sysmgr/role/import', params, config)
}
// 导入角色(覆盖已存在角色)
export function importRolesToOverride (params, config) {
    return post('web/sysmgr/role/import/update', params, config)
}
// 根据角色ID获取用户列表
export function getRoleByIdUserList (params, config) {
    return post('api/basedata/role/users/get', params, config)
}
// 关联角色对应的用户
export function relativeRoleUser (params, config) {
    return post('api/basedata/role/insert', params, config)
}
// 批量移除用户
export function roleUserBatchDel (params, config) {
    return post('api/basedata/role/batchDelete', params, config)
}
// 移除用户
export function roleUserDel (params, config) {
    return post('web/sysmgr/role/roleuser/delete', params, config)
}
// 关联角色对应的用户
export function getNoRelativeUserList (params, config) {
    return post('api/basedata/role/userList/get', params, config)
}

// {* 登录日志 *}
// 获取列表
export function getLoginLogList (params, config) {
    return post('api/basedata/loginLog/listPage', params, config)
}

// {* 数据字典 *}
// 字典树
export function dictionaryTreeList (params, config) {
    return post('api/basedata/dictionaryitem/list', params, config)
}
// 根据name获取字典信息
export function getDictionaryByID (params, config) {
    return post('api/basedata/dictionaryitem/find/name', params, config)
}
// 新建数据字典
export function addDictionary (params, config) {
    return post('api/basedata/dictionaryitem/insert', params, config)
}
// 编辑数据字典
export function updateDictionary (params, config) {
    return post('api/basedata/dictionaryitem/update', params, config)
}
// 通过分组名称获取字典列表
export function getDictionaryList (params, config) {
    return post('api/basedata/dictionaryitem/list/group', params, config)
}

/**
 * 系统设置
 */
// 获取已使用的系统设置列表
export function getSettingList (params, config) {
    return post('api/basedata/systemsetting/used/list', params, config)
}

// 获取的系统设置列表
export function getNotUsedList (params, config) {
    return post('api/basedata/systemsetting/list', params, config)
}
// 根据名称获取系统设置
export function getNameList (params, config) {
    return post('api/basedata/systemsetting/find', params, config)
}
// 导出系统设置
export function exportSetting (params, config) {
    return post('web/sysmgr/systemsetting/export', params, {
        responseType: 'blob',
        ...config
    })
}
// 导入系统设置
export function importSetting (params, config) {
    return post('web/sysmgr/systemsetting/import', params, config)
}
// 新建、编辑
export function settingAddOrUpdate (params, config) {
    return post('api/basedata/systemsetting/edit', params, config)
}
// 删除系统设置
export function deleteEnterpriseLevelApi (params, config) {
    return post('api/basedata/systemsetting/delete', params, config)
}
// 删除系统设置
export function resetSettings (params, config) {
    return post('api/basedata/systemsetting/reset', params, config)
}

// 设置
// 获取密码设置
export function getPasswordSetting (params, config) {
    return post('api/basedata/passwordSetting/find', params, config)
}
// 编辑密码设置
export function editPasswordSetting (params, config) {
    return post('web/sysmgr/passwordsetting/edit', params, config)
}

// 获取SN信息
export function getSnInfo (params, config) {
    return post('web/sysmgr/user/sn/get', params, config)
}

// 获取个人信息
export function getPersonalInfo (params, config) {
    return post('api/basedata/user/getLoginInfo', params, config)
}
// 修改个人信息
export function editPersonalInfo (params, config) {
    return post('api/basedata/user/updatePersonal', params, config)
}
// 修改密码
export function editPassword (params, config) {
    return post('api/basedata/user/updatePassword', params, config)
}

// 自定义菜单
// 获取菜单树
export function getMenuList (params, config) {
    return post('api/basedata/navigationCustom/list', params, config)
}
// 重置自定义菜单
export function resetMenu (params, config) {
    return post('api/basedata/navigationCustom/reset', params, config)
}
// 保存自定义菜单
export function savetMenu (params, config) {
    return post('api/basedata/navigationCustom/insert', params, config)
}
// 根据名称获取菜单
export function getByNameMenu (params, config) {
    return post(`web/sysmgr/navigationcustom/get/${params.name}`, params, config)
}
// 修改菜单
export function updateMenu (params, config) {
    return post('api/basedata/navigationCustom/edit', params, config)
}
// 获取组织机构树

export function NSGetUserCategoryTree (params, config) {
    return post('api/basedata/organization/list', params, config)
}
// 根据组织机构ID获取机构信息
export function NSGetUserCategoryInfo (params, config) {
    return post('api/basedata/organization/find', params, config)
}
