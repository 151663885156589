import React, { useEffect, useState } from 'react'
import { useHistory, withRouter } from 'react-router-dom'
import { Modal, Button, notification, Form, Input } from 'tea-component'
import { GetSysInfo } from '@/assets/js/storage.js'
import { useForm, Controller } from 'react-hook-form'
import { postWithAnonymous, instance } from '@/axios'
import { SetUserInfo, SetToken } from '@/assets/js/storage'
import './index.css'
import { useIntl } from 'react-intl'
import TranslateButton from '@/components/i18n/TranslateButton'
import { AESEncryt } from '@/utils/crypto'
import { queryString } from '@/utils'
import VerifyCode from '@/components/VerifyCode'
import { randomString } from './utils'
function Login (props) {
    const { children, showReset, allowSso } = props // allowSso: 允许单点登录
    const history = useHistory()
    const intl = useIntl()
    const { control, formState, handleSubmit } = useForm()
    // input状态
    function getStatus (meta) {
        if (!meta.isDirty && !formState.isSubmitted) {
            return null
        }
        return meta.invalid ? 'error' : 'success'
    }

    const [urlPath, setUrlPath] = useState(null)
    const [urlToken, setUrlToken] = useState(null)
    useEffect(() => {
        if (queryString()) {
            setUrlPath(queryString().path ? queryString().path : urlPath)
            setUrlToken(queryString().token ? queryString().token : urlToken)
        }
    }, [urlPath, urlToken])

    // 登录
    const [loginloading, setLoginloading] = useState(false)
    const [reset, setReset] = useState(false) // 重置验证码
    const [randomStr, setRandomStr] = useState(randomString()) // 设置验证码随机数

    function onSubmit (values) {
        setLoginloading(true)
        values.password = AESEncryt(values.password)
        const verifyUrl = GetSysInfo().verifyCode ? `?randomStr=${randomStr}&code=${values.verifyCode}` : ''
        postWithAnonymous('api/basedata/user/login' + verifyUrl, { ...values, cultureName: '' }).then(async res => {
            if (res.success) {
                const info = res.data.userInfo
                if (!info.industryType) {
                    info.industryType = 'tts'
                }
                SetUserInfo(info)
                SetToken(res.data.token)
                instance.defaults.headers.Authorization = 'Bearer ' + res.data.token
                instance.defaults.headers.Code = info.corpId
                if (urlPath) {
                    history.push(`${urlPath}?token=${urlToken}`)
                } else {
                    history.push('/')
                }
            } else {
                setReset(true)
                setLoginloading(false)
                // 如果需要修改密码，则提示用户，进行密码修改；其他则提示错误消息
                if (res.code === 'NeedModifyPassError') {
                    if (res.data) {
                        SetUserInfo(res.data.userInfo)
                    }
                    const confirmResult = await Modal.confirm({
                        message: '密码修改提示',
                        description: res.message,
                        okText: '确定',
                        cancelText: '取消'
                    })
                    if (confirmResult) {
                        showReset()
                    }
                } else {
                    notification.error({
                        description: res.message
                    })
                }
            }
        }).catch(() => {
            notification.error({
                description: '请求失败'
            })
            setLoginloading(false)
        })
    }
    // 单点登录
    const toThirdLogin = () => {
        history.push('/thirdlogin')
    }
    return (
        <>
            <form style={{ width: 'form-material', margin: 'auto' }} id="loginForm" onSubmit={handleSubmit(onSubmit)}>
                {children}
                <Form>
                    <Controller
                        name="loginName"
                        control={control}
                        rules={{ required: '请输入账号' }}
                        render={({ field: { value, onChange }, fieldState }) => (
                            <Form.Item
                                className='loginForm'
                                required={true}
                                label={intl.formatMessage({ id: 'common_account', defaultMessage: '账号' })}
                                status={
                                    formState.isValidating ? 'validating' : getStatus(fieldState)
                                }
                                message={formState.errors.loginName?.message}
                                style={{ color: 'red' }}
                            >
                                <Input size="l" value={value} onChange={onChange} autoComplete="off" placeholder="请输入账号" className='pwdInput' />
                            </Form.Item>
                        )}
                    />
                    <Controller
                        name="password"
                        control={control}
                        rules={{ required: '请输入密码' }}
                        render={({ field: { value, onChange }, fieldState }) => (
                            <Form.Item
                                required={true}
                                label={intl.formatMessage({ id: 'common_password', defaultMessage: '密码' })}
                                className='myPass'
                                status={
                                    formState.isValidating ? 'validating' : getStatus(fieldState)
                                }
                                message={formState.errors.password?.message}
                            >
                                <Input.Password rules={false} size="full" value={value} onChange={onChange} autoComplete="new-password" placeholder="请输入密码" className='pwdInput' />
                            </Form.Item>
                        )}
                    />
                    {
                        GetSysInfo().verifyCode
                            ? <Controller
                                name="verifyCode"
                                control={control}
                                rules={{ required: '请输入验证码' }}
                                render={({ field: { value, onChange }, fieldState }) => (
                                    <Form.Item
                                        align='middle'
                                        required={true}
                                        label={intl.formatMessage({ id: 'common_verifyCode', defaultMessage: '验证码' })}

                                        status={
                                            formState.isValidating ? 'validating' : getStatus(fieldState)
                                        }
                                        message={formState.errors.verifyCode?.message}
                                    >
                                        <VerifyCode
                                            reset={reset}
                                            setReset={setReset}
                                            randomStr={randomStr}
                                            setRandomStr={setRandomStr}
                                            value={value}
                                            onChange={onChange}
                                        />
                                    </Form.Item>
                                )}
                            />
                            : null
                    }

                </Form>
                <Form.Action className='login-border'>
                    <Button type="primary" htmlType="submit" loading={loginloading} disabled={formState.isValidating} className={!allowSso ? 'loginBtn' : 'minLoginBtn'}>
                        {
                            intl.formatMessage({ id: 'common_login', defaultMessage: '登录' })
                        }
                    </Button>
                    {
                        allowSso ? <Button type="weak" htmlType='button' className='ssoLogin' onClick={toThirdLogin}>单点登录</Button> : null
                    }
                </Form.Action>
                <div className="form-group " style={{ marginTop: '10px', display: 'flex', justifyContent: 'space-between' }}>
                    {
                        GetSysInfo()?.multiLanguage && <TranslateButton></TranslateButton>
                    }
                </div>
            </form>
        </>
    )
}

export default withRouter(Login)
