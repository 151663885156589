import moment from 'moment'
export namespace NMoment {
    /** 格式化 YYYY-MM-DD  */
    export const formatDate = 'YYYY-MM-DD'
    /** 格式化 YYYY-MM-DD HH:mm:ss */
    export const formatDateTime = 'YYYY-MM-DD HH:mm:ss'

    /** 默认一个月 [Moment("YYYY-MM-DD 00:00:00"),Moment("YYYY-MM-DD 23:59:59")] */
    export const defaultMonthMomentRange: [moment.Moment, moment.Moment] = [moment(moment(new Date().setMonth(new Date().getMonth() - 1)).format(formatDate) + ' 00:00:00'), moment(moment(new Date()).format(formatDate) + ' 23:59:59')]
    /** 默认一个月 ["YYYY-MM-DD 00:00:00","YYYY-MM-DD 23:59:59"] */
    export const defaultMonthRange: [string, string] = [moment(new Date().setMonth(new Date().getMonth() - 1)).format(formatDate) + ' 00:00:00', moment(new Date()).format(formatDate) + ' 23:59:59']
    /** 默认一个月 'YYYY-MM-DD 00:00:00~YYYY-MM-DD 23:59:59' */
    export const defaultMonthRangeStr: string = defaultMonthRange.join('~')

    /** 默认一个月 [Moment("YYYY-MM-DD"),Moment("YYYY-MM-DD")] */
    export const defaultMonthMomentRangeNoSec: [moment.Moment, moment.Moment] = [moment(new Date().setMonth(new Date().getMonth() - 1)), moment(new Date())]
    /** 默认一个月 ['YYYY-MM-DD', 'YYYY-MM-DD'] */
    export const defaultMonthRangeNoSec: [string, string] = [moment(new Date().setMonth(new Date().getMonth() - 1)).format(formatDate), moment(new Date()).format(formatDate)]
    /** 默认一个月 'YYYY-MM-DD~YYYY-MM-DD' */
    export const defaultMonthRangeNoSecStr: string = defaultMonthRangeNoSec.join('~')
    /** 当天 [YYYY-MM-DD 00:00:00, YYYY-MM-DD 23:59:59] */
    export const defaultDayRange = [moment(new Date()).format(formatDate) + ' 00:00:00', moment(new Date()).format(formatDate) + ' 23:59:59']

    /** 默认三个月 [Moment("YYYY-MM-DD"),Moment("YYYY-MM-DD")] */
    export const defaultMonthMomentRangeNoSecMo: [moment.Moment, moment.Moment] = [moment(new Date().setMonth(new Date().getMonth() - 3)), moment(new Date())]
    /** 默认三个月 ['YYYY-MM-DD', 'YYYY-MM-DD'] */
    export const defaultMonthRangeNoSecMo: [string, string] = [moment(new Date().setMonth(new Date().getMonth() - 3)).format(formatDate), moment(new Date()).format(formatDate)]
    /** 默认三个月 'YYYY-MM-DD~YYYY-MM-DD' */
    export const defaultMonthRangeNoSecMoStr: string = defaultMonthRangeNoSecMo.join('~')

    /** 默认五天 [Moment("YYYY-MM-DD"),Moment("YYYY-MM-DD")] */
    export const defaultFiveDayRange: [moment.Moment, moment.Moment] = [moment(new Date().setDate(new Date().getDate() - 4)), moment(new Date())]
    /** 默认五天 ['YYYY-MM-DD', 'YYYY-MM-DD'] */
    export const defaultFiveDayRangeNoSec: [string, string] = [moment(new Date().setDate(new Date().getDate() - 4)).format(formatDate), moment(new Date()).format(formatDate)]
    /** 默认五天 */
    export const defaultFiveDayRangeStr: string = defaultFiveDayRangeNoSec.join('~')

    /**
     * 转换日期范围字符串
     * @param dateRange [Moment("yyyy-MM-DD"),Moment("yyyy-MM-DD")]
     * @param format 格式化 默认 YYYY-MM-DD
     * @returns 'YYYY-MM-DD~YYYY-MM-DD'
     */
    export const dateRangeStrToMoment = (dateRange: moment.Moment[], format = formatDate): string => {
        if (dateRange?.[0]) {
            return `${dateRange?.[0]?.format(format)}~${dateRange?.[1]?.format(format)}`
        } else {
            return ''
        }
    }
}
