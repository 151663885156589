import { useCallback, useEffect, useState } from 'react'
import { Cascader } from 'tea-component'

const NCascader = (props: any) => {
    const { field, loading = false, value, onChange, ...rest } = props
    const [_value, setValue] = useState<string[] | undefined>(undefined)
    const handleOnChange = useCallback((values: string[]) => {
        setValue(values)
        onChange(values)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        if (loading && rest?.data) {
            setTimeout(() => {
                setValue(value)
            }, 800)
        }
    }, [loading, setValue, value, rest?.data])
    return <Cascader value={_value} {...rest} onChange={handleOnChange} />
}
export default NCascader
