
import React, { useState, useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { Modal, Button, Form, Input, notification } from 'tea-component'
import { useForm, Controller } from 'react-hook-form'
import { useFetch } from '@/hooks'
import { post, instance } from '@/axios'
import { AESEncryt } from '@/utils/crypto'
import { getPasswordSetting } from '@/pages/sysmgr/api'
import { GetUserInfo, SetToken, SetUserInfo } from '@/assets/js/storage'

import { passwordRules } from '@/pages/sysmgr/passwordRules'

const { Password } = Input

export default function UpdatePwd (props) {
    const [passwordValid, setPasswordValid] = useState(false)
    const history = useHistory()
    const { control, formState, getValues, handleSubmit } = useForm()
    const [visible, setVisible] = useState(false)
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        setVisible(props.visible)
    }, [props.visible])

    const { data, loadData, token } = useFetch(getPasswordSetting)
    useEffect(() => {
        loadData()
        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            token.current?.cancel?.('cancel')
        }
    }, [loadData, token])

    const rules = useMemo(() => {
        if (data) {
            return passwordRules(data.length, data.reg)
        }
    }, [data])

    function getStatus (meta) {
        if (!meta.isDirty && !formState.isSubmitted) {
            return null
        }
        return meta.invalid ? 'error' : 'success'
    }

    function onSubmit (values) {
        setLoading(true)
        values = {
            ...values,
            id: GetUserInfo()?.id,
            oldPassword: AESEncryt(values.oldPassword),
            newPassword: AESEncryt(values.newPassword)
        }
        post('/api/basedata/user/modify', values).then(res => {
            if (res.success === true) {
                // 登录成功，存储数据
                SetToken(res.data.token)
                // SetRefreshToken(res.data.refreshToken)
                instance.defaults.headers.Authorization = 'Bearer ' + res.data.token
                SetUserInfo(res.data.userInfo)
                notification.success({
                    description: '登录成功'
                })
                // 关闭密码弹窗并跳转到首页
                setVisible(false)
                setLoading(false)
                props.changeVisible(false)
                history.push('/')
            } else {
                notification.error({
                    description: res.message
                })
                setLoading(false)
            }
        }).catch(() => {
            setLoading(false)
        })
    }
    const close = () => { setVisible(false); props.changeVisible(false) } // 关闭
    return (
        <>
            {/* 修改密码 */}
            <Modal visible={visible} caption="重置密码" onClose={close}>
                <Modal.Body>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Form>
                            <Controller
                                name="oldPassword"
                                control={control}
                                rules={{ required: '请输入旧密码' }}
                                render={({ field, fieldState }) => (
                                    <Form.Item
                                        required={true}
                                        label="旧密码"
                                        status={
                                            formState.isValidating ? 'validating' : getStatus(fieldState)
                                        }
                                        message={formState.errors.oldPassword?.message}
                                    >
                                        <Input type="password" size="l" {...field} autoComplete="off" placeholder="请输入旧密码"/>
                                    </Form.Item>
                                )}
                            />
                            <Controller
                                name="newPassword"
                                control={control}
                                rules={{
                                    required: '请输入新密码',
validate: {
                                        matchesPreviousPassword: () => passwordValid || '新密码不符合规则'
                                    }
                                }}
                                render={({ field: { value, onChange }, fieldState }) => (
                                    <Form.Item
                                        required={true}
                                        label="新密码"
                                        status={
                                            formState.isValidating ? 'validating' : getStatus(fieldState)
                                        }
                                        message={formState.errors.newPassword?.message}
                                    >
                                        <Password
                                            value={value}
                                            onChange={(onChangevalue, { valid }) => {
                                                onChange(onChangevalue)
                                                setPasswordValid(valid)
                                            }}
                                            rules={rules}
                                            autoComplete="off"
                                            placeholder="请输入新密码"
                                            size="full"
                                        />
                                    </Form.Item>
                                )}
                            />
                            <Controller
                                name="confirmNewPassword"
                                control={control}
                                rules={{
 required: '请再次输入新密码',
validate: {
                                    matchesPreviousPassword: value => {
                                      const { newPassword } = getValues()
                                      return newPassword === value || '密码不一致'
                                    }
                                }
}}
                                render={({ field, fieldState }) => (
                                    <Form.Item
                                        required={true}
                                        label="确认密码"
                                        status={
                                            formState.isValidating ? 'validating' : getStatus(fieldState)
                                        }
                                        message={formState.errors.confirmNewPassword?.message}
                                    >
                                        <Input type="password" size="l" {...field} autoComplete="off" placeholder="请再次输入新密码"/>
                                    </Form.Item>
                                )}
                            />
                        </Form>
                        <Form.Action>
                            <Button type="primary" htmlType="submit" loading={loading} disabled={formState.isValidating}>保存</Button>
                            <Button type="weak" htmlType="button" onClick={close}>取消</Button>
                        </Form.Action>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    )
}
