import styled from 'styled-components'

export const ProductChart = styled.div`
    .chart-title {
        border-radius: 5px;
        font-size: 16px;
        display: flex;
        justify-content: center;
        div {
            border: 2px solid #1DA1E4;
            line-height: 37px;
            border-radius: 3px;
            span {
                padding: 10px 16px;
                border-right: 2px solid #1DA1E4;
                cursor: pointer;
            }
            :last-child {
                border-right: none;
            }
        }
    }

`
