import './App.css'
import React, { Suspense, useMemo, useEffect, useState, useCallback, useContext } from 'react'
import context from './context'
import { Layout, StatusTip } from 'tea-component'
import { GetSysInfo } from '@/assets/js/storage'

import Routes from '@/router'
import Head from '@components/Head'
import Left from '@components/Left'
import Foot from '@components/Foot'
import { post } from './axios/index'
import { SetMenus } from '@assets/js/storage'
import 'moment/locale/zh-cn'
import moment from 'moment'
moment.suppressDeprecationWarnings = true

const { LoadingTip } = StatusTip
const { Body, Content } = Layout

function App ({ location, onlyBody }) {
    const { setTableColumns } = useContext(context)
    const [allMenus, setAllMenus] = useState([])
    const [leftMenus, setLeftMenus] = useState([])
    const [loading, setLoading] = useState(false)
    const [topMenus, setTopMenus] = useState([])
    const [currTopMenuName, setCurrTopMenuName] = useState('main')
    const [first, setFirst] = useState(true)
    const [hideLeft, setHideLeft] = useState(false)
    useEffect(() => {
        post('/api/basedata/table/listSetting').then(res => {
            if (res.success) {
                setTableColumns(res.data)
            }
        }).finally(() => {
        })
    }, [setTableColumns])
    useEffect(() => {
        if (!first) { return }
        setFirst(false)
        setLoading(true)
        // 获取当前用户的菜单列表
        post('/api/basedata/navigation/menus').then(res => {
            if (!res?.data) { return }
            if (res.data?.length === 0) { return }
            SetMenus(res.data)
            const all = res.data
            setAllMenus(all)
            const uri = location.pathname
            let cTop = ''
            cTop = all?.reduce((prev, cur) => {
                if (prev === '') { return cur.name }
                if (cur.url === uri) { return cur.name }
                if (cur.children.some(x => x.url === uri)) { return cur.name }
                if (cur.children.filter(x => x.children.some(r => r.url === uri)).length > 0) { return cur.name }
                return prev
            }, cTop)
            setCurrTopMenuName(cTop)
            // 顶部菜单
            const tMenus = all?.map(menu => {
                return {
                    customData: menu.customData,
                    displayName: menu.displayName,
                    name: menu.name,
                    url: menu.url
                }
            })
            setTopMenus(tMenus)
        }).finally(() => {
            setLoading(false)
        })
    }, [location, first])

    useEffect(() => {
        // #region 设置网页图标favicon
        const { favicon } = GetSysInfo()
        const faviconurl = favicon ? `https:${favicon}` : '' // 这里可以是动态的获取的favicon的地址
        const link = document.querySelector("link[rel*='icon']") || document.createElement('link')
        link.type = 'image/x-icon'
        link.rel = 'shortcut icon'
        if (faviconurl) {
            link.href = faviconurl
        }
        document.getElementsByTagName('head')[0].appendChild(link)
        // #endregion
    }, [])

    useEffect(() => {
        // 左侧菜单
        const tMenuArray = allMenus?.filter(menu => menu.name === currTopMenuName)
        if (tMenuArray && tMenuArray.length > 0) {
            const t = tMenuArray[0]
            if (t.url) {
                setHideLeft(true)
            } else {
                setHideLeft(false)
            }
            const lMenus = [...t.children]
            setLeftMenus(lMenus)
        }
    }, [currTopMenuName, allMenus])

    // 返回所有菜单url和displayName  url用于注册路由  displayName用于显示页面
    const routes = useMemo(() => {
        const routes = { data: [] }
        for (const menu of allMenus) {
            if (menu.url && menu.url !== '#') { routes.data.push({ url: menu.url, title: menu.displayName, name: menu.name }) }
            const tMenuArray = menu?.children ?? []
            for (const tMenu of tMenuArray) {
                if (tMenu.url && tMenu.url !== '#') { routes.data.push({ url: tMenu.url, title: tMenu.displayName, name: tMenu.name }) }
                tMenu.children && tMenu.children.forEach(menu => {
                    if (menu.children.length === 0 || menu.children[0].type === 2) {
                        if (menu.url && menu.url !== '#') { routes.data.push({ url: menu.url, title: menu.displayName, name: menu.name }) }
                    } else {
                        menu.children.forEach(child => {
                            if (child.url && child.url !== '#') { routes.data.push({ url: child.url, title: child.displayName, name: child.name }) }
                        })
                    }
                })
            }
        }
        return routes
    }, [allMenus])

    const onHeadChange = useCallback(
        name => {
            setCurrTopMenuName(name)
        },
        [setCurrTopMenuName]
    )

    const memoHead = useMemo(() => (
        <Head hide={onlyBody} topMenus={topMenus} currTopMenuName={currTopMenuName} onChange={onHeadChange} />
    ), [topMenus, currTopMenuName, onHeadChange, onlyBody])

    const memoLeft = useMemo(() => (
        <Left leftMenus={leftMenus} loading={loading} hide={hideLeft || onlyBody} />
    ), [leftMenus, hideLeft, onlyBody, loading])

    return (
        <Layout style={{ minWidth: 1220 }}>
            {/* 头部导航 */}
            {memoHead}
            <Body>
                {/* 左侧菜单 */}
                {memoLeft}

                <Content>
                    <Suspense fallback={<LoadingTip />}>
                        {
                            !!routes.data.length && <Routes routes={routes} currTopMenuName={currTopMenuName} />
                        }
                    </Suspense>
                    <Foot />
                </Content>
            </Body>

        </Layout>
    )
}

export default App
