
import { TypeStatus } from '@/types'
export function getStatus (meta: any, formState: any): TypeStatus {
    if (!meta.isDirty && !formState.isSubmitted) {
        return undefined
    }
    return meta.invalid ? 'error' : 'success'
}
export function getErrorMessage (code: string, formState: any) : string | null {
    return formState.errors[code]?.message ? (formState.errors[code]?.message + '') : null
}
