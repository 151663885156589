import CryptoJS from 'crypto-js'
const _aesKey = 'Yh^f$i1u&(d%f98c'
// const _iv = CryptoJS.enc.Utf8.parse('1234567890000000')
/**
 * @author hanqi
 * @description AES加密
 * @param value
 * @returns {string}
 */
export function AESEncryt (txt) {
    const _key = CryptoJS.enc.Utf8.parse(_aesKey)
    const result = CryptoJS.AES.encrypt(txt, _key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    })
    return result.toString()
}
/**
 * @author hanqi
 * @description AES解密
 * @param value
 * @returns {string}
 */
export function AESDecrypt (txt) {
    const _key = CryptoJS.enc.Utf8.parse(_aesKey)
    const encrypted = CryptoJS.AES.decrypt(txt, _key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    })
    return encrypted.toString(CryptoJS.enc.Utf8)
}
