import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import TopRightData from './TopRightData'
import dataBg from '../../assets/images/databg.png'
import totalicon from '../../assets/images/totalicon.png'
import production from '../../assets/images/production.png'
import warningicon from '../../assets/images/warningicon.png'
import { NSAreaLineTotal } from '../../api'
const TopDataWrap = styled.div`
  height: 100%;
  box-sizing: border-box;
  background-image: url(${dataBg});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`
const ItemWrap = styled.div`
  display: flex;
  grid-gap: 20px;
  align-items: center;
  border-right: 2px solid #0558A9;
  padding: 0% 20px;
  &:last-child {
    border-right: none;
  }
  img {
    width: 70px;
    height: 70px;
  }
`
type DataType = {
  allLineNum: number,
  runningNum: number,
  errorNum: number
}
const TopData: React.FC<any> = ({ corpId }: any) => {
  const [data, setData] = useState<DataType>({
    allLineNum: 0,
    runningNum: 0,
    errorNum: 0
  })
  useEffect(() => {
    NSAreaLineTotal.action({ corpId }).then(res => {
      const { data: _data } = res
      if (_data) {
        setData({
          ..._data
        })
      }
    })
  }, [corpId])
  return <TopDataWrap>
    <ItemWrap>
      <img src={totalicon} alt="" />
      <TopRightData title='总产线数' number={data.allLineNum || 0}/>
    </ItemWrap>
    <ItemWrap>
      <img src={production} alt="" />
      <TopRightData title='生产中产线数' number={data.runningNum || 0}/>
    </ItemWrap>
    <ItemWrap>
      <img src={warningicon} alt="" />
      <TopRightData title='异常产线数' number={data.errorNum || 0}/>
    </ItemWrap>
  </TopDataWrap>
}

export default TopData
