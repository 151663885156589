import React, { useMemo } from 'react'
import AutoTable from '../../../tracedashboard/components/AutoTable'
import { TypeColumn } from '@/types'
type TypeUserSituationProps = {
    data: any[],
}
const LineCollectionRate: React.FC<TypeUserSituationProps> = (props) => {
    const { data } = props
    const columns = useMemo<TypeColumn[]>(() => {
        return [{
            key: 'corpName',
            header: '工厂',
            width: 200,
            headerAlign: 'center',
            align: 'center'
        }, {
            key: 'lineName',
            header: '产线',
            width: 400,
            align: 'center',
            headerAlign: 'center'
        }, {
            key: 'collectComprehensiveRate',
            header: '综合采集率',
            width: 200,
            headerAlign: 'center',
            align: 'center'
        }]
    }, [])
    return <AutoTable columns={columns} data={data} pageSize={8} height={280}/>
}
export default LineCollectionRate
