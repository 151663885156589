import { post } from '@/axios'

// H5代办预警列表
export function getUnreadList (params, config) {
  return post('api/message/backlog/unread/list', params, config)
}

// 待办预警详情列表
export function getUnreadListInfo (params, config) {
  return post('api/message/backlog/unread/listpage', params, config)
}

// 批量更新待办预警消息状态
export function getUnreadStatus (params, config) {
  return post('api/message/backlog/batch/status/update', params, config)
}
