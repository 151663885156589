import React, { useState, useContext } from 'react'
import Context from '@/context'
import {
    Select
} from 'tea-component'
const options = [
    {
        text: '中文',
        value: 'zh-CN'
    }, {
        text: 'English',
        value: 'en-US'
    }
]
export default function TranslateSelect (props) {
    const provider = useContext(Context)
    const [value, setValue] = useState(provider.locale.language)
    const handleOnChange = val => {
        provider.localeDispatch(val)
        setValue(val)
    }
    return <Select
        {...props}
        appearance="default"
        options={options}
        value={value}
        onChange={handleOnChange}
    />
}
