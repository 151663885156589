import React, { useEffect } from 'react'
import autofit from 'autofit.js'
import DashboardContainer from './components/DashboardContainer'
import DashboardTitle from './components/DashboardTitle'
import DashboardLayout from './components/DashboardLayout'
import DashboardCard from './components/DashboardCard'
import FlyLineMap from './components/FlyLineMap'
import GlobalTopData from './components/GlobalTopData/GlobalTopData'
import ComprehensiveCollect from './components/ComprehensiveCollect/ComprehensiveCollect'
import InFlowRanking from './components/InFlowRanking/InFlowRanking'
import ScanWaring from './components/ScanWarning/ScanWarning'
import CardBgS from './assets/images/cardbg-s.png'
import bg from './assets/images/productbg.png'
import styled from 'styled-components'
import TotalData from './components/TotalData/TotalData'
import { useMainData } from './hooks'

const MainWrap = styled.div`
  margin-bottom: 15px;
  flex: 1;
`
const LeftWrap = styled.div`
  margin: 0 53px;
`
const LeftBottomWrap = styled.div`
  position: relative;
`
const Dashboard: React.FC<any> = () => {
  const {
    mainData,
    lineDatas,
    comprehensiveCollectData,
    comprehensiveCollectYAxis,
    inFlowRangkingData,
    scanWarningData,
    currentProvinceName,
    originalProviceName
  } = useMainData()
  useEffect(() => {
    autofit.init({
      dh: 1080,
      dw: 1920,
      el: '#dashboard',
      resize: true
    })
  }, [])
  return <DashboardContainer id='dashboard' background={bg}>
      <DashboardTitle>码智联溯源</DashboardTitle>
      <MainWrap>
          <DashboardLayout columns={[2, 1]} rows={[1]} >
            <LeftWrap>
              <DashboardLayout columns={[1]} rows={[1, 5]}>
                <DashboardLayout columns={[1]} rows={[1]}>
                  <GlobalTopData data={{
                    allScanNum: mainData.allScanNum,
                    diffPlaceScanNum: mainData.diffPlaceScanNum
                  }}/>
                </DashboardLayout>
                <LeftBottomWrap>
                  <TotalData
                    name={originalProviceName}
                    provinceInNum={mainData.provinceInNum}
                    provinceOutNum={mainData.provinceOutNum}
                    provinceOutRate={mainData.provinceOutRate}
                  />
                  <FlyLineMap
                    mapData={[{
                      provinceName: currentProvinceName
                    }]}
                    lineDatas={lineDatas}
                    areaCode={'100000_withoutsansha'}
                    areaName={'全国'}
                  />
                </LeftBottomWrap>
              </DashboardLayout>
            </LeftWrap>
            <DashboardLayout columns={[1]} rows={[1, 1, 1]}>
              <DashboardCard background={CardBgS} title='流入省份TOP10'>
                <ComprehensiveCollect data={comprehensiveCollectData} yAxis={comprehensiveCollectYAxis}/>
              </DashboardCard>
              <DashboardCard background={CardBgS} title='流入产品排行榜'>
                <InFlowRanking data={inFlowRangkingData}/>
              </DashboardCard>
              <DashboardCard background={CardBgS} title='流入客户扫码预警'>
                <ScanWaring data={scanWarningData}/>
              </DashboardCard>
            </DashboardLayout>
          </DashboardLayout>
        </MainWrap>
  </DashboardContainer>
}
export default Dashboard
