import React, { useEffect, useState, useCallback } from 'react'
import { Select, notification, Text, H2, Form, Button } from '@/components/Base'
import { useSubmit, useFetch } from '@/hooks'
import NCard from '@/components/NLayout/NCard'
import { useForm, Controller } from 'react-hook-form'
import { $findByToken } from '@/axios/api'
import { NSCodeInfo, NSetAwardsTactics } from './api'
import AwardsRuleTable from './components/AwardRuleTable'
import CodeViewInfo from './components/CodeViewInfo'
import { queryString } from '@/utils'
import NLink from '../NLink'
const NaryTradeIn: React.FC<any> = (props) => {
    const { control, setValue } = useForm({})
    const { token: _token }: any = queryString()
    const [rewardRule, setrRewardRule] = useState<number | null>(1)
    // #region 获取基本信息
    const [codeInfo, setCodeInfo] = useState<any>({
        requestNo: '',
        corpName: '',
        printCorpName: '',
        productName: '',
        numberStr: '',
        createdTime: '',
        remark: '',
        amount: 0
    })
    const getDefaultFields = (defaultField = {}) => ({
        rewardRule: 1,
        strategyList: [{
            id: 0,
            includeMaskCode: true,
            number: 0,
            priority: 1,
            proportion: 0,
            content: ''
        }],
        ...defaultField
    })
    // 根据token获取id
    const [id, setId] = useState(null)
    const findByToken = useCallback(() => {
        $findByToken({ token: _token }).then(res => {
            if (res.success) {
                setId(res.data.id)
            } else {
                notification.error({ description: res.message })
            }
        })
    }, [_token])
    useEffect(() => {
        if (_token) {
            findByToken()
        }
    }, [id, findByToken, _token])

    const [fields, setFields] = useState<any>(getDefaultFields())

    const { data, loadData, token, setQuerys } = useFetch(NSCodeInfo.action, { id })
    useEffect(() => {
        if (id) {
            setQuerys({ id })
            loadData()
        }
        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            (token.current as any)?.cancel?.('cancel')
        }
    }, [id, loadData, setQuerys, token])

    useEffect(() => {
        if (data) {
            setCodeInfo(data)
        }
    }, [data])
    // #endregion

    const handleOnChangeForm = useCallback((key: any, value: any) => {
        setFields({
            ...fields,
            [key]: value
        })
    }, [fields])

    // #region 保存数据
    const { onSubmit } = useSubmit(NSetAwardsTactics.action, () => {
        // history.goBack()
    })
    const sumArr = (arr: any) => {
        return arr.reduce(function (prev: any, curr: any, idx: any, _arr: any) {
            return prev + curr
        })
    }
    const submit = async () => {
        const { strategyList } = fields
        if (strategyList.length === 0) {
            notification.error({ description: '至少添加一组配置项' })
            return
        }
        if (rewardRule === 1) {
            if (strategyList.some(({ proportion, priority, content }: any) => priority === 0 || proportion === 0 || !content)) {
                notification.error({ description: '配置的规则项不能为空' })
                return
            }
            // 所有比率的和必须等于100%
            const arr = strategyList.map((it: any) => Number(it.proportion))
            if (sumArr(arr) !== 100) {
                notification.error({ description: '所有比率的和必须等于100%' })
                return
            }
        } else {
            if (strategyList.some(({ number, priority, content }: any) => number === 0 || priority === 0 || !content)) {
                notification.error({ description: '配置的规则项不能为空' })
                return
            }
            // 所有申请数量之和必须等于所申请的数量
            const numberArr = strategyList.map((it: any) => Number(it.number))
            if (sumArr(numberArr) !== Number(codeInfo.amount)) {
                notification.error({ description: '所有个数的和必须等于申请数量' })
                return
            }
        }
        await onSubmit({
            ...fields,
            codeRequestId: id,
            rewardRule
        })
    }
    // #endregion
    return (
        <div style={{ width: '100%', height: '100%', backgroundColor: '#F3F4F7' }}>
            <div style={{ width: '800px', height: '100%', position: 'absolute', backgroundColor: '#fff', left: '50%', transform: 'translate(-50%,0)' }}>
                <Text parent="div" align="center" style={{ marginTop: '24px' }}>
                    <H2>N元换购</H2>
                </Text>
                <NCard boxShadow=''>
                        <Form style={{ marginBottom: '10px' }}>
                            <Controller
                                name="channelNumber"
                                control={control}
                                defaultValue={1}
                                render={({ field: { value, onChange } }) => {
                                    return (
                                        <Form.Item
                                            label="奖项规则"
                                            align='middle'
                                        >
                                            <Select
                                                value={value}
                                                onChange={(v: any) => {
                                                    onChange(v)
                                                    setValue('channelNumber', v)
                                                    setrRewardRule(v)
                                                }}
                                                type="simulate"
                                                appearance="button"
                                                placeholder="请选择奖项规则"
                                                size="m"
                                                listWidth={240}
                                                options={[
                                                    { value: 1, text: '比率' },
                                                    { value: 2, text: '个数' }
                                                ]}
                                            />
                                        </Form.Item>
                                    )
                                }}
                            />
                        </Form>
                        <AwardsRuleTable
                            rewardRule={rewardRule}
                            onChange={handleOnChangeForm}
                            value={fields.strategyList}
                        />
                    {
                        CodeViewInfo(codeInfo)
                    }
                    <Form.Action>
                        <Button type="primary" htmlType="submit" onClick={submit}>保存</Button>
                        <NLink to='/'>
                            <Button htmlType="button">跳转到首页</Button>
                        </NLink>
                    </Form.Action>
                </NCard>
            </div>
        </div>
    )
}
export default NaryTradeIn
