import React from 'react'
import styled from 'styled-components'
import LedNumber from './components/LedNumber'
import { randomFloat } from '@/utils'
const LedBoardWrap = styled.div`
  @font-face {
    font-family: DSDIGI;
    src: url('/fonts/DS-DIGI.TTF');
  }
  display: flex;
  font-family: DSDIGI;
`
type TypeLedBoardProps = {
  number: number
}
const LedBoard: React.FC<TypeLedBoardProps> = (props) => {
  const { number = 0 } = props
  const numberStr = number.toString()
  return <LedBoardWrap>
    {
      numberStr.split('').map((item, index) => {
        return <LedNumber key={randomFloat()} number={Number(item)}/>
      })
    }
  </LedBoardWrap>
}
export default LedBoard
