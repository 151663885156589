import { useEffect } from 'react'

/**
 * @author hanqi
 * @description 引入外部js文件
 * @param url数组
 * @returns
 */
export function useScript (urlArray, onChange) {
    useEffect(() => {
        const scriptArray = []
        urlArray.forEach(it => {
            const script = document.createElement('script')
            script.type = 'text/javascript'
            script.async = false
            script.src = it
            scriptArray.push(script)
            document.body.appendChild(script)
        })
        setTimeout(() => {
            onChange && onChange()
        }, 3000)
        return () => {
            scriptArray.forEach(it => {
                document.body.removeChild(it)
            })
        }
    }, [urlArray, onChange])
}
