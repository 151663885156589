import { forwardRef, useState } from 'react'
import { ImagePreview } from 'tea-component'
const NImagePreview = (props: any, _ref: any) => {
    const { field, ...rest } = props
    const [visible, setVisible] = useState<boolean>(true)
    return <>
        <img style={{ display: 'none' }} src={rest.src} onLoad={() => setVisible(true) } onError={() => setVisible(false)}/>
        {
            visible ? <ImagePreview {...rest} /> : ''
        }
        </>
}
export default forwardRef(NImagePreview)
