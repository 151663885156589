import { post } from './index'

// 获取企业下拉框数据源
export function getCorpbaseDropdownlist (params, config) {
    return post('/api/basedata/corp/dropdown/list', params, config)
}
// 获取生产企业下拉框数据源
export function getProductCorpbaseDropdownlist (params, config) {
    return post('web/productmgr/productsinglecorp/corp/dropdown/list', params, config)
}
// 获取产品下拉框数据源
export function getProductbaseDropdownlist (params, config) {
    return post('api/basedata/product/dropdownlist', params, config)
}
// 根据产品ID获取产线
export function getProductLineDropdownlist (params, config) {
    return post('api/basedata/productionline/list', params, config)
}
// 根据企业ID查询车间下拉框
export function getWorkshopsList (params, config) {
    return post('api/basedata/workshop/dropdown/list', params, config)
}
// 获取集团的所有包装比例
export function getGroupPackageList (params, config) {
    return post('web/basedatamgr/packagebase/grouppackages/find', params, config)
}
// 根据产品ID获取包装比例
export function getPackageListByProductID (params, config) {
    return post('api/basedata/lineproductpackage/package/list', params, config)
}
// 获取用户下拉框数据源
export function getUserbaseDropdownlist (params, config) {
    return post('api/basedata/user/dropDown/list', params, config)
}

// 根据字典分组名称获取字典项
export function getDictionaryItemList (params, config) {
    return post('api/basedata/dictionaryitem/list/group', params, config)
}

// 获取企业ERP辅助编码下拉框
export function getCorpERPCodeDownList (params, config) {
    return post('api/basedata/corperp/dropdown/list', params, config)
}

// 获取产品ERP辅助编码下拉框
export function getProductERPCodeDownList (params, config) {
    return post('api/basedata/productecode/list', params, config)
}

// 获取品牌下拉框
export function getProductBrandList (params, config) {
    return post('api/h5/templateproduct/productbrand/list', params, config)
}

export function getExtendPropertyList (params, config) {
    return post('api/basedata/extend/list', params, config)
}

// 退出登录
export function loginOut (params, config) {
    return post('api/basedata/user/signOut', params, config)
}

// 获取行政区域
export function getProvinceList (params, config) {
    return post('api/basedata/nationalregion/list/tree', params, config)
}

// 获取稽查区域
export function getSalesregionList (params, config) {
    return post('api/basedata/salesregion/tree/list/all', params, config)
}

export function getLocalesJs (params, config) {
    return post('api/basedata/language/listSetting', params, config)
}

// 重发验证码
export function $resendsCode (params, config) {
    return post('api/code/codeRequest/download/send', params, config)
}

// 下载码包
export function $downloadCodePackage (params, config) {
    return post('api/code/codeRequest/download', params, {
        responseType: 'blob',
        ...config,
        timeout: 0
    })
}

// 获取追溯码申请详情
export function $findByToken (params, config) {
    return post('api/code/codeRequest/findByToken', params, config)
}

// 根据条件查询经销商下拉框
export function getAgentcorp (params, config) {
    return post('api/basedata/agentcorp/dropdown/list', params, config)
}

// 销售区域下拉框
export function NSSalesregionbizcorpList (params, config) {
    return post('api/basedata/salesregion/all/dropdown/list', params, config)
}

// 盖厂下拉框
export function NSPrintcorpList (params, config) {
    return post('api/basedata/printcorp/dropdown/list', params, config)
}

export { NSEditTableUserSetting, NSFindTableUserSetting } from './api_ts'
