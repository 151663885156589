import React, { useState, useMemo, useEffect, forwardRef, useImperativeHandle, useCallback } from 'react'
import { Select, StatusTip } from '@/components/Base'
import { useFetch } from '@/hooks'

function NSelect ({
    action,
    params = {},
    setOptions,
    onChange,
    value,
    defaultValue,
    disabled = false,
    placeholder = '',
    selected,
    clearable = true,
    searchKey,
    size = 'm',
    ...rest
}, ref) {
    // const timerRef = useRef(null)
    const [searchValue, setSearchValue] = useState('')
    const [initSelect, setInitSelect] = useState(selected)
    // const [isFirstLoad, setIsFirstLoad] = useState(true)
    // const [isChangeQuerys, setIisChangeQuerys] = useState(true)
    const {
        data,
        loading,
        loadData,
        setQuerys,
        error,
        token
    } = useFetch(action, params)
    // useEffect(() => {
    //     setQuerys(params)
    // }, [params, setQuerys])
    // console.log('value', value)
    // const fetch = useCallback(() => {
        // const keys = Object.keys(params)
        // let isParamsChange = false
        // for (const key of keys) {
        //     if (params[key] !== querys?.[key]) {
        //         isParamsChange = true
        //     }
        // }
        // if (!isParamsChange && !isFirstLoad) { return }
        // setIsFirstLoad(false)
        // if (!isChangeQuerys) {
            // setIisChangeQuerys(false)
        // }
        // setQuerys(params)
        // loadData()
    // 去除params的监听，原因是因为params在外面传进来的时候是新对象，而且params只是一开始的时候会用到
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [/* isChangeQuerys,  */loadData, setQuerys])
    const handleOnChange = useCallback((val, option) => {
        // 清除选项的时候，去掉筛选值
        if (!val) {
            setSearchValue('')
            setInitSelect(selected)
            setQuerys({
                [searchKey]: null
            })
            loadData()
        }
        onChange(val, option)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadData, searchKey, setQuerys, onChange])
    const options = useMemo(() => {
        const optionsValue = setOptions && setOptions(data || [])
        if (initSelect && initSelect.value && optionsValue.filter(x => x.value === initSelect.value).length === 0) {
            return [
                ...optionsValue,
                initSelect
            ]
        }
        return optionsValue
    // 去除setOptions的监听，因为该函数只是对结果进行处理，不存在变化的情况，加入监听有可能导致options的改变
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, initSelect])
    useImperativeHandle(ref, () => ({
        setParams: p => {
            setInitSelect(null)
            setSearchValue('')
            setQuerys({
                ...p,
                [searchKey]: null
            })
            // setIsFirstLoad(true)
            // setIisChangeQuerys(true)
            loadData()
        },
        getOptions: () => {
            return options
        }
    }))

    useEffect(() => {
        setQuerys(params)
        loadData()
        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            token.current?.cancel?.('cancel')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadData, setQuerys, token])
    // console.log('token.current', token.current)
    useEffect(() => {
        setInitSelect(selected)
    }, [selected])
    const fetchStatus = useMemo(() => {
        if (loading) { return 'loading' }
        if (error) { return 'error' }
        return null
    }, [loading, error])
    return (
        <Select
            disabled={disabled}
            searchable
            searchValue={searchValue}
            matchButtonWidth
            autoClearSearchValue={false}
            type="simulate"
            size={size}
            clearable={clearable}
            appearance="button"
            placeholder={placeholder}
            defaultValue={defaultValue}
            value={value}
            {...rest}
            onSearch={onSearchValue => {
                setSearchValue(onSearchValue)
                setInitSelect(null)
                setQuerys({
                    [searchKey]: onSearchValue
                })
                // setIsFirstLoad(true)
                loadData()
            }}
            options={options}
            onChange={handleOnChange}
            tips={
                fetchStatus
                    ? (
                        <StatusTip
                            status={fetchStatus}
                            onRetry={() => loadData()}
                        />
                    )
                    : null
            }
        />
    )
}

export default forwardRef(NSelect)
