import { useEffect } from 'react'
import { randomString } from '../Login/utils'
import {
    Input
} from '@/components/Base'
const baseProxyURL =
    process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BaseURL : ''
const VerifyCode = (props: any) => {
    const {
        reset,
        setReset,
        onChange,
        value,
        randomStr,
        setRandomStr
    } = props
    const handleClick = () => {
        setRandomStr(randomString())
    }
    useEffect(() => {
        if (reset) {
            setRandomStr(randomString())
            setReset(false)
        }
    }, [reset, setRandomStr, setReset])
    return <div style={{ width: '100%', display: 'inline-flex', justifyContent: 'flex-start', alignItems: 'center' }}>
        <Input style={{ width: '73%' }} placeholder='请输入验证码' className='pwdInput' value={value} onChange={onChange} />
        <img style={{ height: 40, width: 96, marginLeft: 15, cursor: 'pointer' }} onClick={handleClick} src={`${baseProxyURL}/openapi/getCode?randomStr=${randomStr}`} />
    </div>
}
export default VerifyCode
