import React, { useCallback, useEffect, useState } from 'react'
import {
    Form,
    Button,
    Text,
    H2,
    notification,
    LoadingTip
} from 'tea-component'
import { Link } from 'react-router-dom'
import { queryString } from '@/utils'
import { $findByToken } from '@/axios/api.js'
import { $getCodeInfo } from '../api'
import TraceableCode from './components/TraceableCode'
import Transpond from './components/Transpond'
import Download from './components/Download'

export default function CodePkgDownloadModal () {
    // 根据token获取id
    const [id, setId] = useState(null)
    const [fields, setFields] = useState()
    const findByToken = useCallback(() => {
        $findByToken({ token: queryString().token }).then(res => {
            if (res.success) {
                setId(res.data.id)
            } else {
                notification.error({ description: res.message })
            }
        })
    }, [])

    const getCodeInfo = useCallback(() => {
        $getCodeInfo({ id }).then(res => {
            if (res.success) {
                setFields(res.data)
            }
        })
    }, [id])
    useEffect(() => {
        if (queryString().token) {
            findByToken()
        }
    }, [id, findByToken])
    useEffect(() => {
        if (id) {
            getCodeInfo()
        }
    }, [getCodeInfo, id])

    return (
        <>
            <div style={{ width: '100%', height: '100%', backgroundColor: '#F3F4F7' }}>
                <div style={{ width: '700px', height: '100%', position: 'absolute', overflowY: 'auto', backgroundColor: '#fff', left: '50%', transform: 'translate(-50%,0)' }}>
                {fields
                ?<>
                    {!fields.forward && !fields.subpackageDownload
                        ? <div>
                            <Text parent="div" align="center" style={{ marginTop: '30px' }}>
                                <H2>下载追溯码</H2>
                            </Text>
                            <Form.Action></Form.Action>
                            <div style={{ padding: '30px' }} >
                                <TraceableCode id={id} />
                            </div>
                        </div>
                        : null}

                    {fields.forward
                        ? <div>
                            <Text parent="div" align="center" style={{ marginTop: '30px' }}>
                                <H2>转发</H2>
                            </Text>
                            <Form.Action></Form.Action>
                            <div style={{ padding: '30px' }}>
                                <Transpond id={id} fields={fields} />
                            </div>
                        </div>
                        : null}

                    {fields.subpackageDownload
                        ? <div>
                            <Text parent="div" align="center" style={{ marginTop: '30px' }}>
                                <H2>分包下载</H2>
                            </Text>
                            <Form.Action></Form.Action>
                            <div style={{ padding: '30px' }}>
                                <Download id={id} fields={fields} />
                            </div>
                        </div>
                        : null}

                    <Link to='/'>
                        <Button type="primary" style={{ width: '20%', marginLeft: '40%' }}>
                            跳转到首页
                        </Button>
                    </Link>

                    </>
                    :<LoadingTip />}
                </div>
            </div>

        </>
    )
}
