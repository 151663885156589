import React, { useState } from 'react'
import './index.css'
import GetCodeCom from './components/GetCode'
import SetNewPassWord from './components/SetNewPassWord'

export default function UpdatePwd () {
    const [showcode, setShowcode] = useState(true)
    const [userId, setUserId] = useState(null)
    const [verCode, setVerCode] = useState(null)
    const changstep = (id, verificationCode) => {
        setShowcode(false)
        setUserId(id)
        setVerCode(verificationCode)
    }

    return (
        <div className='forgotPass'>
            <div className='content'>
                <h3 className='title'>找回密码</h3>
                <div className='forgetPass'>
                    {
                        showcode ? <GetCodeCom changstep={changstep} /> : <SetNewPassWord userId={userId} verCode={verCode} />
                    }
                </div>
            </div>
        </div>
    )
}
