import React, { Component } from 'react'
import { Status } from '../Base'
import NCard from '../NLayout/NCard'
export default class UnExpectedException extends Component {
    render () {
        return (<div style={{ padding: '20px' }}>
            <NCard>
            <Status
                size={'l'}
                title={'系统异常'}
                description={'请尝试刷新下页面或联系管理员'}
            />
        </NCard>
        </div>)
    }
}
