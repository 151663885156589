import { post } from '@/axios'
import { TypeConfig } from '@/types'
// 获取全国工厂信息
export namespace NSArea {
    export type Params = {
    }
    export type Result = {
        corpId: number,
        corpName: string,
        provinceName: string, // 省份名称
        provinceCode: string,
        cityName: string, // 市区
        cityCode: string
    }

  export function action (params: Params, config?: TypeConfig) {
    return post('api/qingpi/produce/corp/list', params, config)
  }
}
// 查询产线信息统计
export namespace NSAreaLineTotal {
    export type Params = {
        corpId?: number
    }
    export type Result = {
        allLineNum: number, // 总产线数
        runningNum: number, // 运行中的产线
        errorNum: number, // 异常运行
    }

  export function action (params: Params, config?: TypeConfig) {
    return post('api/qingpi/production/line/total', params, config)
  }
}

// 数据分析-区域采集量
export namespace NSAreaCollection {
  export type Params = {
      provinceId: number, // 省id
      cityId: number, // 市id
      districtId: number // 区id
  }
  type MapDetail = {
      amount: number, // 数量
      corpName: string, // 工厂名称
  }
  export type Result = {
      provinceName: string,
      provinceCode?: string,
      details: MapDetail[]
  }
  export function action (params: Params, config?: TypeConfig) {
      return post('openapi/qingpi/data/analysis/getAreaCollection', params, config)
  }
}
// 产线实时监控曲线图
export namespace NSProductLineCurve {
    export type Params = {
        corpId?: number | null
    }
    export type CollectionRateDTOList = {
        bottleRelationBoxNum: number,
        collectBottleRate: number, // 平均瓶采
        collectBoxRate: number, // 平均箱采集率集率
        collectBottleBoxRate: number, // 平均瓶箱采集率
        collectBoxChopRate: number, // 平均箱托采集率
        collectComprehensiveRate: number, // 平均综合采集率
        collectTime: string, // 采集时间
    }
    export type Result = {
        lineName: string,
        collectionRateDTOList: CollectionRateDTOList[]
    }
    export function action (params: Params, config?: TypeConfig) {
        return post('api/qingpi/produce/total/rate/listByLine', params, config)
    }
}
// 按工厂查询关联率
export namespace NSFactoryRelationRate {
    export type Params = {
        corpId?: number, // 工厂id
        dateRange?: string
    }
    export type Result = {
        collectBottleRate: number, // 瓶采集率
        collectBoxRate: number, // 箱采集率
        collectBottleBoxRate: number, // 瓶箱采集率
        collectBoxChopRate: number, // 箱托采集率
        collectComprehensiveRate: number, // 平均采集率
        bottleRelationBoxNum?: number // 与箱建立关联关系的瓶数,
    }
    export function action (params: Params, config?: TypeConfig) {
        return post('api/qingpi/produce/total/rate/findByCorp', params, config)
    }
}
// 工厂预警列表
export namespace NSFactoryWarningList {
    export type Params = {
        corpId?: number,
        dateRange?: string,
        currentPage?: number,
        itemsPerPage?: number
    }
    export type Items = {
        id: number, // 预警id
        corpId: number,
        corpName: string,
        groupId: number, // 工厂名称
        productionLineId: number,
        productionLineName: string,
        errorMsg: string // 预警原因
        warningTime: string, // 预警时间
    }
    export type Result = {
            data: Items[],
            items: {}
    }
    export function action (params: Params, config?: TypeConfig) {
        return post('api/qingpi/production/error/line/listPage', params, config)
    }
}
// 获取综合采集率列表
export namespace NSCollectRateList {
    export type Params = {
        corpId?: number | null,
        dateRange?: string,
        currentPage?: number,
        itemsPerPage?: number
    }
    export type Items = {
        id: number, // 综合采集率id
        corpName: string,
        productionLineName: string,
        collectComprehensiveRate: number, // 综合采集率
    }
    export type Result = {
        items: Items[],
    }
    export function action (params: Params, config?: TypeConfig) {
        return post('api/qingpi/produce/collect/rate/listPage', params, config)
    }
}
