import React, { useContext } from 'react'
import Context from '@/context'
import {
    Button
} from 'tea-component'
import { useIntl } from 'react-intl'
export default function TranslateButton () {
    const intl = useIntl()
    const provider = useContext(Context)
    const swtichLocale = () => {
        if (provider.locale.language === 'zh-CN') {
            provider.localeDispatch('en-US')
        } else {
            provider.localeDispatch('zh-CN')
        }
    }
    return <Button type="link" htmlType="button" onClick={swtichLocale}>
        {intl.formatMessage({ id: 'common_switch_chinese', defaultMessage: '切换至英文' })}
    </Button>
}
