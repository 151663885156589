import React, { useState, useEffect, useMemo, useCallback } from 'react'
import wx from 'weixin-js-sdk'
import { postWithAnonymous as post } from '@/axios'
import styles from './index.module.css'
type TypeErrorText = string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined
const ua: string = navigator.userAgent.toLowerCase()
const isWeixin = ua.indexOf('micromessenger') !== -1 // 是微信环境
const Scancode = ({ match }: any) => {
    console.log(match.params)
    const { code } = match.params
    const [wxReady, setWxReady] = useState(false)
    const [wxPath, setWxPath] = useState<any>(null)
    const [bgUrl, setBgUrl] = useState<any>(null)
    const [errText, setErrText] = useState<any>(null)

    // 未获取结果：0，成功：1 ，失败 ：2
    const [step, setStep] = useState<0 | 1 | 2>(0)
    const btndom = useMemo(() => {
        if (isWeixin) {
            console.log('isWeixin')
            // 在微信小程序中打开
            if (wxPath) {
                console.log('获取到‘目标小程序’ 的信息')

                // 获取到‘目标小程序’ 的信息
                return (
                    <wx-open-launch-weapp
                        id="launch-btn"
                        username={wxPath?.username}
                        path={wxPath?.path}
                        style={{ margin: '0 auto', width: '80vw' }}
                    >
                        <script type="text/wxtag-template">
                            <div style={{
                                width: '100%',
                                margin: '0 auto',
                                textAlign: 'center',
                                color: '#fff',
                                backgroundColor: '#006eff',
                                height: '40px',
                                lineHeight: '40px'
                            }}>立即查看</div>
                        </script>
                    </wx-open-launch-weapp>
                )
            } else {
                console.log('未 获取到‘目标小程序’ 的信息')
                return null
            }
        } else {
            // 在 ‘非’ 微信小程序中打开
            console.log('在 ‘非’ 微信小程序中打开')

            return (
                <div className={styles.succBtn}>
                    请使用微信打开
                </div>
            )
        }
    }, [wxPath])
    const brErrorText = useCallback((text: string) => {
        const errTextArr = text.split('<br/>')
        return errTextArr.map((it: TypeErrorText) => {
            return <><p>{it}</p></>
        })
    }, [])
    const stepDom = useMemo(() => {
        if (step === 0) {
            return null
        } else if (step === 1) {
            return <>
                <img className={styles.bgimg} src={bgUrl} alt="" />
                <div className={styles.btnCon}>
                    {btndom}
                </div>
            </>
        } else if (step === 2) {
            return <div style={{ position: 'relative' }}>
                <img className={styles.bgimg} src={bgUrl} alt="" />
                <p className={styles.errortext}>{brErrorText(errText)}</p>
            </div>
        } else {
            return null
        }
    }, [bgUrl, brErrorText, btndom, errText, step])

    const isIos14AndMoreFun = useCallback(() => {
        // 判断ios手机版本号大于14，大于14就拿存储的url,否则拿当前页面的url
        try {
            const str = navigator.userAgent.toLowerCase()
            const ver = str.match(/cpu iphone os (.*?) like mac os/)

            if (!ver) {
                return false
            } else {
                return Number(ver[1].split('_')[0]) >= 10
            }
        } catch (e) {
            return false
        }
    }, [])

    useEffect(() => {
        const url = sessionStorage.getItem('scanCodeUrl')
        const isIos14AndMore = isIos14AndMoreFun()
        if (!wxReady && !wxPath) {
            post('/api/h5/wechat/find', {
                code: process.env.REACT_APP_CODE,
                url: (isIos14AndMore && url) ? url : window.location.href.split('#')[0]
            }).then((rs: any) => {
                if (!rs.success) {
                    return
                }
                wx.config({
                    beta: true,
                    appId: rs.data.appId, // 必填，公众号的唯一标识
                    // debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印
                    timestamp: rs.data.timestamp, // 必填，生成签名的时间戳
                    nonceStr: rs.data.nonceStr, // 必填，生成签名的随机串
                    signature: rs.data.signature, // 必填，签名
                    jsApiList: ['scanQRCode', 'onMenuShareQZone', 'onMenuShareWeibo', 'onMenuShareQQ'],
                    openTagList: ['wx-open-launch-weapp'] // 获取开放标签权限
                })
                wx.ready(function () {
                    // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中
                    console.log('ready')

                    setWxReady(true)
                    wx.getLocation({
                        success ({ latitude, longitude }: any) {
                            post('/api/qingpi/h5/maskcode/find', {
                                code,
                                latitude,
                                longitude,
                                sourceForm: 9,
                                url: window.location.href.split('#')[0]
                            }).then(res => {
                                if (res.success) {
                                    document.title = (res?.data?.systemName) ?? ''
                                    setStep(1)
                                    setBgUrl(res.data.templateUrl)
                                    res.data.originalId && setWxPath({
                                        username: res.data.originalId,
                                        path: res.data.url
                                    })
                                } else {
                                    document.title = '扫码异常'
                                    setBgUrl(res.data.templateUrl)
                                    setErrText(res.message)
                                    setStep(2)
                                }
                            })
                        }
                    })
                })
            })
        }
    }, [code, isIos14AndMoreFun, wxPath, wxReady])
    return (
        <div className={[styles.codecon, 'noScrollbar'].join(' ')}>
            {/* <div style={{ position: 'relative' }}>
                <img className={styles.bgimg} src='//cloud3-test.oss-cn-hangzhou.aliyuncs.com/7/images/product/2fa6e083-6338-479a-a616-5e9e49980489.png' alt="" />
                <p className={styles.errortext}>异常模板4异常模板4异常模板4异常模板4</p>
            </div> */}
            {stepDom}
        </div>
    )
}

export default Scancode
