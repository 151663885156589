
import React, { useState, useEffect } from 'react'
import style from '../index.css'
import { Button, notification, Form, Input } from 'tea-component'
import { useForm, Controller } from 'react-hook-form'
import { getVerificationcode, verifyCode } from '../api'
let timer = null
export default function GetCode ({ changstep }) {
    const { control, formState, handleSubmit, getValues } = useForm()

    // 登录
    const [codeText, setCodeText] = useState('获取验证码')
    const [remainingTime, setRemainingTime] = useState(90)
    const [isMounted, setIsMounted] = useState(true)
    const [isVerify, setIsVerify] = useState(false)

    // input状态
    function getStatus (meta) {
        if (!meta.isDirty && !formState.isSubmitted) {
            return null
        }
        return meta.invalid ? 'error' : 'success'
    }
    function getCode () {
        if (!getValues().loginName) {
            notification.error({ description: '请输入账号' })
            return
        }
        getVerificationcode(getValues().loginName).then(rs => {
            if (rs.success) {
                notification.success({ description: rs.message })
                // setDiscodeBtn(true)
                timer = setInterval(() => {
                    setRemainingTime(val => {
                        val = val - 1
                        return val
                    })
                }, 1000)
            } else {
                notification.error({ description: rs.message })
            }
        })
    }
    useEffect(() => {
        setCodeText(remainingTime < 90 ? `${remainingTime}秒后获取` : '获取验证码')
        return () => {
            if (remainingTime <= 0) {
                clearInterval(timer)
                timer = null
                setRemainingTime(90)
                // setDiscodeBtn(true)
            }
        }
    }, [remainingTime])
    useEffect(() => {
        return () => {
            setIsMounted(false)
        }
    }, [isVerify])
    function onSubmit (model) {
        verifyCode(model).then(rs => {
            if (!rs.success) {
                notification.error({ description: rs.message })
            } else {
                if (isMounted) {
                    setIsVerify(true)
                    changstep(rs.data.id, model.verificationCode)
                }
            }
        })
    }
    return (
        <form style={{ width: 'form-material', margin: 'auto' }} id="loginForm" onSubmit={handleSubmit(onSubmit)}>
            <Form>
                <Controller
                    name="loginName"
                    control={control}
                    rules={{ required: '请输入账号' }}
                    render={({ field, fieldState }) => (
                        <Form.Item
                            className={style.loginForm}
                            required={true}
                            status={
                                formState.isValidating ? 'validating' : getStatus(fieldState)
                            }
                            message={formState.errors.loginName?.message}
                            style={{ color: 'red' }}
                        >
                            <Input type="loginName" {...field} autoComplete="off" placeholder="请输入账号" className={style.pwdInput} />
                        </Form.Item>
                    )}
                />
                <Controller
                    name="verificationCode"
                    control={control}
                    rules={{ required: '请输入密码' }}
                    render={({ field, fieldState }) => (
                        <Form.Item
                            required={true}
                            className='verificationCode'
                            status={
                                formState.isValidating ? 'validating' : getStatus(fieldState)
                            }
                            message={formState.errors.verificationCode?.message}
                        >
                            <Input type="verificationCode" {...field} autoComplete="off" placeholder="请输入密码" className={style.pwdInput} />
                            <Button disabled={remainingTime < 90} htmlType="button" type="weak" style={style} onClick={getCode}>
                                {codeText}
                            </Button>
                        </Form.Item>
                    )}
                />
            </Form>
            <Button className='blockBtn' type="primary" htmlType="submit" disabled={formState.isValidating}>下一步</Button>
        </form>
    )
}
