import React, {
 } from 'react'
import {
} from 'tea-component'

export default function Foot () {
    return (
        <>
        </>
    )
}
