import React from 'react'
import styled from 'styled-components'
import totalBg from '../../assets/images/totalbg.png'
type TypeTotalDataProps = {
  name: string,
  provinceInNum: number,
  provinceOutNum: number,
  provinceOutRate: number
}
const TotalDataWrap = styled.div`
  width: 569px;
  height: 104px;
  background-image: url(${totalBg});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  position: absolute;
  top: 0px;
  left: 0px;
`
const TotalDataItemWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const TotalDataItemContent = styled.div`
  font-size: 34px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #421251;
  background: linear-gradient(0deg, #FFFFFF 44.7998046875%, #9DA6B5 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
`
const TotalDataItemUnit = styled.div`
  font-size: 18px;
  margin-bottom: 5px;
`
const TotalDataItemTitle = styled.div`
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 23px;
`
const TotalData: React.FC<TypeTotalDataProps> = (props) => {
  const { name, provinceInNum, provinceOutNum, provinceOutRate } = props
  const isWan = (number: number) => {
    if (number >= 10000) {
      return [true, (number / 10000).toFixed(1)]
    } else {
      return [false, number]
    }
  }
  return <TotalDataWrap>
    <TotalDataItemWrap>
      <TotalDataItemContent>
        {isWan(provinceInNum)[1] ?? 0}
        {
          isWan(provinceInNum)[0] && <TotalDataItemUnit>万</TotalDataItemUnit>
        }
      </TotalDataItemContent>
      <TotalDataItemTitle>{name}流入总量</TotalDataItemTitle>
    </TotalDataItemWrap>
    <TotalDataItemWrap>
      <TotalDataItemContent>
        {isWan(provinceOutNum)[1] ?? 0}
        {
          isWan(provinceOutNum)[0] && <TotalDataItemUnit>万</TotalDataItemUnit>
        }
      </TotalDataItemContent>
      <TotalDataItemTitle>{name}流出总量</TotalDataItemTitle>
    </TotalDataItemWrap>
    <TotalDataItemWrap>
      <TotalDataItemContent>{provinceOutRate ?? 0}%</TotalDataItemContent>
      <TotalDataItemTitle>{name}流出率</TotalDataItemTitle>
    </TotalDataItemWrap>
  </TotalDataWrap>
}
export default TotalData
