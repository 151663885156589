import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import Chart from './Chart'
import mapBg from '../assets/images/mapbg.png'
import SanShaShi from '../assets/images/sanshashi.png'
const FlyLineMapWarp = styled.div`
  position: relative;
  background-image: url(${mapBg});
  background-repeat: no-repeat;
  background-position-y: bottom;
  background-position-x: center;
  width: 100%;
  height: 100%;
  img {
    position: absolute;
    bottom: 20px;
    right: 50px;
    width: 114px;
    height: 153px;
  }
`
// 地图数据
const chinaGeoCoordMap: any = {
  黑龙江: [127.9688, 45.368],
  内蒙古: [110.3467, 41.4899],
  吉林省: [125.8154, 44.2584],
  北京市: [116.4551, 40.2539],
  辽宁省: [123.1238, 42.1216],
  河北省: [114.4995, 38.1006],
  天津市: [117.4219, 39.4189],
  山西省: [112.3352, 37.9413],
  陕西省: [109.1162, 34.2004],
  甘肃省: [103.5901, 36.3043],
  宁夏: [106.3586, 38.1775],
  青海省: [101.4038, 36.8207],
  新疆: [87.9236, 43.5883],
  西藏: [91.11, 29.97],
  四川省: [103.9526, 30.7617],
  重庆市: [108.384366, 30.439702],
  山东省: [117.1582, 36.8701],
  河南省: [113.4668, 34.6234],
  江苏省: [118.8062, 31.9208],
  安徽省: [117.29, 32.0581],
  湖北省: [114.3896, 30.6628],
  浙江省: [119.5313, 29.8773],
  福建省: [119.4543, 25.9222],
  江西省: [116.0046, 28.6633],
  湖南省: [113.0823, 28.2568],
  贵州省: [106.6992, 26.7682],
  云南省: [102.9199, 25.4663],
  广东省: [113.12244, 23.009505],
  广西省: [108.479, 23.1152],
  海南省: [110.3893, 19.8516],
  上海市: [121.4648, 31.2891]
}

const convertData = function (data: any) {
  const res = []
  for (const dataItem of data) {
    const fromCoord = chinaGeoCoordMap[dataItem.from]
    const toCoord = chinaGeoCoordMap[dataItem.to] // 中心点地理坐标
    if (fromCoord && toCoord) {
      res.push([
        {
          // 飞线从哪里出发
          coord: fromCoord,
          value: 1,
          type: dataItem.type
        },
        {
          // 飞线去往哪里
          coord: toCoord,
          value: 1,
          type: dataItem.type
        }
      ])
    }
  }
  return res
}
// 组装series
const buildSeries = (params: Record<string, any>) => {
  console.log('params', params)
  const series = []
  series.push(
    {
      name: '采集总量',
      map: params.mapName ?? 'china',
      type: 'map',
      geoIndex: 0,
      data: params.citys
    },
    {
      type: 'lines',
      coordinateSystem: 'geo',
      zlevel: 2,
      effect: {
        show: true,
        period: 4, // 箭头指向速度，值越小速度越快
        trailLength: 0, // 特效尾迹长度[0,1]值越大，尾迹越长重
        symbol: 'arrow', // 箭头图标
        symbolSize: 5, // 图标大小
        color: '#FFDA0E'
      },
      lineStyle: {
        normal: {
          show: true,
          width: 1, // 尾迹线条宽度
          opacity: 1, // 尾迹线条透明度
          curveness: 0.3, // 尾迹线条曲直度
          color: (_params: any) => {
            return _params.data.type === 'in' ? '#f56c6c' : '#FFDA0E'
          } // 飞线颜色
          // color: '#FFDA0E'
        },
        color: '#FFDA0E'
      },
      data: convertData(params.lineDatas)
    },
    {
      type: 'effectScatter',
      coordinateSystem: 'geo',
      zlevel: 2,
      rippleEffect: {
        // 涟漪特效
        period: 4, // 动画时间，值越小速度越快
        brushType: 'stroke', // 波纹绘制方式 stroke, fill
        scale: 3, // 波纹圆环最大限制，值越大波纹越大
        color: '#FFDA0E'
      },
      label: {
        normal: {
          show: false,
          position: 'right', // 显示位置
          offset: [5, 0], // 偏移设置
          formatter: (_params: any) => {
            // 圆环显示文字
            return _params.data.name
          },
          fontSize: 13
        },
        emphasis: {
          show: false
        }
      },
      symbol: 'circle',
      symbolSize: () => {
        return 5 // 圆环大小
      },
      itemStyle: {
        normal: {
          show: false,
          color: '#fce182'
        }
      },
      data: [...params.lineDatas.map((dataItem: any) => {
        return {
          name: dataItem.from,
          value: chinaGeoCoordMap[dataItem.from]
        }
      }), ...params.lineDatas.map((dataItem: any) => {
        return {
          name: dataItem.to,
          value: chinaGeoCoordMap[dataItem.to]
        }
      })]
  },
  // 中心点
  {
    type: 'effectScatter',
    coordinateSystem: 'geo',
    zlevel: 15,
    rippleEffect: {
      period: 4,
      brushType: 'stroke',
      scale: 4,
      color: '#38ff85'
    },
    label: {
      normal: {
        show: false,
        position: 'right',
        // offset:[5, 0],
        color: '#38ff85',
        formatter: '{b}',
        textStyle: {
          color: '#38ff85'
        }
      },
      emphasis: {
        show: false,
        color: '#38ff85'
      }
    },
    symbol: 'circle',
    symbolSize: 5,
    itemStyle: {
      color: '#38ff85'
    }
  })
  return series
}

export const mapOptions = (params: { citys: { value2: any[] }[], mapName: string, lineDatas: any[] }) => ({
  title: {
    show: false,
    text: '浙江',
    left: 'center',
    textStyle: {
      color: '#fff'
    }
  },
  legend: {
    show: false
  },
  geo: {
    nameMap: {
      China: '中国'
    },
    map: params.mapName,
    label: {
      show: true,
      color: '#fff',
      // offset: [20, 20],
      emphasis: {
        color: '#fff'
      }
    },
    zoom: 1.2,
    itemStyle: {
      normal: {
        borderColor: '#04A8DD', // 区域边框颜色
        areaColor: '#0154A7', // 区域颜色
        borderWidth: 0.5, // 区域边框宽度
        shadowBlur: 5,
        shadowColor: 'rgba(107,91,237,.7)'
      },
      emphasis: {
        borderColor: '#04A8DD',
        areaColor: 'rgba(102,105,240,.3)',
        borderWidth: 1,
        shadowBlur: 5,
        shadowColor: 'rgba(135,138,255,.5)'
      }
    }
    // data: params.citys,
  },
  visualMap: {
    show: false,
    // 设置最大值和最小值
    min: 0,
    max: 50,
    // 设置位置
    left: '4%',
    top: '40%',
    text: ['高', '低'], // 文本，默认为数值文本
    calculable: true,
    seriesIndex: [0], // 作用在哪个series上
    inRange: {
      color: ['#FFCA4B'] // 粉黄
    }
  },

  series: buildSeries(params)
})

type TypeMapProps = {
    mapData: any[],
    /** 飞线数据 */
    lineDatas: any[],
    areaCode?: string,
    areaName?: string
}
const FlyLineMap: React.FC<TypeMapProps> = (props) => {
    const { mapData, lineDatas, areaCode, areaName } = props
    const _map = useMemo(() => {
        const map: any = {
            citys: [],
            mapName: areaName,
            lineDatas
        }
        mapData.forEach((item: any) => {
            const city= {
              name: item.provinceName,
              value: [1],
              value2: item.details
            }
            map.citys.push(city)
        })
        console.log('map', map)
        return map
    }, [areaName, lineDatas, mapData])
    const [renderer] = useState<string>('canvas')
    return <FlyLineMapWarp>
            {
                mapData
                ? <Chart renderer={renderer} option={mapOptions(_map)} areaCode={areaCode} areaName={areaName}/>
                : <></>
            }
            <img src={SanShaShi} alt="" />
        </FlyLineMapWarp>
}
export default FlyLineMap
