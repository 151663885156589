import React from 'react'
import styled from 'styled-components'
import numberBg from '../../../assets/images/numberbg.png'

type TypeLedNumberProps = {
  number: number
}

const LedNumberWrap = styled.div`
  background-image: url(${numberBg});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 42px;
  height: 53px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #01FFF6;
  font-size: 44px;
`

const LedNumber: React.FC<TypeLedNumberProps> = (props) => {
  const { number } = props
  return <LedNumberWrap>{number}</LedNumberWrap>
}
export default LedNumber
