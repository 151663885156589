import React, { useCallback, useMemo } from 'react'
import { withRouter, useHistory } from 'react-router-dom'
import { Layout, List, NavMenu, Text, notification } from 'tea-component'
import { RemoveSysInfo, GetUserInfo, GetSysInfo } from '@/assets/js/storage.js'
import { instance } from '@/axios/index'
import TranslateSelect from '@/components/i18n/TranslateSelect.jsx'
import './index.css'
import dLogo from '@/assets/images/white-sysLogo.png'
import { loginOut } from '@/axios/api'
const { Header } = Layout
const envLogo = process.env.REACT_APP_Logo || 'tts'
function Head ({ topMenus, currTopMenuName, onChange, hide }) {
    const projectLogoImg = require(`@/components/Login/images/login-logo-${envLogo}.png`).default
    const history = useHistory()
    const { corpName, nickName } = GetUserInfo()

    const LogoOut = useCallback(() => {
        loginOut({}).then(res => {
            const { success, message } = res || {}
            if (success) {
                RemoveSysInfo()
                instance.defaults.headers.Authorization = ''
                history.push('/sysmgr/user/login')
            } else {
                notification.error({ description: message })
            }
        }).catch(e => {
            notification.error({
                description: '请求失败'
            })
        })
    }, [history])

    const MyProfile = useCallback(() => {
        onChange('top_profile')
        history.push('/sysmgr/myprofile/index')
    }, [history, onChange])

    const handleClickTopMenu = (name, url) => {
        onChange(name)
        // 如果顶部菜单有链接地址，则跳转页面
        if (url) { history.push(url) }
    }
    const sysLogo = useMemo(() => (GetSysInfo()?.logo || projectLogoImg || dLogo), [projectLogoImg])

    const rightHeadMenu = useMemo(() => {
        return (
            <List type="option">
                <List.Item onClick={MyProfile} className="tea-nav__list-line">个人信息</List.Item>
                <List.Item onClick={LogoOut}>退出</List.Item>
            </List>
        )
    }, [MyProfile, LogoOut])

    const toHome = () => {
        history.push('/')
    }

    return !hide
        ? (
            <Header>
                <NavMenu
                    left={
                        <>
                            <NavMenu.Item type="logo">
                                <img
                                    src={sysLogo}
                                    alt="logo"
                                    onClick={toHome}
                                    style={{ height: '38px' }}
                                />
                            </NavMenu.Item>

                            {
                                topMenus && topMenus.filter(x => x.name !== 'top_profile').map(menu => {
                                    return (
                                        <NavMenu.Item
                                            key={menu.name}
                                            selected={menu.name === currTopMenuName}
                                            onClick={() => handleClickTopMenu(menu.name, menu.url)}>
                                            {menu.displayName || '管理中心'}
                                        </NavMenu.Item>
                                    )
                                })
                            }
                        </>
                    }
                    right={
                        <>
                            {
                                GetSysInfo()?.multiLanguage && <TranslateSelect style={{ color: '#fff', marginRight: '10px' }} />
                            }
                            <Text style={{ color: '#bbb' }}>{`${corpName || ''}/${nickName}`}</Text>
                            <NavMenu.Item
                                type="dropdown"
                                overlay={rightHeadMenu}
                            >
                                <i className='navIconAvatar'></i>
                            </NavMenu.Item>
                        </>
                    }
                />
            </Header>
        )
        : null
}

export default withRouter(Head)
