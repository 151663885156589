import React from 'react'
import { Card } from 'tea-component'
function index (props) {
    return (
        <div style={{ position: 'fixed', width: '100vw', padding: 30, textAlign: 'center', boxSizing: 'border-box' }}>
            <Card bordered={false}>
                <Card.Body>
                    <div style={{ height: 200 }}>
                        <img src={require('@/assets/images/warn.png').default} style={{ width: 80 }} alt="" />
                        <div style={{ fontSize: 16, paddingTop: 15, color: '#666' }}>请用微信扫码打开</div>
                    </div>
                </Card.Body>
            </Card>
        </div>
    )
}

export default index
