import React, { useEffect, useState } from 'react'
import { SetSysInfo } from '@/assets/js/storage'
import { postWithAnonymous } from '@/axios'
import './index.css'
import UpdatePwd from './updatePassword'
import LoginForm from './LoginForm'
import dLogo from '@/assets/images/sysLogo.png'
import dFavicon from '@/assets/images/sysFavicon.ico'
// anxin 生产、流通管理系统
// tts TTS追溯平台
// link 爱创追溯云
const envloginPage = process.env.REACT_APP_LoginPage || 'tts'
const envLogo = process.env.REACT_APP_Logo || 'tts'
const envPageTitle = process.env.REACT_APP_Title || '追溯平台'
export default function Login () {
    // 背景图片
    const bgImg = require(`./images/login-bg-${envloginPage}.png`).default
    // logo图片
    const projectLogoImg = require(`./images/login-logo-${envLogo}.png`).default
    // Favicon
    const projectFaviconImg = require(`./images/login-logo-${envLogo}.png`).default
    const [showModifyModal, setShowModifyModal] = React.useState(false)
    const [sysName, setSysName] = useState('')
    const [sysLogo, setSysLogo] = useState('')
    const [userLoginInfo, setUserLoginInfo] = useState({})
    useEffect(() => {
        postWithAnonymous('/api/basedata/systemsetting/systemName/find').then(res => {
            const defaultLogo = projectLogoImg || dLogo
            const defaultTitle = envPageTitle
            const defaultFavicon = projectFaviconImg || dFavicon

            if (res?.success) {
                if (!res?.data) { return }
                SetSysInfo(res?.data)
                setUserLoginInfo(res?.data)
            }
            // 设置网页标题
            const title = res?.data.name || defaultTitle
            setSysName(title)
            document.title = title
            // 登录页图标
            const sysLogoValue = res.data.loginLog || defaultLogo
            setSysLogo(sysLogoValue)

            // #region 设置网页图标favicon
            const favicon = res.data.favicon
            const faviconurl = favicon ? `https:${favicon}` : defaultFavicon // 这里可以是动态的获取的favicon的地址
            const link = document.querySelector("link[rel*='icon']") || document.createElement('link')
            link.type = 'image/x-icon'
            link.rel = 'shortcut icon'
            if (faviconurl) {
                link.href = faviconurl
            }
            document.getElementsByTagName('head')[0].appendChild(link)
                // #endregion
        })
    }, [projectFaviconImg, projectLogoImg])
    return (
        <section id="wrapper" style={{ minwidth: '1336px', height: '100%' }}>
            <div style={{ display: 'flex', height: '100%' }}>
                <div className='leftWrapper' style={{ backgroundImage: `url("${bgImg}")` }}></div>
                <div className='rightWrapper'>
                    <LoginForm showReset={() => setShowModifyModal(true)} url={userLoginInfo?.url} allowSso={userLoginInfo?.allowSso} >
                        <div className='logoContainer'>
                        </div>
                        <div className='sysContainer'>
                            <div>
                                <img src={sysLogo} alt="" />
                            </div>
                            <span style={{ fontSize: '22px' }}>{sysName}</span>
                        </div>
                    </LoginForm>
                </div>
                {
                    showModifyModal ? (<UpdatePwd visible={showModifyModal} changeVisible={v => setShowModifyModal(v)} />) : null
                }
            </div>
        </section>
    )
}
